// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiDatePicker from "components/SuiDatePicker";
import FormField from "layouts/profile/components/FormField";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function EmploymentLeadForm(props) {
  const { values } = breakpoints;
  const { inputs, onChange, onDateChange } = props;

  return (
    <Accordion style={{ borderRadius: 10, boxShadow: "0px 0px 20px #ECEDEE" }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="basics-accordion"
        id="basics-accordion-header"
        style={{ marginLeft: 15 }}
      >
        <SuiTypography
          textTransform="capitalize"
          fontWeight="bold"
          variant={window.innerWidth < values.sm ? "h6" : "h5"}
        >
          Employment
        </SuiTypography>
      </AccordionSummary>
      <SuiBox p={3}>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormField
                type="text"
                name="currentEmployer"
                value={inputs.currentEmployer}
                label="Current Employer"
                placeholder=""
                onChange={(e) => onChange(e, "employment")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                name="currentPosition"
                value={inputs.currentPosition}
                label="Current Position / Title"
                placeholder=""
                onChange={(e) => onChange(e, "employment")}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Current Employer Start Date
                  </SuiTypography>
                </SuiBox>
                <SuiDatePicker
                  value={inputs.currentEmployerStart}
                  onChange={(e) => onDateChange(e, "employment", "currentEmployerStart")}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} sm={3}>
              <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Current Employer End Date
                  </SuiTypography>
                </SuiBox>
                <SuiDatePicker
                  value={inputs.currentEmployerEnd}
                  onChange={(e) => onDateChange(e, "employment", "currentEmployerEnd")}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12}>
              <FormField
                type="text"
                name="previousEmployer"
                value={inputs.previousEmployer}
                label="Previous Employer"
                placeholder=""
                onChange={(e) => onChange(e, "employment")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                name="previousPosition"
                value={inputs.previousPosition}
                label="Previous Position / Title"
                placeholder=""
                onChange={(e) => onChange(e, "employment")}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Previous Employer Start Date
                  </SuiTypography>
                </SuiBox>
                <SuiDatePicker
                  value={inputs.previousEmployerStart}
                  onChange={(e) => onDateChange(e, "employment", "previousEmployerStart")}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} sm={3}>
              <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Previous Employer End Date
                  </SuiTypography>
                </SuiBox>
                <SuiDatePicker
                  value={inputs.previousEmployerEnd}
                  onChange={(e) => onDateChange(e, "employment", "previousEmployerEnd")}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </AccordionDetails>
      </SuiBox>
    </Accordion>
  );
}

EmploymentLeadForm.propTypes = {
  inputs: PropTypes.shape({
    currentEmployer: PropTypes.string.isRequired,
    currentEmployerEnd: PropTypes.string.isRequired,
    currentEmployerStart: PropTypes.string.isRequired,
    currentPosition: PropTypes.string.isRequired,
    previousEmployer: PropTypes.string.isRequired,
    previousEmployerEnd: PropTypes.string.isRequired,
    previousEmployerStart: PropTypes.string.isRequired,
    previousPosition: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
};

export default EmploymentLeadForm;
