import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "redux/actions/userActions";
import { useHistory } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// MUI Components
import { CircularProgress, Grid, Stack, Typography } from "@mui/material";

// SUI Components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// SUI Styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import LoanaLogo from "assets/images/loana-logo.png";

// Custom Components
import MuiLoader from "components/MuiLoader";

function Checkout(props) {
  const { type } = props;
  const { values } = breakpoints;

  const dispatch = useDispatch();
  const history = useHistory();

  const loading = useSelector((state) => state.ui.loading);

  const handleSignout = (event) => {
    event.preventDefault();
    dispatch(logoutUser()).then(() => history.push("/authentication/sign-in"));
  };

  const startTimer = () => setTimeout(() => history.push("/mortgage"), 10000);

  if (type === "upgrade") {
    startTimer();
  }

  return (
    <>
      <MuiLoader loading={loading} />
      <SuiBox mt={15} mb={15} p={3}>
        <Grid container spacing={5} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={8}>
            <Stack direction="row" spacing={3} justifyContent="center" alignItems="end">
              <SuiBox component="img" src={LoanaLogo} alt="Loana Logo" width="60px" />
              <Typography
                variant={window.innerWidth > values.md ? "h2" : "h4"}
                sx={{ letterSpacing: 4 }}
              >
                <b>LOANA</b>
              </Typography>
            </Stack>
          </Grid>
          {type === "checkout" ? (
            <>
              <Grid item xs={12} md={8} align="center">
                <SuiTypography variant="h4" textColor="info" fontWeight="bold">
                  Congrats on upgrading to Loana Pro! We sincerely thank you for choosing Loana.
                </SuiTypography>
              </Grid>
              <Grid item xs={12} md={7}>
                <SuiTypography variant="h6">
                  We cannot wait to work with you and get your team onboarded! Be on the lookout for
                  an email from our onboarding team, it will outline the next steps and the items
                  we&apos;ll need to get everything imported.
                </SuiTypography>
              </Grid>
              <Grid item xs={12} md={7}>
                <SuiTypography variant="h6">
                  Next, click the <b>Finished</b> button below. You will be signed out of your Loana
                  account. Please log back in and begin to explore your Loana Pro dashboard!
                </SuiTypography>
              </Grid>
              <Grid item xs={12} md={7}>
                <SuiTypography variant="h6">
                  Again, thank you for joining Loana and we&apos;ll speak to you soon!
                </SuiTypography>
              </Grid>
              <Grid item xs={12} md={8} align="center">
                <SuiButton
                  variant="gradient"
                  buttonColor="error"
                  size="large"
                  onClick={handleSignout}
                >
                  Finished
                </SuiButton>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={8} align="center">
                <SuiTypography variant="h4" textColor="info" fontWeight="bold">
                  Congrats on upgrading your Loana Pro plan! We sincerely thank you for choosing
                  Loana.
                </SuiTypography>
              </Grid>
              <Grid item xs={12} md={7} align="center">
                <SuiTypography variant="h6">
                  We&apos;re updating your account as we speak! This should only take a few moments.
                </SuiTypography>
              </Grid>
              <Grid item xs={12} md={7} align="center">
                <CircularProgress color="info" />
              </Grid>
            </>
          )}
        </Grid>
      </SuiBox>
    </>
  );
}

Checkout.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Checkout;
