import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UI_ERROR, SET_OPPS } from "redux/types";
import { apiPostWithReturn } from "redux/actions/dataActions";
import { useHistory, useLocation, useParams } from "react-router-dom";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// Firebase
import { collection, query, onSnapshot, getFirestore } from "firebase/firestore";

// Day JS
import dayjs from "dayjs";

// @mui material components
import { Dialog, DialogActions, DialogTitle, Grid, Divider, DialogContent } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
import SuiButton from "components/SuiButton";
import SuiDatePicker from "components/SuiDatePicker";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Utils
import oppSchema from "utils/schemas/opp";
import organizeOpps from "utils/helpers/organizeOpps";
import leadsRefresh from "utils/helpers/leadsRefresh";

// Custom Components
import OpportunityForm from "../add-opportunity/components/OpportunityForm";
import OpportunityFormActions from "../add-opportunity/components/OpportunityFormActions";
import NotesArea from "../add-opportunity/components/NotesArea";
import SendActions from "../add-opportunity/components/SendActions";
import NotesForm from "../add-opportunity/components/NotesForm";
import ClosedDialog from "../components/ClosedDialog";
import EmailDialog from "../components/EmailDialog";

function EditOpportunity() {
  const { values } = breakpoints;
  const bypass = true;
  const addDeleteAction = true;

  const { oppId } = useParams();

  // Opens UI Error
  const [openError, setOpenError] = useState(false);

  // Dispatch and History declarations
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const db = getFirestore();

  // User, Data, UI Redux Objects
  const user = useSelector((state) => state.user);
  const loading = useSelector((state) => state.ui.loading);
  const inputError = useSelector((state) => state.ui.error);
  const oppsAll = useSelector((state) => state.data.opps);
  const leadsAll = useSelector((state) => state.data.leads);
  const partnersAll = useSelector((state) => state.data.partners);

  // User Inputs and triggers
  const [oppInputs, setOppInputs] = useState({ ...oppSchema, oppOwner: user.profile.uid });
  const [addedLeads, setAddedLeads] = useState([]);

  // Flags to capture the opps previous tag states to remove duplicate notes on submit
  const [initialTags, setInitialTags] = useState({
    holdActive: false,
    creditRepairActive: false,
    noResponseActive: false,
    dnq: false,
    dnc: false,
  });

  // Captures the initial opp status
  const [initialStatus, setInitialStatus] = useState("new");

  /* ------------------ Add Note Dialog ------------------ */
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [newNote, setNewNote] = useState("");

  const handleCloseNoteDialog = () => setOpenNoteDialog(false);
  const handleOpenNoteDialog = () => setOpenNoteDialog(true);

  const setNotes = (notes) => {
    notes.push({
      createdAt: new Date().toISOString(),
      message: newNote,
      noteOwner: user.profile.uid,
      noteId: uuidv4(),
    });
    return notes;
  };

  const handleAddNote = (event) => {
    event.preventDefault();
    const tempNotes = oppInputs.notes;
    const submitData = {
      opp: {
        ...oppInputs,
        notes: setNotes(tempNotes),
      },
      companyId: user.company.companyId,
    };
    if (newNote !== "") {
      setOpenNoteDialog(false);
      dispatch(apiPostWithReturn("/opp/edit", submitData))
        .then(() => setNewNote(""))
        .catch((error) => {
          dispatch({
            type: UI_ERROR,
            payload: `Something went wrong. Please contact support@loana.freshdesk.com for help or try again. Error: ${error}`,
          });
        });
    }
  };

  const handleNoteChange = (event) => {
    event.persist();
    setNewNote(event.target.value);
  };
  /* ----------------------------------------------------------- */

  /* ------------------ Delete Dialog ------------------ */
  const [openDelete, setOpenDelete] = useState(false);

  const handleCloseDeleteDialog = () => setOpenDelete(false);
  const handleOpenDeleteDialog = () => setOpenDelete(true);
  /* ----------------------------------------------------------- */

  /* ------------------ Hold Dialog ------------------ */
  const [openHold, setOpenHold] = useState(false);
  const [holdDate, setHoldDate] = useState("placeholder");
  const [checkedHold, setCheckedHold] = useState(false);
  const [stallDialog, setStallDialog] = useState(true);

  // Clears the checked, holdDate, and resets the user input fields
  const handleHoldRemove = () => {
    setCheckedHold(false);
    setHoldDate("");
    setOppInputs((inputs) => ({
      ...inputs,
      holdUntil: "",
      holdActive: false,
    }));
  };

  // Controls the switch for Hold for Future Opportunity
  const handleCheckHold = (event) => {
    if (!event.target.checked) {
      handleHoldRemove();
    }
    setCheckedHold(event.target.checked);
  };

  // Closes the Hold Dialog and clears items. Used for if user clicks off the dialog
  const handleCloseHoldDialog = () => {
    handleHoldRemove();
    setOpenHold(false);
  };

  // Sets a temporary state to store the hold date user input
  const handleHoldChange = (event) => setHoldDate(event[0].toISOString());

  // Handles user cancelling the date select for the hold date
  const handleHoldCancel = () => {
    setHoldDate("");
    setCheckedHold(false);
    setOpenHold(false);
  };

  // Handles user saving the selected date
  const handleHoldSave = () => {
    setOppInputs((inputs) => ({
      ...inputs,
      holdUntil: holdDate,
      holdActive: true,
    }));
    setOpenHold(false);
  };

  // Opens the dialog depending on the switch's state
  useEffect(() => {
    if (stallDialog && checkedHold) {
      setStallDialog(false);
    } else if (!stallDialog && checkedHold && holdDate === "") {
      setOpenHold(true);
    }
  }, [checkedHold, stallDialog, holdDate]);

  // Updates switches state and date depending on opp data
  useEffect(() => {
    if (oppInputs.holdActive) {
      setCheckedHold(true);
      setHoldDate(oppInputs.holdUntil);
    } else {
      setHoldDate("");
      setStallDialog(false);
    }
  }, [oppInputs]);
  /* ----------------------------------------------------------- */

  // ------------------ Credit Repair Switch ------------------ //
  const [checkedCrn, setCheckedCrn] = useState(false);

  // Switch state handler
  const handleCheckCrn = (event) => {
    event.preventDefault();
    setCheckedCrn(event.target.checked);
    setOppInputs((inputs) => ({
      ...inputs,
      creditRepairActive: event.target.checked,
      creditRepairUntil: event.target.checked
        ? new Date(
            Date.now() + user.company.followupTemplate.creditRepair * 24 * 60 * 60 * 1000
          ).toISOString()
        : "",
    }));
  };

  useEffect(() => {
    if (oppInputs.creditRepairActive) {
      setCheckedCrn(true);
    }
  }, [oppInputs]);
  /* ----------------------------------------------------------- */

  /* ------------------ No Response Switch ------------------ */
  const [checkedNr, setCheckedNr] = useState(false);

  // Switch state handler
  const handleCheckNr = (event) => {
    event.preventDefault();
    setCheckedNr(event.target.checked);
    setOppInputs((inputs) => ({
      ...inputs,
      noResponseActive: event.target.checked,
      noResponseTask: event.target.checked,
      noResponseUntil: event.target.checked
        ? new Date(
            Date.now() + user.company.followupTemplate.noResponse * 24 * 60 * 60 * 1000
          ).toISOString()
        : "",
    }));
  };

  useEffect(() => {
    if (oppInputs.noResponseActive) {
      setCheckedNr(true);
    }
  }, [oppInputs]);
  /* ----------------------------------------------------------- */

  /* -------------------- Do Not Contact and Does Not Qualify Switches ---------------- */
  const [checkedDnc, setCheckedDnc] = useState(false);
  const [checkedDnq, setCheckedDnq] = useState(false);

  // Switch Do Not Contact handler
  const handleCheckDnc = (event) => {
    if (event.target.checked) {
      setCheckedDnc(event.target.checked);
      setCheckedDnq(false);
      setCheckedHold(false);
      setHoldDate("");
      setCheckedNr(false);
      setCheckedCrn(false);
      setOppInputs((inputs) => ({
        ...inputs,
        dnc: event.target.checked,
        dnq: false,
        status: "lost",
        holdUntil: "",
        holdActive: false,
        creditRepairActive: false,
        creditRepairUntil: "",
        noResponseActive: false,
        noResponseUntil: "",
      }));
    } else {
      setCheckedDnc(event.target.checked);
      setOppInputs((inputs) => ({
        ...inputs,
        dnc: event.target.checked,
        status: initialStatus,
      }));
    }
  };

  // Switch state handler
  const handleCheckDnq = (event) => {
    if (event.target.checked) {
      setCheckedDnq(event.target.checked);
      setCheckedDnc(false);
      setCheckedHold(false);
      setHoldDate("");
      setCheckedNr(false);
      setCheckedCrn(false);
      setOppInputs((inputs) => ({
        ...inputs,
        dnq: event.target.checked,
        status: "lost",
        holdUntil: "",
        holdActive: false,
        creditRepairActive: false,
        creditRepairUntil: "",
        noResponseActive: false,
        noResponseUntil: "",
      }));
    } else {
      setCheckedDnq(event.target.checked);
      setOppInputs((inputs) => ({
        ...inputs,
        dnq: event.target.checked,
        status: initialStatus,
      }));
    }
  };

  useEffect(() => {
    if (oppInputs.dnc) {
      setCheckedDnc(true);
    }
  }, [oppInputs]);

  useEffect(() => {
    if (oppInputs.dnq) {
      setCheckedDnq(true);
    }
  }, [oppInputs]);
  /* ----------------------------------------------------------- */

  /* ----------------------- Email Dialog ------------------------- */

  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [currentOpp, setCurrentOpp] = useState({});

  const handleOpenEmailDialog = (event) => {
    event.preventDefault();
    setOpenEmailDialog(true);
  };

  const handleCloseEmailDialog = () => {
    setCurrentOpp({});
    setOpenEmailDialog(false);
  };

  /* ----------------------------------------------------------- */

  /* ----------------------- Closed Dialog ------------------------- */

  const [openClosedDialog, setOpenClosedDialog] = useState(false);

  const handleOpenClosedDialog = () => setOpenClosedDialog(true);

  const handleClosedAmountInputChange = (event) => {
    event.persist();
    setOppInputs((inputs) => ({
      ...inputs,
      closedAmount: event.target.value,
    }));
  };

  const handleClosedRateChange = (event) => {
    event.persist();
    setOppInputs((inputs) => ({
      ...inputs,
      closedRate: event.target.value,
    }));
  };

  const handleClosedDateChange = (event) => {
    if (event && event.length !== 0) {
      setOppInputs((inputs) => ({ ...inputs, closedAt: event[0].toISOString() }));
    } else {
      setOppInputs((inputs) => ({ ...inputs, closedAt: new Date().toISOString() }));
    }
  };

  // Handles User submit action of closed amount
  const handleSubmitClosedAmount = async (event) => {
    event.preventDefault();
    const submitData = {
      opp: {
        ...oppInputs,
        closedAmount: parseInt(oppInputs.closedAmount, 10),
        closedAt: oppInputs.closedAt,
        closedRate: oppInputs.closedRate,
        creditRepairActive: false,
        holdActive: false,
        dnq: false,
        refinanceJourneyActive: true,
        refinanceJourneyStatus: "opener",
      },
      companyId: user.company.companyId,
    };
    dispatch(apiPostWithReturn("/opp/edit", submitData))
      .then((res) => {
        if (res.status === 200) {
          history.push("/pipeline/opportunities");
        }
      })
      .catch((error) => {
        dispatch({
          type: UI_ERROR,
          payload: `Something went wrong. Please contact support@loana.freshdesk.com for help or try again. Error: ${error}`,
        });
      });
  };

  /* ----------------------------------------------------------- */

  /* -------------------- Core Functions --------------------- */
  // onChange handlers for user inputs
  const handleInputsChange = (event, name) => {
    setOppInputs((inputs) => ({
      ...inputs,
      [name]: event.value,
    }));
  };

  // Sets leads to be displayed and added to inputs
  const handleSetLeads = (event, option) => {
    event.preventDefault();
    const leadArr = addedLeads;
    leadArr.push(option);
    setAddedLeads(leadArr);
    setOppInputs((inputs) => ({
      ...inputs,
      leads: leadArr,
    }));
  };

  // Removes specific lead from being displayed and inputs
  const handleRemoveLeads = (event, option) => {
    event.preventDefault();
    const leadArr = addedLeads.filter((obj) => obj.leadId !== option.leadId);
    setAddedLeads(leadArr);
    setOppInputs((inputs) => ({
      ...inputs,
      leads: leadArr,
    }));
  };

  const structureLeads = () => {
    const structuredLeads = addedLeads;
    addedLeads.forEach((lead, index) => {
      const currentLead = leadsAll.filter((obj) => obj.leadId === lead.leadId);
      if (currentLead[0].basic.partner && currentLead[0].basic.partner !== "") {
        const currentPartner = partnersAll.filter(
          (obj) => obj.partnerId === currentLead[0].basic.partner
        );
        structuredLeads[index] = {
          ...structuredLeads[index],
          partnerId: currentPartner[0].partnerId,
          partnerName: currentPartner[0].basic.name,
        };
      } else {
        structuredLeads[index] = {
          ...structuredLeads[index],
          partnerId: null,
          partnerName: "",
        };
      }
    });
    return structuredLeads;
  };

  // User input validator
  const validateInputs = () => {
    let valid = true;
    if (
      oppInputs.type === "" ||
      oppInputs.status === "" ||
      oppInputs.owner === "" ||
      oppInputs.leads.length === 0
    ) {
      valid = false;
      dispatch({
        type: UI_ERROR,
        payload: "Please enter at least a type, status, owner, and add one lead",
      });
    }
    return valid;
  };

  // Adds automated notes based off tags
  const addTagNotes = () => {
    const notesArr = oppInputs.notes;
    if (oppInputs.holdActive && !initialTags.holdActive) {
      notesArr.push({
        createdAt: new Date().toISOString(),
        message: `Future opportunity hold placed until ${dayjs(oppInputs.holdUntil).format(
          "MM/DD/YYYY"
        )}`,
        noteOwner: user.profile.uid,
        noteId: uuidv4(),
      });
    }
    if (oppInputs.creditRepairActive && !initialTags.creditRepairActive) {
      notesArr.push({
        createdAt: new Date().toISOString(),
        message: `Credit repair needed`,
        noteOwner: user.profile.uid,
        noteId: uuidv4(),
      });
    }
    if (oppInputs.noResponseActive && !initialTags.noResponseActive) {
      notesArr.push({
        createdAt: new Date().toISOString(),
        message: `No response from the leads associated with the opportunity`,
        noteOwner: user.profile.uid,
        noteId: uuidv4(),
      });
    }
    if (oppInputs.dnq && !initialTags.dnq) {
      notesArr.push({
        createdAt: new Date().toISOString(),
        message: `Associated leads do not qualify`,
        noteOwner: user.profile.uid,
        noteId: uuidv4(),
      });
    }
    if (oppInputs.dnc && !initialTags.dnc) {
      notesArr.push({
        createdAt: new Date().toISOString(),
        message: `Do not contact the associated leads`,
        noteOwner: user.profile.uid,
        noteId: uuidv4(),
      });
    }
    return notesArr;
  };

  // Handles opp submit action
  const handleSubmit = async (event) => {
    event.preventDefault();
    const submitData = {
      opp: {
        ...oppInputs,
        notes: addTagNotes(),
      },
      companyId: user.company.companyId,
    };
    const valid = validateInputs();
    if (valid) {
      dispatch(apiPostWithReturn("/opp/edit", submitData))
        .then((res) => {
          if (res.status === 200) {
            if (location.state.redirectToFollowups) {
              history.push("/tools/followups");
            } else {
              history.push("/pipeline/opportunities");
            }
          }
        })
        .catch((error) => {
          dispatch({
            type: UI_ERROR,
            payload: `Something went wrong. Please contact support@loana.freshdesk.com for help or try again. Error: ${error}`,
          });
        });
    }
  };

  // Handles opp delete action
  const handleDelete = async (event) => {
    event.preventDefault();
    const submitData = {
      oppId,
      companyId: user.company.companyId,
    };
    dispatch(apiPostWithReturn("/opp/delete", submitData))
      .then((res) => {
        if (res.status === 200) {
          history.push("/pipeline/opportunities");
        }
      })
      .catch((error) => {
        dispatch({
          type: UI_ERROR,
          payload: `Something went wrong. Please contact support@loana.freshdesk.com for help or try again. Error: ${error}`,
        });
      });
  };

  // Handle initial tag flags
  const handleTagFlags = (opp) => {
    Object.keys(opp).forEach((key) => {
      if (
        key === "holdActive" ||
        key === "creditRepairActive" ||
        key === "dnq" ||
        key === "dnc" ||
        key === "noResponseActive"
      ) {
        setInitialTags((tags) => ({
          ...tags,
          [key]: opp[key],
        }));
      }
    });
  };

  // Gets current opportunity from redux data
  useEffect(() => {
    const combineOppsArrs = Object.keys(oppsAll).map((key) => oppsAll[key]);
    combineOppsArrs.forEach((arr) => {
      arr.forEach((opp) => {
        if (opp.oppId === oppId) {
          setOppInputs(opp);
          setAddedLeads(opp.leads);
          handleTagFlags(opp);
          setCurrentOpp(opp);
          setInitialStatus(opp.status);
        }
      });
    });
  }, [oppsAll, oppId]);

  // Creates Firestore channel to listen for realtime document updates
  useEffect(() => {
    const q = query(collection(db, `/companies/${user.company.companyId}/opps`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
      const organized = organizeOpps(data);
      dispatch({ type: SET_OPPS, payload: organized });
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const q = query(collection(db, `/companies/${user.company.companyId}/leads`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const queryData = [];
      querySnapshot.forEach((doc) => {
        queryData.push(doc.data());
      });
      leadsRefresh(dispatch, queryData);
    });

    return unsubscribe;
  }, []);

  // Opens error alert
  useEffect(() => {
    if (inputError !== "" && inputError !== "Email and subject cannot be blank.")
      setOpenError(true);
  }, [inputError]);
  /* ----------------------------------------------------------- */

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiBox lineHeight={1} pl={3}>
              <SuiTypography
                textTransform="capitalize"
                fontWeight="bold"
                variant={window.innerWidth < values.sm ? "h5" : "h4"}
              >
                Edit Opportunity
              </SuiTypography>
            </SuiBox>
            <Divider />
          </Grid>
          {oppInputs.dnc ? (
            <Grid item xs={12}>
              <SuiAlert color="info">
                <SuiTypography
                  component="a"
                  href="#"
                  variant="body2"
                  fontWeight="medium"
                  textColor="white"
                >
                  <b>Do not contact the associated leads.</b> A member of your team tagged this
                  opportunity as &quot;Do Not Contact&quot;.
                </SuiTypography>
              </SuiAlert>
            </Grid>
          ) : null}
          {openError ? (
            <Grid item xs={12}>
              <SuiAlert color="warning">
                <SuiTypography variant="body2" textColor="white">
                  <SuiTypography
                    component="a"
                    href="#"
                    variant="body2"
                    fontWeight="medium"
                    textColor="white"
                  >
                    Oops!&nbsp;
                  </SuiTypography>
                  {inputError}
                </SuiTypography>
              </SuiAlert>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={8}>
            <OpportunityForm
              inputs={oppInputs}
              user={user.profile}
              team={user.company.team}
              leads={structureLeads()}
              onChange={handleInputsChange}
              onLeadsAdd={handleSetLeads}
              onLeadsRemove={handleRemoveLeads}
              onHoldChange={handleCheckHold}
              onCrnChange={handleCheckCrn}
              onNrChange={handleCheckNr}
              onDnqChange={handleCheckDnq}
              onDncChange={handleCheckDnc}
              checkedHold={checkedHold}
              checkedCrn={checkedCrn}
              checkedNr={checkedNr}
              checkedDnq={checkedDnq}
              checkedDnc={checkedDnc}
              holdDate={holdDate}
              onRemoveHold={handleHoldRemove}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <OpportunityFormActions
                  onSubmit={handleSubmit}
                  onSubmitClosed={handleOpenClosedDialog}
                  onDelete={handleOpenDeleteDialog}
                  deleteAvailable={addDeleteAction}
                  status={oppInputs.status}
                />
              </Grid>
              <Grid item xs={12}>
                <SendActions onSend={handleOpenEmailDialog} />
              </Grid>
              <Grid item xs={12}>
                <NotesArea
                  notes={oppInputs.notes && oppInputs.notes.length !== 0 ? oppInputs.notes : []}
                  openDialog={handleOpenNoteDialog}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
      {/* EMAIL DIALOG */}
      <EmailDialog opp={currentOpp} open={openEmailDialog} onClose={handleCloseEmailDialog} />
      {/* CLOSED DIALOG */}
      <ClosedDialog
        open={openClosedDialog}
        amount={oppInputs.closedAmount}
        rate={oppInputs.closedRate}
        date={oppInputs.closedAt}
        onDateChange={handleClosedDateChange}
        onRateChange={handleClosedRateChange}
        onChange={handleClosedAmountInputChange}
        onSubmit={handleSubmitClosedAmount}
      />
      {/* HOLD FOR FUTURE OPPORTUNITY DIALOG */}
      <Dialog open={openHold} onClose={handleCloseHoldDialog}>
        <DialogTitle>Hold for Future Opportunity</DialogTitle>
        <DialogContent>
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            height="100%"
            mb={2}
            mt={2}
          >
            <SuiTypography variant="body2" textColor="text">
              Please select the date you wish to be reminded to follow up with this opportunity
            </SuiTypography>
          </SuiBox>
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            height="100%"
            mb={2}
            mt={2}
          >
            <SuiDatePicker value={oppInputs.holdUntil} onChange={(e) => handleHoldChange(e)} />
          </SuiBox>
        </DialogContent>
        <DialogActions>
          <SuiButton variant="outlined" buttonColor="error" size="small" onClick={handleHoldCancel}>
            Cancel
          </SuiButton>
          <SuiButton
            variant="gradient"
            buttonColor="error"
            size="small"
            onClick={handleHoldSave}
            disabled={holdDate === "" || false}
          >
            Save Hold Date
          </SuiButton>
        </DialogActions>
      </Dialog>
      {/* DELETE DIALOG */}
      <Dialog open={openDelete} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Opportunity?</DialogTitle>
        <DialogContent>
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            height="100%"
            mb={2}
            mt={2}
          >
            <SuiTypography variant="body2" textColor="text">
              Are you sure you wish to delete this opportunity? This action cannot be undone.
            </SuiTypography>
          </SuiBox>
        </DialogContent>
        <DialogActions>
          <SuiButton
            variant="outlined"
            buttonColor="error"
            size="small"
            onClick={handleCloseDeleteDialog}
          >
            Cancel
          </SuiButton>
          <SuiButton variant="gradient" buttonColor="error" size="small" onClick={handleDelete}>
            Delete Opportunity
          </SuiButton>
        </DialogActions>
      </Dialog>
      {/* ADD NOTE */}
      <NotesForm
        open={openNoteDialog}
        newNote={newNote}
        onClick={handleAddNote}
        onClose={handleCloseNoteDialog}
        onChange={handleNoteChange}
      />
    </DashboardLayout>
  );
}

export default EditOpportunity;
