// Proptypes
import PropTypes from "prop-types";

// @mui material components
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Sui Components
import SuiTypography from "components/SuiTypography";

function FaqItem(props) {
  const { title, description } = props;

  return (
    <Accordion style={{ backgroundColor: "transparent", boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <SuiTypography variant="h6">{title}</SuiTypography>
      </AccordionSummary>
      <AccordionDetails>
        <SuiTypography variant="body2">{description}</SuiTypography>
      </AccordionDetails>
    </Accordion>
  );
}

FaqItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default FaqItem;
