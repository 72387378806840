// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { Card, CardTitle } from "material-ui";
import { CardContent } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function PartnerFormActions(props) {
  const { values } = breakpoints;
  const { onSubmit } = props;

  return (
    <Card>
      <CardTitle>
        <SuiTypography
          textTransform="capitalize"
          fontWeight="bold"
          variant={window.innerWidth < values.sm ? "h6" : "h5"}
        >
          Actions
        </SuiTypography>
      </CardTitle>
      <SuiBox p={3}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} align="center">
              <SuiButton variant="gradient" buttonColor="error" size="large" onClick={onSubmit}>
                Save Partner
              </SuiButton>
            </Grid>
          </Grid>
        </CardContent>
      </SuiBox>
    </Card>
  );
}

PartnerFormActions.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default PartnerFormActions;
