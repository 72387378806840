import {
  SET_LEADS,
  SET_OPPS,
  SET_PARTNERS,
  SET_TASKS,
  SET_FOLLOWUPS,
  SET_ISDATAREADY,
  SET_FILTERS,
  SET_NOTIFICATIONS,
  CLEAR_FILTERS,
} from "../types";

const initialState = {
  leads: [],
  opps: {
    new: [],
    pq: [],
    ai: [],
    docs: [],
    pa: [],
    uc: [],
    les: [],
    closed: [],
    crn: [],
    hold: [],
    dnq: [],
    lost: [],
    nr: [],
  },
  partners: [],
  tasks: [],
  followups: [],
  isDataReady: false,
  filters: {
    startDate: null,
    endDate: null,
    status: null,
    owner: "my",
    showAll: false,
    search: "",
  },
  notifications: [],
};

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LEADS:
      return {
        ...state,
        leads: action.payload,
      };
    case SET_OPPS:
      return {
        ...state,
        opps: action.payload,
      };
    case SET_PARTNERS:
      return {
        ...state,
        partners: action.payload,
      };
    case SET_TASKS:
      return {
        ...state,
        tasks: action.payload,
      };
    case SET_FOLLOWUPS:
      return {
        ...state,
        followups: action.payload,
      };
    case SET_ISDATAREADY:
      return {
        ...state,
        isDataReady: true,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    case CLEAR_FILTERS:
      return {
        ...state,
        filters: {
          startDate: null,
          endDate: null,
          status: null,
          owner: null,
          showAll: false,
          search: "",
        },
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    default:
      return state;
  }
}
