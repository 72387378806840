// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Dialog, DialogContent } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiDatePicker from "components/SuiDatePicker";

// Custom styles for ComplexBackgroundCard
import styles from "./styles";

function ClosedDialog(props) {
  const classes = styles();
  const { amount, rate, date, onChange, onDateChange, onRateChange, open, onSubmit } = props;

  return (
    <Dialog open={open}>
      <DialogContent className={classes.closedDialog}>
        <SuiBox textAlign="center" p={2}>
          <SuiBox display="flex" flexDirection="column" mt={6} p={3}>
            <SuiBox mb={4}>
              <SuiTypography variant="h5" textColor="white" fontWeight="bold">
                Congratulations, you closed an opportunity!
              </SuiTypography>
            </SuiBox>
            <SuiBox mb={2} textAlign="start">
              <SuiTypography variant="body2" textColor="white" fontWeight="bold">
                How much was the closed loan amount?
              </SuiTypography>
            </SuiBox>
            <SuiBox mb={2}>
              <SuiInput name="closedAmount" value={amount} onChange={onChange} />
            </SuiBox>
            <SuiBox mb={2} textAlign="start">
              <SuiTypography variant="body2" textColor="white" fontWeight="bold">
                What was the interest rate?
              </SuiTypography>
            </SuiBox>
            <SuiBox mb={2}>
              <SuiInput name="closedRate" value={rate} onChange={onRateChange} />
            </SuiBox>
            <SuiBox mb={2} textAlign="start">
              <SuiTypography variant="body2" textColor="white" fontWeight="bold">
                What was the closing date?
              </SuiTypography>
            </SuiBox>
            <SuiBox mb={2}>
              <SuiDatePicker value={date || ""} onChange={onDateChange} />
            </SuiBox>
            <SuiBox mb={2} mt={2}>
              <SuiButton
                variant="gradient"
                color="error"
                disabled={amount === 0 || false}
                onClick={onSubmit}
              >
                Close Opportunity
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </DialogContent>
    </Dialog>
  );
}

ClosedDialog.propTypes = {
  amount: PropTypes.number.isRequired,
  rate: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onRateChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ClosedDialog;
