import { apiGetWithReturn } from "redux/actions/dataActions";
import { SET_ISDATAREADY } from "redux/types";

// Utils
import oppsRefresh from "utils/helpers/oppsRefresh";
import organizeOpps from "./organizeOpps";

const handleOppsRefresh = (dispatch, companyId) => {
  try {
    return dispatch(apiGetWithReturn(`/opp/all/${companyId}`))
      .then((res) => {
        // Updates the Redux store with the refreshed opps
        const organizedData = organizeOpps(res.data.message);
        oppsRefresh(dispatch, organizedData);
        return res.data.message;
      })
      .then((results) => {
        dispatch({ type: SET_ISDATAREADY });
        return results;
      });
  } catch (error) {
    return error;
  }
};

export default handleOppsRefresh;
