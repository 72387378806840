// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Grid, IconButton, Stack, Card, Tooltip, CardContent } from "@mui/material";
import { Avatar } from "material-ui";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React icons
import SpaceShip from "examples/Icons/SpaceShip";

function RefinanceCard(props) {
  const { opp, owner, onOpen } = props;

  return (
    <Grid item xs={12}>
      <Card
        key={uuidv4()}
        style={{
          borderRadius: 10,
          marginBottom: 10,
          boxShadow: "0px 0px 20px #ECEDEE",
        }}
      >
        <CardContent>
          <Grid container spacing={1} justifyContent="center" alignItems="center">
            <Grid item xs={2} align="center">
              <Avatar
                alt="User Profile Picture"
                src={owner.photoURL}
                style={{ objectFit: "cover", width: "32px", height: "32px" }}
              />
            </Grid>
            <Grid item xs={8}>
              <Stack spacing={1} my={2} justifyContent="center">
                {opp.leads
                  ? opp.leads.map((lead) => (
                      <SuiTypography
                        variant="caption"
                        textColor="text"
                        fontWeight="bold"
                        key={`opp-card-${lead.leadId}`}
                      >
                        {lead.name}
                      </SuiTypography>
                    ))
                  : null}
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title="Create Opportunity">
                <IconButton aria-label="actions" color="light" onClick={(e) => onOpen(e, opp)}>
                  <SpaceShip size="24px" color="error" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

// Typechecking props for the Card
RefinanceCard.propTypes = {
  owner: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    uid: PropTypes.string,
    photoURL: PropTypes.string,
  }).isRequired,
  onOpen: PropTypes.func.isRequired,
  opp: PropTypes.shape({
    leads: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        leadId: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
      })
    ),
    oppId: PropTypes.string,
  }).isRequired,
};

export default RefinanceCard;
