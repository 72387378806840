import { apiGetWithReturn } from "redux/actions/dataActions";

// Utils
import leadsRefresh from "utils/helpers/leadsRefresh";

const handleLeadsRefresh = (dispatch, companyId) => {
  try {
    return dispatch(apiGetWithReturn(`/lead/all/${companyId}`)).then((res) => {
      leadsRefresh(dispatch, res.data.message);
      return res.data.message;
    });
  } catch (error) {
    return error;
  }
};

export default handleLeadsRefresh;
