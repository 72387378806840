import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UI_ERROR_CLEAR, SET_OPPS, SET_FILTERS, CLEAR_FILTERS } from "redux/types";

// Firebase
import { collection, query, onSnapshot, getFirestore } from "firebase/firestore";

// SUI
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

import { Grid } from "@mui/material";

// Utils
import pipelineBoard from "utils/schemas/pipelineBoard";
import organizeOpps from "utils/helpers/organizeOpps";

// Custom component
import OpportunityList from "./components/OpportunityList";
import OpportunityHeader from "./components/OpportunityHeader";
import EmailDialog from "./components/EmailDialog";

function Opportunities() {
  const bypass = true;
  const dispatch = useDispatch();
  const db = getFirestore();

  const user = useSelector((state) => state.user);
  const opps = useSelector((state) => state.data.opps);
  const loading = useSelector((state) => state.ui.loading);

  const [filteredOpps, setFilteredOpps] = useState(opps);

  /* ----------------------- Email Dialog ------------------------- */

  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [currentOpp, setCurrentOpp] = useState({});

  const handleOpenEmailDialog = (event, opp) => {
    event.preventDefault();
    setCurrentOpp(opp);
    setOpenEmailDialog(true);
  };

  const handleCloseEmailDialog = () => {
    setCurrentOpp({});
    setOpenEmailDialog(false);
  };

  /* ----------------------------------------------------------- */

  /* ----------------------- Filters ------------------------- */

  const filters = useSelector((state) => state.data.filters);
  const [tempFilters, setTempFilters] = useState(filters);

  // Resets filters
  const handleFilterReset = () => {
    setTempFilters({
      startDate: null,
      endDate: null,
      status: null,
      owner: "my",
      search: "",
    });
    dispatch({ type: CLEAR_FILTERS });
  };

  // Handles user input of start and end dates
  const handleStartDateChange = (event) => {
    if (event && event.length !== 0) {
      setTempFilters((inputs) => ({ ...inputs, startDate: event[0].toISOString() }));
    } else {
      setTempFilters((inputs) => ({ ...inputs, startDate: null }));
    }
  };
  const handleEndDateChange = (event) => {
    if (event && event.length !== 0) {
      setTempFilters((inputs) => ({ ...inputs, endDate: event[0].toISOString() }));
    } else {
      setTempFilters((inputs) => ({ ...inputs, endDate: null }));
    }
  };

  // Handles user input of status filter select
  const handleStatusChange = (event) =>
    setTempFilters((inputs) => ({ ...inputs, status: event.value }));

  // Handles user input of owner filter select
  const handleOwnerChange = (event) =>
    setTempFilters((inputs) => ({ ...inputs, owner: event.value }));

  // Handles user input of search input
  const handleSearchValueChange = (event) =>
    setTempFilters((inputs) => ({ ...inputs, search: event.target.value }));

  // Filters opportunities by their leads names and the search term
  const filterBySearch = (arr) => {
    const tempArr = [];
    arr.forEach((opp) => {
      opp.leads.forEach((lead) => {
        if (lead.name.toUpperCase().includes(tempFilters.search.toUpperCase())) {
          tempArr.push(opp);
        }
      });
    });
    return tempArr;
  };

  const filterOppArrays = (conditions) => {
    Object.keys(opps).forEach((key) => {
      if (filters.search !== "") {
        const filteredKey = filterBySearch(opps[key]).filter((opp) =>
          conditions.every((f) => f(opp))
        );
        setFilteredOpps((inputs) => ({ ...inputs, [key]: filteredKey }));
      } else if (filters.search === "") {
        const filteredKey = opps[key].filter((opp) => conditions.every((f) => f(opp)));
        setFilteredOpps((inputs) => ({ ...inputs, [key]: filteredKey }));
      }
    });
  };

  // Sets the boards filter with the date and show variables
  const handleFilterOpps = () => {
    const conditions = [];

    if (filters.owner === "my") {
      conditions.push((opp) => opp.oppOwner === user.profile.uid);
    } else if (filters.owner !== "my" && filters.owner !== "all") {
      conditions.push((opp) => opp.oppOwner === filters.owner);
    }

    if (filters.status) {
      conditions.push((opp) => opp.status === filters.status);
    }

    if (filters.startDate) {
      conditions.push((opp) => new Date(opp.createdAt).toISOString() >= filters.startDate);
    } else {
      conditions.push((opp) => new Date(opp.createdAt).toISOString() >= "1970-01-01T00:00:00.000Z");
    }

    if (filters.endDate) {
      conditions.push((opp) => new Date(opp.createdAt).toISOString() <= filters.endDate);
    } else {
      conditions.push((opp) => new Date(opp.createdAt).toISOString() <= "2100-01-01T00:00:00.000Z");
    }

    filterOppArrays(conditions);
  };

  // Invokes the filter handler when a variable is updated
  useEffect(() => handleFilterOpps(), [filters]);
  useEffect(() => handleFilterOpps(), [opps]);

  // Dispatches filter changes to redux
  useEffect(() => dispatch({ type: SET_FILTERS, payload: tempFilters }), [tempFilters]);

  /* ----------------------------------------------------------- */

  /* ----------------------- Core ------------------------- */
  // Creates Firestore channel to listen for realtime document updates
  useEffect(() => {
    const q = query(collection(db, `/companies/${user.company.companyId}/opps`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
      const organized = organizeOpps(data);
      dispatch({ type: SET_OPPS, payload: organized });
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    dispatch({ type: UI_ERROR_CLEAR });
  }, []);
  /* ----------------------------------------------------------- */

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox pt={3} display="flex" justifyContent="flex-end" m={2}>
        <OpportunityHeader
          title="Opportunities"
          type="main"
          onDateStartChange={handleStartDateChange}
          onDateEndChange={handleEndDateChange}
          onReset={handleFilterReset}
          filters={tempFilters}
          onStatusChange={handleStatusChange}
          onOwnerChange={handleOwnerChange}
          onSearch={handleSearchValueChange}
        />
      </SuiBox>
      <SuiBox pt={3} display="flex" justifyContent="flex-end" m={2}>
        <OpportunityHeader title="Pipeline" type="pipeline" />
      </SuiBox>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <OpportunityList
            opps={filteredOpps.new}
            labels={pipelineBoard.new}
            openSend={handleOpenEmailDialog}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <OpportunityList
            opps={filteredOpps.pq}
            labels={pipelineBoard.pq}
            openSend={handleOpenEmailDialog}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <OpportunityList
            opps={filteredOpps.ai}
            labels={pipelineBoard.ai}
            openSend={handleOpenEmailDialog}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <OpportunityList
            opps={filteredOpps.docs}
            labels={pipelineBoard.docs}
            openSend={handleOpenEmailDialog}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <OpportunityList
            opps={filteredOpps.pa}
            labels={pipelineBoard.pa}
            openSend={handleOpenEmailDialog}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <OpportunityList
            opps={filteredOpps.uc}
            labels={pipelineBoard.uc}
            openSend={handleOpenEmailDialog}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <OpportunityList
            opps={filteredOpps.les}
            labels={pipelineBoard.les}
            openSend={handleOpenEmailDialog}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <OpportunityList
            opps={filteredOpps.closed}
            labels={pipelineBoard.closed}
            openSend={handleOpenEmailDialog}
          />
        </Grid>
        <Grid item xs={12} md={2} />
      </Grid>
      <SuiBox pt={3} display="flex" justifyContent="flex-end" m={2}>
        <OpportunityHeader title="Future Follow-ups" type="followup" />
      </SuiBox>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <OpportunityList
            opps={filteredOpps.hold}
            labels={pipelineBoard.hold}
            openSend={handleOpenEmailDialog}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <OpportunityList
            opps={filteredOpps.crn}
            labels={pipelineBoard.crn}
            openSend={handleOpenEmailDialog}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <OpportunityList
            opps={filteredOpps.nr}
            labels={pipelineBoard.nr}
            openSend={handleOpenEmailDialog}
          />
        </Grid>
      </Grid>
      <SuiBox pt={3} display="flex" justifyContent="flex-end" m={2}>
        <OpportunityHeader title="Lost Opportunities" type="lost" />
      </SuiBox>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <OpportunityList
            opps={filteredOpps.lost}
            labels={pipelineBoard.lost}
            openSend={handleOpenEmailDialog}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <OpportunityList
            opps={filteredOpps.dnq}
            labels={pipelineBoard.dnq}
            openSend={handleOpenEmailDialog}
          />
        </Grid>
      </Grid>
      <EmailDialog opp={currentOpp} open={openEmailDialog} onClose={handleCloseEmailDialog} />
    </DashboardLayout>
  );
}

export default Opportunities;
