// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function Verification() {
  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SuiBox mb={3}>
          <SuiTypography variant="h5" fontWeight="bold" textColor="primary">
            Woohoo! You are almost finished with your account set up.
          </SuiTypography>
        </SuiBox>
        <SuiBox mb={3}>
          <SuiTypography variant="body2" fontWeight="regular" textColor="text" textAlign="left">
            <b>If you created an account with an email and password,</b> check your inbox and follow
            the link to verify your email address. Please check your spam folder if you do not see
            an email from us.
          </SuiTypography>
        </SuiBox>
        <SuiBox mb={3}>
          <SuiTypography variant="body2" fontWeight="regular" textColor="text" textAlign="left">
            <b>If you created an account with Google,</b> your email has already been verified.
          </SuiTypography>
        </SuiBox>
        <SuiBox mb={3}>
          <SuiTypography variant="body2" fontWeight="bold" textColor="text" textAlign="left">
            Finally, you will be logged out and redirected to the sign in page to complete the
            process.
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default Verification;
