// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Day JS
import dayjs from "dayjs";

// @mui material components
import { Card, CardContent, CardHeader, Divider, Grid, Stack, Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function RoleAdministrationCard(props) {
  const { users, inputs, onChange, onSubmit } = props;
  const { values } = breakpoints;

  const options = [
    { value: "admin", label: "Admininstrator" },
    { value: "user", label: "User" },
  ];

  return (
    <Card style={{ paddingTop: 5, paddingRight: 5, paddingLeft: 5, paddingBottom: 10 }}>
      <CardHeader
        title={
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <SuiTypography variant="h5" fontWeight="bold">
              Company Users and Roles
            </SuiTypography>
            <Tooltip title="View and set user account privileges. Administrators have additional access to this page, company templates, billing and subscription.">
              <HelpIcon color="#8392ab" />
            </Tooltip>
          </Stack>
        }
      />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={window.innerWidth > values.md ? 4 : 6}>
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Name
                </SuiTypography>
              </Grid>
              {window.innerWidth > values.md ? (
                <Grid item xs={4}>
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Account Created
                  </SuiTypography>
                </Grid>
              ) : null}
              <Grid item xs={window.innerWidth > values.md ? 4 : 6}>
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  User Role
                </SuiTypography>
              </Grid>
            </Grid>
          </Grid>
          {users && users.length !== 0 ? (
            users.map((member, index) => (
              <Grid item xs={12} key={member.uid}>
                <Grid container alignItems="center">
                  {index === 0 ? (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  ) : null}
                  <Grid item xs={window.innerWidth > values.md ? 4 : 6}>
                    <SuiTypography
                      variant={window.innerWidth > values.md ? "body2" : "caption"}
                      fontWeight="bold"
                      textColor="info"
                    >{`${member.firstName} ${member.lastName}`}</SuiTypography>
                  </Grid>
                  {window.innerWidth > values.md ? (
                    <Grid item xs={4}>
                      <SuiTypography variant="body2" fontWeight="medium">
                        {dayjs(member.createdAt).format("MM/DD/YYYY")}
                      </SuiTypography>
                    </Grid>
                  ) : null}
                  <Grid item xs={window.innerWidth > values.md ? 4 : 6}>
                    <SuiSelect
                      value={
                        inputs[index].admin
                          ? options.filter((option) => option.value === "admin")
                          : options.filter((option) => option.value === "user")
                      }
                      placeholder="Select Role"
                      options={options}
                      onChange={(e) => onChange(e, index)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item xs={4}>
              <SuiTypography variant="body2" fontWeight="medium">
                No current users
              </SuiTypography>
            </Grid>
          )}
          <Grid item xs={12} align="end" style={{ marginTop: 10 }}>
            <SuiButton variant="gradient" buttonColor="error" size="medium" onClick={onSubmit}>
              Save Changes
            </SuiButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

RoleAdministrationCard.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      createdAt: PropTypes.string,
      uid: PropTypes.string,
      admin: PropTypes.bool,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      createdAt: PropTypes.string,
      admin: PropTypes.bool,
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default RoleAdministrationCard;
