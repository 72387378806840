// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiDatePicker from "components/SuiDatePicker";
import FormField from "layouts/profile/components/FormField";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
import { useSelector } from "react-redux";

function BasicsLeadForm(props) {
  const { values } = breakpoints;
  const { inputs, onChange, onSelectChange, onDateChange } = props;

  const partnersAll = useSelector((state) => state.data.partners);

  const setPartnersSelect = () => {
    const data = [];
    partnersAll.forEach((partner) =>
      data.push({ value: partner.partnerId, label: partner.basic.name })
    );
    return data;
  };

  const options = {
    partner: setPartnersSelect(),
    statusMarital: [
      { value: "single", label: "Single" },
      { value: "married", label: "Married" },
      { value: "divorced", label: "Divorced" },
      { value: "widowed", label: "Widowed" },
      { value: "separated", label: "Separated" },
    ],
    statusCitizen: [
      { value: "citizen", label: "US Citizen" },
      { value: "nonImmigrant", label: "Non-Immigrant" },
      { value: "permanentResident", label: "Permanent Resident" },
      { value: "undocumented", label: "Undocumented" },
    ],
  };

  return (
    <Accordion
      className="overflow-visible"
      style={{ borderRadius: 10, boxShadow: "0px 0px 20px #ECEDEE" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="basics-accordion"
        id="basics-accordion-header"
        style={{ marginLeft: 15 }}
      >
        <SuiTypography
          textTransform="capitalize"
          fontWeight="bold"
          variant={window.innerWidth < values.sm ? "h6" : "h5"}
        >
          Basics
        </SuiTypography>
      </AccordionSummary>
      <SuiBox p={3}>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormField
                type="text"
                name="name"
                value={inputs.name}
                label="Name"
                placeholder=""
                onChange={(e) => onChange(e, "basics")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                name="email"
                value={inputs.email}
                label="email"
                placeholder=""
                onChange={(e) => onChange(e, "basics")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                name="phone"
                value={inputs.phone}
                label="Phone"
                placeholder=""
                onChange={(e) => onChange(e, "basics")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Birthdate
                  </SuiTypography>
                </SuiBox>
                <SuiDatePicker
                  value={inputs.birthdate}
                  onChange={(e) => onDateChange(e, "basics", "birthdate")}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                name="ssn"
                value={inputs.ssn}
                label="Social Security Number"
                placeholder=""
                onChange={(e) => onChange(e, "basics")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Marital Status
                  </SuiTypography>
                </SuiBox>
                <SuiSelect
                  value={
                    inputs.statusMarital === ""
                      ? ""
                      : options.statusMarital.filter(
                          (option) => option.value === inputs.statusMarital
                        )
                  }
                  placeholder=""
                  options={options.statusMarital}
                  onChange={(e) => onSelectChange(e, "basics", "statusMarital")}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Citizenship Status
                  </SuiTypography>
                </SuiBox>
                <SuiSelect
                  value={
                    inputs.statusCitizen === ""
                      ? ""
                      : options.statusCitizen.filter(
                          (option) => option.value === inputs.statusCitizen
                        )
                  }
                  placeholder=""
                  options={options.statusCitizen}
                  onChange={(e) => onSelectChange(e, "basics", "statusCitizen")}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                name="estimatedFico"
                value={inputs.estimatedFico}
                label="Estimated FICO Score"
                placeholder=""
                onChange={(e) => onChange(e, "basics")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Partner Referrer
                  </SuiTypography>
                </SuiBox>
                <SuiSelect
                  value={
                    inputs.partner === ""
                      ? ""
                      : options.partner.filter((option) => option.value === inputs.partner)
                  }
                  placeholder=""
                  options={options.partner}
                  onChange={(e) => onSelectChange(e, "basics", "partner")}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </AccordionDetails>
      </SuiBox>
    </Accordion>
  );
}

BasicsLeadForm.propTypes = {
  inputs: PropTypes.shape({
    birthdate: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    estimatedFico: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    ssn: PropTypes.string.isRequired,
    statusCitizen: PropTypes.string.isRequired,
    statusMarital: PropTypes.string.isRequired,
    partner: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
};

export default BasicsLeadForm;
