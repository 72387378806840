import { useState } from "react";
import { useSelector } from "react-redux";

// axios
import axios from "axios";

// Stripe
import { loadStripe } from "@stripe/stripe-js";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Divider } from "material-ui";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import LoanaOnboardingImage from "assets/images/loana-leads-onboarding.jpg";

const stripePromise = loadStripe(
  "pk_test_51JuMxABs5Bn0IenuKiELx8P4GuKGfLH8eMOwD086uNWvAUM9NHWkuY2JXFJU65yZBCxcDNF5Ajmkgo2AN1EazUD800dduhl2LN"
);

function ImportLead() {
  const { values } = breakpoints;

  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);
      const data = {
        stripePrice: "price_1K6OBhBs5Bn0IenuGcpKFMBR",
        email: user.auth.email,
        companyId: user.profile.companyId,
      };

      const stripe = await stripePromise;

      const response = await axios.post("/stripe/checkout", data).then((res) => res.data.message);

      const result = await stripe.redirectToCheckout({
        sessionId: response,
      });

      return Promise.all([stripe, response, result]).then((results) => results);
    } catch (error) {
      return error;
    }
  };

  return (
    <DashboardLayout loading={loading}>
      <LoanaTopNavbar />
      <SuiBox pt={6} pb={3}>
        <Card>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <SuiBox pl={5} pt={5} pb={2}>
                <SuiTypography
                  textTransform="capitalize"
                  fontWeight="bold"
                  variant={window.innerWidth < values.sm ? "h3" : "h2"}
                >
                  The Loana Import Process
                </SuiTypography>
                <Divider />
              </SuiBox>
              <SuiBox pl={5} pb={2}>
                <SuiTypography fontWeight="regular" variant="h6">
                  Our goal is to make your transition to Loana as smooth as possible. Our focus is
                  on value and not profits. That is exactly why Loana is free to use!
                </SuiTypography>
              </SuiBox>
              <SuiBox pl={5} pb={2}>
                <SuiTypography fontWeight="regular" variant="h6">
                  Each transition to a new CRM requires importing your previous leads/opportunities.
                  This process can be a major headache, but we are here to change that!
                </SuiTypography>
              </SuiBox>
              <SuiBox pl={5} pb={2}>
                <SuiTypography fontWeight="regular" variant="h6">
                  <span style={{ color: "#17c1e8", fontWeight: "bold" }}>How?</span> During a 1-hour
                  meeting, a Loana Onboarding Specialist will{" "}
                  <span style={{ color: "#ea0606", fontWeight: "bold" }}>
                    educate you on key features
                  </span>{" "}
                  of Loana,{" "}
                  <span style={{ color: "#ea0606", fontWeight: "bold" }}>
                    gather vital data for you to be successful
                  </span>
                  , guarantee an{" "}
                  <span style={{ color: "#ea0606", fontWeight: "bold" }}>
                    understanding of your current pipeline
                  </span>
                  , and be your{" "}
                  <span style={{ color: "#ea0606", fontWeight: "bold" }}>
                    point-of-contact for your questions
                  </span>
                  .
                </SuiTypography>
              </SuiBox>
              <SuiBox pl={5} pb={2}>
                <SuiTypography fontWeight="regular" variant="h6">
                  <span style={{ color: "#17c1e8", fontWeight: "bold" }}>Why?</span> Every CRM has a
                  unique way to format and collect data. Throughout years of experience, we have
                  found the most efficient solution is to work directly with and help you from the
                  very beginning.{" "}
                  <span style={{ color: "#ea0606", fontWeight: "bold" }}>We save you time</span> by
                  connecting you with an expert that will guarantee your lead data is accurately
                  translated over to Loana.
                </SuiTypography>
              </SuiBox>
              <SuiBox pl={5} pb={2}>
                <SuiTypography fontWeight="regular" variant="h6">
                  <span style={{ color: "#17c1e8", fontWeight: "bold" }}>What can I expect?</span>{" "}
                  You will schedule a 1-hour meeting with a Loana Onboarding Specialist. After the
                  meeting has concluded, you can expect your current pipeline to be imported within
                  48 hours.
                </SuiTypography>
              </SuiBox>
              <SuiBox pl={5} pb={2}>
                <SuiTypography fontWeight="regular" variant="h6">
                  <span style={{ color: "#17c1e8", fontWeight: "bold" }}>
                    What is required of me?
                  </span>{" "}
                  1-hour of your time to meet with your Loana Onboarding Specialist, your previous
                  CRM or lead tracking system&apos;s data, and any questions you have for us.
                </SuiTypography>
              </SuiBox>
              <SuiBox pl={5} pb={2}>
                <SuiTypography fontWeight="regular" variant="h6">
                  <span style={{ color: "#17c1e8", fontWeight: "bold" }}>What do I get?</span>{" "}
                  <SuiBox pl={5} pb={2}>
                    <span style={{ color: "#ea0606", fontWeight: "bold" }}>
                      <ul>
                        <li>A dedicated Loana Onboarding Specialist</li>
                        <li>A 1-hour onboarding meeting</li>
                        <li>The process saves you time</li>
                        <li>Loana key features education</li>
                        <li>A point-of-contact for your onboarding questions</li>
                        <li>Your current pipeline translated over to Loana</li>
                      </ul>
                    </span>
                  </SuiBox>
                </SuiTypography>
              </SuiBox>
            </Grid>
            <Grid item xs={12} sm={6} padding={5}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} alignContent="center">
                  <img
                    alt="Loana import leads onboarding"
                    src={LoanaOnboardingImage}
                    style={{ borderRadius: 10 }}
                    width="100%"
                    height="auto"
                  />
                </Grid>
                <Grid item xs={12} align="center">
                  <SuiBox pl={5} pb={2}>
                    <SuiTypography fontWeight="bold" variant="h3">
                      $499 one-time
                    </SuiTypography>
                  </SuiBox>
                </Grid>
                <Grid item xs={12} align="center">
                  <SuiBox pl={5} pb={2}>
                    <SuiButton
                      variant="gradient"
                      buttonColor="error"
                      size="large"
                      onClick={handleClick}
                    >
                      <SuiTypography fontWeight="bold" variant="h4">
                        <span style={{ color: "#FFFFFF" }}>Get Started Now!</span>
                      </SuiTypography>
                    </SuiButton>
                  </SuiBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </SuiBox>
    </DashboardLayout>
  );
}

export default ImportLead;
