// Day JS
import dayjs from "dayjs";

// Redux
import { SET_LEADS } from "../../redux/types";

const leadsRefresh = (dispatch, data) => {
  // Format lead createdAt dates to dayjs format
  const formattedLeads = [];
  data.forEach((lead) => {
    formattedLeads.push({
      ...lead,
      basic: {
        ...lead.basic,
        createdAt: dayjs(lead.basic.createdAt).format("MM/DD/YYYY"),
      },
    });
  });
  dispatch({ type: SET_LEADS, payload: formattedLeads });
};

export default leadsRefresh;
