// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Card, CardContent, CardHeader, Grid, Stack, Divider } from "@mui/material";
import { Avatar } from "material-ui";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function TeamOverviewCard(props) {
  const { company } = props;
  const { values } = breakpoints;

  return (
    <Card>
      <CardHeader title="The Team" style={{ margin: 10 }} />
      <CardContent>
        <Grid container spacing={3}>
          {company && company.team.length !== 0
            ? company.team
                .sort((a, b) => a.createdAt - b.createdAt)
                .map((member) => (
                  <Grid item xs={12} key={member.uid}>
                    <Stack direction="row" spacing={3} alignItems="center">
                      <Avatar
                        alt={`${member.firstName} ${member.lastName} profile picture`}
                        src={member.photoURL}
                        style={{
                          objectFit: "cover",
                          border: "1px solid #17c1e8",
                          backgroundColor: "#FFFFFF",
                          padding: 4,
                        }}
                        size={window.innerWidth > values.md ? 100 : 50}
                      />
                      <Stack>
                        <SuiTypography
                          variant={window.innerWidth > values.md ? "h5" : "h6"}
                          textTransform="capitalize"
                        >{`${member.firstName} ${member.lastName}`}</SuiTypography>
                        <SuiTypography
                          variant={window.innerWidth > values.md ? "body2" : "caption"}
                          textTransform="capitalize"
                        >
                          {member.companyTitle}
                        </SuiTypography>
                      </Stack>
                    </Stack>
                    <Divider />
                  </Grid>
                ))
            : null}
        </Grid>
      </CardContent>
    </Card>
  );
}

TeamOverviewCard.propTypes = {
  company: PropTypes.shape({
    team: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        companyTitle: PropTypes.string,
        photoURL: PropTypes.string,
      }).isRequired
    ).isRequired,
  }).isRequired,
};

export default TeamOverviewCard;
