// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Custom styles for Header
import styles from "layouts/dashboards/company/team/components/Header/styles";

// Custome components
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

function Header(props) {
  const { company, onImageChange } = props;
  const { values } = breakpoints;

  const classes = styles();

  const handleSelectImage = () => {
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };

  return (
    <SuiBox position="relative">
      <LoanaTopNavbar />
      <SuiBox customClass={classes.profileHeader_background} />
      <Card className={classes.profileHeader_profile}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <SuiBox position="relative" height="max-content" mx="auto">
              <SuiAvatar
                src={company.photoURL}
                alt="company-photo"
                variant="rounded"
                size="xl"
                customClass="shadow-sm"
              />
              <SuiBox
                alt="profile picture"
                position="absolute"
                right={0}
                bottom={0}
                mr={-1}
                mb={-1}
              >
                <SuiButton
                  variant="gradient"
                  buttonColor="light"
                  size="small"
                  iconOnly
                  onClick={handleSelectImage}
                >
                  <input type="file" id="imageInput" hidden="hidden" onChange={onImageChange} />
                  <Icon className="">edit</Icon>
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid item>
            <SuiBox height="100%" mt={0.5} lineHeight={1}>
              <SuiTypography
                variant={window.innerWidth > values.md ? "h4" : "body2"}
                fontWeight="bold"
              >
                {company.companyName}
              </SuiTypography>
            </SuiBox>
          </Grid>
        </Grid>
      </Card>
    </SuiBox>
  );
}

Header.propTypes = {
  company: PropTypes.shape({
    companyName: PropTypes.string.isRequired,
    photoURL: PropTypes.string.isRequired,
  }).isRequired,
  onImageChange: PropTypes.func.isRequired,
};

export default Header;
