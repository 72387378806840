import { useState } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { Card, CardTitle, Checkbox } from "material-ui";
import { CardContent } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function LeadFormActions(props) {
  const { values } = breakpoints;
  const { onCheck, onSubmit, oppAddAvailable } = props;
  const [check, setCheck] = useState(false);

  const handleCheck = () => {
    if (check) {
      setCheck(false);
      onCheck(false);
    } else {
      setCheck(true);
      onCheck(true);
    }
  };

  return (
    <Card>
      <CardTitle>
        <SuiTypography
          textTransform="capitalize"
          fontWeight="bold"
          variant={window.innerWidth < values.sm ? "h6" : "h5"}
        >
          Actions
        </SuiTypography>
      </CardTitle>
      <SuiBox p={3}>
        <CardContent>
          <Grid container spacing={3}>
            {oppAddAvailable ? (
              <Grid item xs={12}>
                <SuiBox display="flex">
                  <Grid container alignItems="center">
                    <Grid item xs={2}>
                      <Checkbox name="createOpp" checked={check} onClick={handleCheck} />
                    </Grid>
                    <Grid item xs={10}>
                      <SuiTypography
                        variant="button"
                        fontWeight="regular"
                        customClass="cursor-pointer user-select-none"
                      >
                        Check the box to create an opportunity from this lead.
                      </SuiTypography>
                    </Grid>
                  </Grid>
                </SuiBox>
              </Grid>
            ) : null}

            <Grid item xs={12} align="center">
              <SuiButton variant="gradient" buttonColor="error" size="large" onClick={onSubmit}>
                Save Lead
              </SuiButton>
            </Grid>
          </Grid>
        </CardContent>
      </SuiBox>
    </Card>
  );
}

// Setting default values for the props of DataTable
LeadFormActions.defaultProps = {
  onCheck: null,
};

LeadFormActions.propTypes = {
  onCheck: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  oppAddAvailable: PropTypes.bool.isRequired,
};

export default LeadFormActions;
