import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiPostWithReturn } from "redux/actions/dataActions";
import { UI_ERROR, UI_ERROR_CLEAR } from "redux/types";

// sweetalert2 components
import Swal from "sweetalert2";

// @mui material components
import { Card, CardContent, CardHeader, Divider, Grid, Stack } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";
import SuiTypography from "components/SuiTypography";

// Utils
import faq from "utils/schemas/faq";

// Custom Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";
import HelpTicket from "./components/HelpTicket";
import FaqItem from "./components/FaqItem";

// Components

function Help() {
  const bypass = true;
  const dispatch = useDispatch();

  // FAQ Types
  const faqTypes = ["Leads and Opportunities", "Account", "Company", "Billing and Subscription"];

  // User, Data, UI Redux Objects
  const user = useSelector((state) => state.user);
  const loading = useSelector((state) => state.ui.loading);
  const inputError = useSelector((state) => state.ui.error);
  const [openError, setOpenError] = useState(false);

  // Help Ticket Inputs
  const [inputs, setInputs] = useState({
    type: "",
    page: "",
    priority: "",
    description: "",
    subject: "",
  });

  const handleInputsChange = (event) => {
    event.persist();
    setInputs((values) => ({ ...values, [event.target.name]: event.target.value }));
  };

  const handleSelectChange = (event, name) => {
    setInputs((selectValues) => ({
      ...selectValues,
      [name]: event.value,
    }));
  };

  const validateInputs = () => {
    let valid = true;
    if (inputs.type === "bug" || inputs.type === "feature") {
      if (
        inputs.page === "" ||
        inputs.priority === "" ||
        inputs.description === "" ||
        inputs.subject === ""
      ) {
        valid = false;
      }
    } else if (inputs.type === "question") {
      if (inputs.subject === "" || inputs.description === "") {
        valid = false;
      }
    }
    return valid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const submitData = {
      ...inputs,
      companyName: user.company.companyName,
      name: `${user.profile.firstName} ${user.profile.lastName}`,
      email: user.profile.email,
      companyTitle: user.profile.companyTitle,
    };
    if (validateInputs()) {
      dispatch(apiPostWithReturn("/email/ticket", submitData)).then(() => {
        Swal.fire("Ticket Sent", "Our support team will get back to you soon!", "success");
        setInputs({
          type: "",
          page: "",
          priority: "",
          description: "",
          subject: "",
        });
      });
    } else {
      dispatch({ type: UI_ERROR, payload: "Please make sure each field is completed below" });
    }
  };

  useEffect(() => {
    if (inputError !== "") {
      setOpenError(true);
    } else {
      setOpenError(false);
    }
  }, [inputError]);

  useEffect(() => {
    dispatch({ type: UI_ERROR_CLEAR });
  }, []);

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack>
              <SuiTypography variant="h3" fontWeight="bold">
                Loana FAQ and Help
              </SuiTypography>
              <Divider />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {faqTypes.map((section) => (
              <Stack spacing={3} marginTop={5} marginBottom={5} key={crypto.randomUUID()}>
                <SuiTypography variant="h5" textColor="info">
                  {section}
                </SuiTypography>
                <Divider />
                {faq.map((item) =>
                  item.type === section ? (
                    <FaqItem
                      title={item.title}
                      description={item.description}
                      key={crypto.randomUUID()}
                    />
                  ) : null
                )}
              </Stack>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Card>
              <SuiBox pt={1} pb={15} pl={3} pr={3}>
                <CardHeader title="Still need help? Submit a ticket to our support team!" />
                <CardContent>
                  {openError ? (
                    <SuiAlert color="warning">
                      <SuiTypography variant="body2" textColor="white">
                        <SuiTypography
                          component="a"
                          href="#"
                          variant="body2"
                          fontWeight="medium"
                          textColor="white"
                        >
                          Oops!&nbsp;
                        </SuiTypography>
                        {inputError}
                      </SuiTypography>
                    </SuiAlert>
                  ) : null}
                  <HelpTicket
                    inputs={inputs}
                    onSelectChange={handleSelectChange}
                    onChange={handleInputsChange}
                  />
                </CardContent>
                <Grid container justifyContent="flex-end" alignItems="flex-end">
                  <Grid item xs={12} align="end">
                    <SuiButton
                      variant="gradient"
                      buttonColor="error"
                      size="medium"
                      disabled={inputs.type === "" || false}
                      onClick={handleSubmit}
                    >
                      Send Ticket
                    </SuiButton>
                  </Grid>
                </Grid>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
}

export default Help;
