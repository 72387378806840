import { apiGetWithReturn } from "redux/actions/dataActions";

// Utils
import partnersRefresh from "./partnersRefresh";

const handlePartnersRefresh = (dispatch, companyId) => {
  try {
    return dispatch(apiGetWithReturn(`/partner/all/${companyId}`)).then((res) => {
      partnersRefresh(dispatch, res.data.message);
      return res.data.message;
    });
  } catch (error) {
    return error;
  }
};

export default handlePartnersRefresh;
