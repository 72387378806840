import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiPostWithReturn, apiGetWithReturn } from "redux/actions/dataActions";
import { UI_ERROR, UI_ERROR_CLEAR } from "redux/types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";

// Utils
import companyStart from "utils/helpers/companyStart";

// Custom components
import LoanaSubscription from "components/LoanaSubscription";
import LoanaSubscriptionUpgrade from "components/LoanaSubscriptionUpgrade";
import AdministrationLayout from "./components/AdministrationLayout";
import RoleAdministrationCard from "./components/RoleAdministrationCard";
import InviteAdministrationCard from "./components/InviteAdministrationCard";
import LeadTemplateAdministrationCard from "./components/LeadTemplateAdministrationCard.js";
import FollowupTemplateAdministrationCard from "./components/FollowupTemplateAdministrationCard.js";
import SubscriptionCard from "./components/SubscriptionCard";
import BillingPortalCard from "./components/BillingPortalCard";

function Administration() {
  const bypass = true;

  // Dispatch declaration
  const dispatch = useDispatch();

  // Page tab states
  const [showUser, setShowUser] = useState(true);
  const [showTemplates, setShowTemplates] = useState(false);
  const [showBilling, setShowBilling] = useState(false);

  // Pending invites
  const [pendingInvites, setPendingInvites] = useState([]);

  // Opens UI Error
  const [openError, setOpenError] = useState(false);

  // User, Data, UI Redux Objects
  const company = useSelector((state) => state.user.company);
  const loading = useSelector((state) => state.ui.loading);
  const inputError = useSelector((state) => state.ui.error);

  /* --------------------- PURCHASE DIALOG COMPONENT --------------------- */
  const [openPurchaseDialog, setOpenPurchaseDialog] = useState(false);

  const handleOpenPurchaseDialog = () => setOpenPurchaseDialog(true);
  const handleCloseSubscription = () => setOpenPurchaseDialog(false);

  /* --------------------- UPGRADE DIALOG COMPONENT --------------------- */
  // Upgrade Dialog state
  const [openUpgradeDialog, setUpgradeDialog] = useState(false);

  const handleOpenUpgradeDialog = () => setUpgradeDialog(true);
  const handleCloseUpgradeDialog = () => setUpgradeDialog(false);

  /* --------------------- FOLLOWUP COMPONENT --------------------- */
  const [followupTemplate, setFollowupTemplate] = useState(company.followupTemplate);

  const handleFollowupTemplateChange = (event) => {
    event.persist();
    setFollowupTemplate((values) => ({
      ...values,
      [event.target.name]: parseInt(event.target.value, 10) || "",
    }));
  };

  const validateFollowupTemplate = () => {
    let valid = true;
    Object.values(followupTemplate).forEach((value) => {
      if (typeof value === "string") {
        valid = false;
      } else if (typeof value === "number") {
        if (value <= 0) {
          valid = false;
        }
      } else if (value === null || Number.isNaN(value)) {
        valid = false;
      }
    });
    return valid;
  };

  const handleFollowupTemplateSubmit = (event) => {
    event.preventDefault();
    const valid = validateFollowupTemplate();
    const submitData = {
      companyId: company.companyId,
      company: {
        followupTemplate,
      },
    };
    if (valid) {
      dispatch(apiPostWithReturn("/company/edit", submitData)).then(() => {
        dispatch(apiGetWithReturn(`/company/${company.companyId}`)).then((res) => {
          companyStart(dispatch, res.data.message);
          setOpenError(false);
        });
      });
    } else {
      dispatch({
        type: UI_ERROR,
        payload: "Please enter a number greater than 0 for each input",
      });
    }
  };

  /* --------------------- LEAD TEMPLATE COMPONENT --------------------- */
  const [leadTemplate, setLeadTemplate] = useState(company.leadTemplate);

  const handleLeadTemplateChange = (name) => {
    if (leadTemplate[name]) {
      setLeadTemplate((values) => ({ ...values, [name]: false }));
    } else {
      setLeadTemplate((values) => ({ ...values, [name]: true }));
    }
  };

  const handleLeadTemplateSubmit = (event) => {
    event.preventDefault();
    const submitData = {
      companyId: company.companyId,
      company: {
        leadTemplate,
      },
    };
    dispatch(apiPostWithReturn("/company/edit", submitData)).then(() => {
      dispatch(apiGetWithReturn(`/company/${company.companyId}`)).then((res) => {
        companyStart(dispatch, res.data.message);
        setOpenError(false);
      });
    });
  };

  /* --------------------- INVITE COMPONENT --------------------- */
  const [inviteInputs, setInviteInputs] = useState({
    email: "",
    role: "",
    inviteId: crypto.randomUUID(),
  });

  const handleInviteInputsSelectChange = (event, name) => {
    setInviteInputs((inputs) => ({
      ...inputs,
      [name]: event.value,
    }));
  };

  const handleInviteInputsChange = (event) => {
    setInviteInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const validateInviteInputs = () => {
    let valid = true;
    if (inviteInputs.email === "" || inviteInputs.role === "") {
      valid = false;
      dispatch({
        type: UI_ERROR,
        payload: "Please enter an email and select a role for the new team member",
      });
    }
    return valid;
  };

  const handleInviteSubmit = () => {
    const valid = validateInviteInputs();
    if (valid) {
      const submitData = {
        email: inviteInputs.email,
        role: inviteInputs.role,
        inviteId: inviteInputs.inviteId,
        createdAt: new Date().toISOString(),
        companyId: company.companyId,
        companyName: company.companyName,
        proActive: company.proActive,
        trialActive: company.trialActive,
        invited: true,
        done: false,
      };
      dispatch(apiPostWithReturn("/invite/add", submitData)).then(() => {
        setInviteInputs({
          email: "",
          role: "",
          inviteId: crypto.randomUUID(),
        });
        dispatch(apiGetWithReturn(`/invite/all/${company.companyId}`)).then((res) => {
          setPendingInvites(res.data.message);
        });
      });
    }
  };

  /* --------------------- USER ROLES COMPONENT --------------------- */
  const [inputs, setInputs] = useState(
    company.team && company.team.length !== 0
      ? company.team.sort((a, b) => a.lastName.localeCompare(b.lastName))
      : []
  );

  const handleRoleChange = (event, index) => {
    const changesArr = [...inputs];
    if (event.value === "admin") {
      changesArr[index].admin = true;
      setInputs(changesArr);
    } else {
      changesArr[index].admin = false;
      setInputs(changesArr);
    }
  };

  const handleRoleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      apiPostWithReturn("/user/claims", {
        team: inputs,
        companyId: company.companyId,
      })
    );
  };

  /* --------------------- TAB COMPONENT --------------------- */
  const handleContentChange = (value) => {
    switch (value) {
      case 0:
        setShowTemplates(false);
        setShowBilling(false);
        setShowUser(true);
        break;
      case 1:
        setShowBilling(false);
        setShowUser(false);
        setShowTemplates(true);
        break;
      case 2:
        setShowUser(false);
        setShowTemplates(false);
        setShowBilling(true);
        break;
      default:
        break;
    }
  };

  /* --------------------- CORE --------------------- */
  useEffect(() => {
    if (inputError !== "") {
      setOpenError(true);
    }
  }, [inputError]);

  useEffect(() => {
    if (company && company.companyId !== "") {
      dispatch(apiGetWithReturn(`/invite/all/${company.companyId}`)).then((res) => {
        setPendingInvites(res.data.message);
      });
    }
  }, [company]);

  useEffect(() => {
    dispatch({ type: UI_ERROR_CLEAR });
  }, []);

  return (
    <AdministrationLayout onTabChange={handleContentChange} loading={loading} bypass={bypass}>
      <SuiBox mt={5}>
        {showUser ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {openError ? (
                <Grid item xs={12}>
                  <SuiAlert color="warning">
                    <SuiTypography variant="body2" textColor="white">
                      <SuiTypography
                        component="a"
                        href="#"
                        variant="body2"
                        fontWeight="medium"
                        textColor="white"
                      >
                        Oops!&nbsp;
                      </SuiTypography>
                      {inputError}
                    </SuiTypography>
                  </SuiAlert>
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <RoleAdministrationCard
                users={
                  company.team && company.team.length !== 0
                    ? company.team.sort((a, b) => a.lastName.localeCompare(b.lastName))
                    : []
                }
                inputs={inputs}
                onChange={handleRoleChange}
                onSubmit={handleRoleSubmit}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InviteAdministrationCard
                inputs={inviteInputs}
                invites={
                  pendingInvites && pendingInvites.length !== 0
                    ? pendingInvites
                        .filter((item) => !item.done)
                        .sort((a, b) => a.createdAt - b.createdAt)
                    : []
                }
                onChange={handleInviteInputsChange}
                onSelectChange={handleInviteInputsSelectChange}
                onSubmit={handleInviteSubmit}
                onUpgrade={handleOpenUpgradeDialog}
              />
            </Grid>
          </Grid>
        ) : null}
        {showTemplates ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {openError ? (
                <Grid item xs={12}>
                  <SuiAlert color="warning">
                    <SuiTypography variant="body2" textColor="white">
                      <SuiTypography
                        component="a"
                        href="#"
                        variant="body2"
                        fontWeight="medium"
                        textColor="white"
                      >
                        Oops!&nbsp;
                      </SuiTypography>
                      {inputError}
                    </SuiTypography>
                  </SuiAlert>
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FollowupTemplateAdministrationCard
                onChange={handleFollowupTemplateChange}
                onSubmit={handleFollowupTemplateSubmit}
                currentTemplate={followupTemplate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LeadTemplateAdministrationCard
                onChange={handleLeadTemplateChange}
                onSubmit={handleLeadTemplateSubmit}
                currentTemplate={leadTemplate}
              />
            </Grid>
          </Grid>
        ) : null}
        {showBilling ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <SubscriptionCard
                onUpgrade={
                  company.proPlan === "free" ? handleOpenPurchaseDialog : handleOpenUpgradeDialog
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <BillingPortalCard />
            </Grid>
          </Grid>
        ) : null}
      </SuiBox>
      <LoanaSubscription
        open={openPurchaseDialog}
        title="Upgrade today to unlock the benefits of Loana Pro!"
        onClose={handleCloseSubscription}
      />
      <LoanaSubscriptionUpgrade
        open={openUpgradeDialog}
        onClose={handleCloseUpgradeDialog}
        type={company.proPlan === "single" ? "single" : "team"}
        title={
          company.proPlan === "single"
            ? "Upgrade to a Loana Pro Team plan to invite team members!"
            : company.proPlan === "team" && company.proSeats === company.team.length
            ? "You're currently at your team member limit. Add additional members to invite them!"
            : "Add additional team member slots to your Loana Pro plan!"
        }
      />
    </AdministrationLayout>
  );
}

export default Administration;
