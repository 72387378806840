// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Soft UI Dasboard PRO Material components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

function LoanaTaskCompletedList(props) {
  const { color, tasks } = props;
  const { values } = breakpoints;
  const { borderWidth } = borders;

  return (
    <SuiBox pb={2}>
      <Accordion style={{ borderRadius: 15, boxShadow: "0px 0px 20px #ECEDEE" }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <SuiBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography
                textTransform="capitalize"
                fontWeight="bold"
                variant={window.innerWidth < values.sm ? "h5" : "h4"}
              >
                Completed Tasks
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </AccordionSummary>
        <AccordionDetails>
          <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
            {tasks && tasks.length !== 0 ? (
              tasks.map((item) =>
                item.done ? (
                  <SuiBox
                    component="li"
                    width="100%"
                    pr={2}
                    mb={2}
                    borderLeft={`${borderWidth[3]} solid ${colors[color].main}`}
                    customClass="no-list-style"
                    key={item.taskId}
                  >
                    <SuiBox width="100%" pl={1} ml={2}>
                      <SuiBox display="flex" alignItems="center">
                        <SuiBox ml={0.2} lineHeight={1}>
                          <SuiTypography variant="button" fontWeight="medium">
                            {item.task}
                          </SuiTypography>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                  </SuiBox>
                ) : null
              )
            ) : (
              <SuiTypography
                variant="button"
                fontWeight="regular"
                textColor="text"
                py={2}
                align="center"
              >
                No tasks have been completed
              </SuiTypography>
            )}
          </SuiBox>
        </AccordionDetails>
      </Accordion>
    </SuiBox>
  );
}

LoanaTaskCompletedList.defaultProps = {
  color: "info",
};

LoanaTaskCompletedList.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      task: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      done: PropTypes.bool.isRequired,
    })
  ).isRequired,
};

export default LoanaTaskCompletedList;
