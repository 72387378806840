// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { Card, CardContent, CardHeader } from "@mui/material";

// Soft UI Dashboard PRO React components
import FormField from "layouts/profile/components/FormField";

function BasicsPartnerForm(props) {
  const { inputs, onChange } = props;

  return (
    <Card
      className="overflow-visible"
      style={{ borderRadius: 10, boxShadow: "0px 0px 20px #ECEDEE" }}
    >
      <CardHeader title="Basics" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type="text"
              name="name"
              value={inputs.name}
              label="Name"
              placeholder=""
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              name="email"
              value={inputs.email}
              label="email"
              placeholder=""
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              name="phone"
              value={inputs.phone}
              label="Phone"
              placeholder=""
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              name="company"
              value={inputs.company}
              label="Company"
              placeholder=""
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              name="companyTitle"
              value={inputs.companyTitle}
              label="Company Title"
              placeholder=""
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

BasicsPartnerForm.propTypes = {
  inputs: PropTypes.shape({
    company: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    companyTitle: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BasicsPartnerForm;
