import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Day JS
import dayjs from "dayjs";

// @mui material components
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import { Edit } from "@mui/icons-material";

// Soft UI Dasboard PRO Material components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dasboard PRO Material base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

function LoanaTask(props) {
  const { color, task, created, noDivider, onClick, taskId, onEdit } = props;
  const { borderWidth } = borders;

  const [checked, setChecked] = useState(false);

  const iconOnlyButton = true;

  const handleOnClick = () => {
    if (!checked) {
      setChecked(true);
      onClick(taskId);
    }
  };

  return (
    <SuiBox
      component="li"
      width="100%"
      pr={2}
      mb={2}
      borderLeft={`${borderWidth[3]} solid ${colors[color].main}`}
      customClass="no-list-style"
    >
      <SuiBox width="100%" pl={1} ml={2}>
        <SuiBox display="flex" alignItems="center">
          <Checkbox checked={checked} onClick={handleOnClick} />
          <SuiBox ml={0.2} lineHeight={1}>
            <SuiTypography variant="button" fontWeight="medium">
              {task}
            </SuiTypography>
          </SuiBox>
          <SuiBox ml="auto" pr={3} lineHeight={0}>
            <SuiButton
              onClick={(e) => onEdit(e, task, taskId)}
              iconOnly={iconOnlyButton}
              style={{ boxShadow: "0 0 0" }}
            >
              <Edit color="error" />
            </SuiButton>
          </SuiBox>
        </SuiBox>
        <SuiBox
          display="flex"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mt={2}
          ml={3}
          pl={0.5}
        >
          <SuiTypography
            display="block"
            variant="caption"
            fontWeight="medium"
            textColor="secondary"
            mr={2}
          >
            Created
          </SuiTypography>
          <SuiTypography variant="caption" fontWeight="bold" textColor="text">
            {dayjs(created).format("MM/DD/YYYY")}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
      {noDivider ? null : <Divider sx={{ marginBottom: 0 }} />}
    </SuiBox>
  );
}

// Setting default values for the props of LoanaTask
LoanaTask.defaultProps = {
  color: "info",
  noDivider: false,
};

// Typechecking props for the LoanaTask
LoanaTask.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  task: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  noDivider: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  taskId: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default LoanaTask;
