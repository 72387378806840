import { useEffect, useState } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Card, CardContent, CardHeader, Grid, Stack, Divider } from "@mui/material";
import { Avatar } from "material-ui";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function TeamLeaderboardRate(props) {
  const { data, team } = props;
  const { values } = breakpoints;

  const [leaderData, setLeaderData] = useState([]);

  const calculateLeaderboard = () => {
    const formattedData = [];
    const currentYear = new Date().getFullYear();
    team.forEach((member, index) => {
      let oppCountTotal = 0;
      let oppCountClosed = 0;
      formattedData.push({
        uid: member.uid,
        firstName: member.firstName,
        lastName: member.lastName,
        photoURL: member.photoURL,
        closeRate: 0,
      });
      Object.keys(data).forEach((status) => {
        if (data[status].length !== 0) {
          data[status].forEach((opp) => {
            if (opp.oppOwner === member.uid && new Date().getFullYear() === currentYear) {
              if (opp.status === "closed") {
                oppCountClosed += 1;
              }
              oppCountTotal += 1;
            }
          });
        }
      });
      const oppRate = oppCountClosed / oppCountTotal;
      if (Number.isNaN(oppRate)) {
        // checks for NaN value
        formattedData[index].closeRate = 0;
      } else {
        formattedData[index].closeRate = Math.round(oppRate * 100);
      }
    });
    setLeaderData(formattedData);
  };

  useEffect(() => {
    if (data && Object.keys(data).length !== 0) {
      calculateLeaderboard();
    }
  }, [data]);

  return (
    <Card>
      <CardHeader
        title={`Close Rate Leaderboard (${new Date().getFullYear()})`}
        style={{ margin: 10 }}
      />
      <CardContent>
        <Grid container spacing={3}>
          {leaderData && leaderData.length !== 0
            ? leaderData
                .sort((a, b) => b.closeRate - a.closeRate)
                .map((entry, index) => (
                  <Grid item xs={12} key={entry.uid}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Stack direction="row" spacing={3} alignItems="center">
                        <SuiTypography
                          variant={window.innerWidth > values.md ? "h5" : "caption"}
                          textColor="info"
                        >
                          #{index + 1}
                        </SuiTypography>
                        {window.innerWidth > values.md ? (
                          <Avatar
                            alt={`${entry.firstName} ${entry.lastName} profile picture`}
                            src={entry.photoURL}
                            style={{
                              objectFit: "cover",
                              border: "1px solid #17c1e8",
                              backgroundColor: "#FFFFFF",
                              padding: 4,
                            }}
                            size={50}
                          />
                        ) : null}

                        <SuiTypography
                          variant={window.innerWidth > values.md ? "h6" : "caption"}
                          textTransform="capitalize"
                        >{`${entry.firstName} ${entry.lastName}`}</SuiTypography>
                      </Stack>
                      <Grid item>
                        <SuiTypography
                          variant={window.innerWidth > values.md ? "h6" : "caption"}
                          fontWeight="bold"
                          textColor="success"
                        >
                          {entry.closeRate}%
                        </SuiTypography>
                      </Grid>
                    </Grid>
                    <Divider />
                  </Grid>
                ))
            : null}
        </Grid>
      </CardContent>
    </Card>
  );
}

TeamLeaderboardRate.propTypes = {
  data: PropTypes.shape({
    new: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        closedAmount: PropTypes.number,
        closedAt: PropTypes.string,
        oppOwner: PropTypes.string,
      })
    ),
    pq: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        closedAmount: PropTypes.number,
        closedAt: PropTypes.string,
        oppOwner: PropTypes.string,
      })
    ),
    ai: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        closedAmount: PropTypes.number,
        closedAt: PropTypes.string,
        oppOwner: PropTypes.string,
      })
    ),
    docs: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        closedAmount: PropTypes.number,
        closedAt: PropTypes.string,
        oppOwner: PropTypes.string,
      })
    ),
    pa: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        closedAmount: PropTypes.number,
        closedAt: PropTypes.string,
        oppOwner: PropTypes.string,
      })
    ),
    uc: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        closedAmount: PropTypes.number,
        closedAt: PropTypes.string,
        oppOwner: PropTypes.string,
      })
    ),
    les: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        closedAmount: PropTypes.number,
        closedAt: PropTypes.string,
        oppOwner: PropTypes.string,
      })
    ),
    closed: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        closedAmount: PropTypes.number,
        closedAt: PropTypes.string,
        oppOwner: PropTypes.string,
      })
    ),
    lost: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        closedAmount: PropTypes.number,
        closedAt: PropTypes.string,
        oppOwner: PropTypes.string,
      })
    ),
    dnq: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        closedAmount: PropTypes.number,
        closedAt: PropTypes.string,
        oppOwner: PropTypes.string,
      })
    ),
    hold: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        closedAmount: PropTypes.number,
        closedAt: PropTypes.string,
        oppOwner: PropTypes.string,
      })
    ),
    crn: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        closedAmount: PropTypes.number,
        closedAt: PropTypes.string,
        oppOwner: PropTypes.string,
      })
    ),
  }).isRequired,
  team: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      photoURL: PropTypes.string,
      uid: PropTypes.string,
    })
  ).isRequired,
};

export default TeamLeaderboardRate;
