// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Proptypes
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";

// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField";

function SettingsProfile({ onImageChange, onChange, inputs, profileImageSrc }) {
  // const options = ["Mortgages", "Real Estate"];
  // const values = ["mortgage", "realtor"];

  const handleSelectImage = () => {
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };

  return (
    <SuiBox>
      <SuiBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} container justifyContent="center">
            <SuiBox position="relative" height="max-content" mx="auto">
              <SuiAvatar src={profileImageSrc} alt="profile picture" size="xxl" variant="rounded" />
              <SuiBox
                alt="profile picture"
                position="absolute"
                right={0}
                bottom={0}
                mr={-1}
                mb={-1}
              >
                <SuiButton
                  variant="gradient"
                  buttonColor="light"
                  size="small"
                  iconOnly
                  onClick={handleSelectImage}
                >
                  <input type="file" id="imageInput" hidden="hidden" onChange={onImageChange} />
                  <Icon className="">edit</Icon>
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              {/* <Grid item xs={12}>
                <SuiBox>
                  <FormRadio
                    size={3}
                    label="My profession is in..."
                    name="role"
                    options={options}
                    values={values}
                    input={inputs.role || ""}
                    handler={onChange}
                  />
                </SuiBox>
              </Grid> */}
              <Grid item xs={12}>
                <SuiBox>
                  <FormField
                    type="text"
                    name="firstName"
                    value={inputs.firstName || ""}
                    label="first name"
                    placeholder="Eg. Michael"
                    onChange={onChange}
                  />
                </SuiBox>
              </Grid>
              <Grid item xs={12}>
                <SuiBox>
                  <FormField
                    type="text"
                    name="lastName"
                    value={inputs.lastName || ""}
                    label="last name"
                    placeholder="Eg. Tomson"
                    onChange={onChange}
                  />
                </SuiBox>
              </Grid>
              <Grid item xs={12}>
                <SuiBox>
                  <FormField
                    type="text"
                    name="phone"
                    value={inputs.phone || ""}
                    label="phone"
                    placeholder="Eg. 1112223333"
                    onChange={onChange}
                  />
                </SuiBox>
              </Grid>
              <Grid item xs={12}>
                <SuiBox>
                  <FormField
                    type="text"
                    name="companyTitle"
                    value={inputs.companyTitle || ""}
                    label="company position/title"
                    placeholder="Eg. CEO"
                    onChange={onChange}
                  />
                </SuiBox>
              </Grid>
              <Grid item xs={12}>
                <SuiBox>
                  <FormField
                    type="text"
                    name="nmls"
                    value={inputs.nmls || ""}
                    label="NMLS # (If applicable)"
                    placeholder="Eg. 123456"
                    onChange={onChange}
                  />
                </SuiBox>
              </Grid>
              {/* <Grid item xs={12}>
                <SuiBox>
                  <FormField
                    type="text"
                    name="realtorLicense"
                    value={inputs.realtorLicense || ""}
                    label="Real Estate License # (If applicable)"
                    placeholder="Eg. 123456"
                    onChange={onChange}
                  />
                </SuiBox>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

SettingsProfile.propTypes = {
  onChange: PropTypes.func.isRequired,
  onImageChange: PropTypes.func.isRequired,
  inputs: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    role: PropTypes.string,
    nmls: PropTypes.string,
    realtorLicense: PropTypes.string,
    companyTitle: PropTypes.string,
  }).isRequired,
  profileImageSrc: PropTypes.string.isRequired,
};

export default SettingsProfile;
