import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { apiPostWithReturn } from "redux/actions/dataActions";
import { UI_ERROR_CLEAR } from "redux/types";

// Firebase
import { collection, query, onSnapshot, getFirestore } from "firebase/firestore";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Stack,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Utils
import partnersRefresh from "utils/helpers/partnersRefresh";

// Custom Components
import LeadsDataTable from "./components/LeadsDataTable";

function Partners() {
  const { values } = breakpoints;
  const bypass = true;

  const db = getFirestore();
  const dispatch = useDispatch();
  const history = useHistory();

  const loading = useSelector((state) => state.ui.loading);
  const partnersAll = useSelector((state) => state.data.partners);
  const user = useSelector((state) => state.user);

  const [currentPartner, setCurrentPartner] = useState({ basic: { name: "" }, partnerId: "" });
  const [openDelete, setOpenDelete] = useState(false);
  const [data, setData] = useState({
    columns: [
      { Header: "name", accessor: "basic.name", id: "name" },
      { Header: "email", accessor: "basic.email", id: "email" },
      { Header: "phone", accessor: "basic.phone", id: "phone" },
      {
        Header: "created",
        accessor: "basic.createdAt",
        id: "createdAt",
        filter: (rows, id, filterValue) =>
          rows.filter(
            (row) =>
              new Date(row.values[id]).toISOString() >= filterValue[0] &&
              new Date(row.values[id]).toISOString() <= filterValue[1]
          ),
      },
      { Header: "actions", accessor: "actions", width: "10%", id: "actions" },
    ],
    rows: [],
  });

  const handleDeleteOpen = (event, partner) => {
    event.preventDefault();
    setCurrentPartner(partner);
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setCurrentPartner({ basic: { name: "" }, partnerId: "" });
  };

  const handleDeletePartner = () => {
    handleDeleteClose();
    dispatch(
      apiPostWithReturn("/partner/delete", {
        partnerId: currentPartner.partnerId,
        companyId: user.company.companyId,
      })
    );
  };

  const handleEditRedirect = (event, id) => {
    event.preventDefault();
    history.push({
      pathname: `/pipeline/partners/edit/${id}`,
      state: { redirectToOpps: false, returnURL: "" },
    });
  };

  const addActions = (partners) => {
    const newPartners = [];
    partners.forEach((partner) => {
      newPartners.push({
        ...partner,
        actions: (
          <Grid container>
            <Grid
              item
              sm={6}
              onClick={(e) => handleEditRedirect(e, partner.partnerId)}
              style={{ cursor: "pointer" }}
            >
              <Tooltip title="Edit Partner">
                <IconButton aria-label="edit" color="error">
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item sm={6}>
              <Tooltip title="Delete Partner">
                <IconButton
                  aria-label="delete"
                  color="error"
                  onClick={(e) => handleDeleteOpen(e, partner)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        ),
      });
    });
    return newPartners;
  };

  useEffect(() => {
    const rowsWithActions = addActions(partnersAll);
    setData((tableData) => ({
      ...tableData,
      rows: rowsWithActions,
    }));
  }, [partnersAll]);

  // Creates Firestore channel to listen for realtime document updates
  useEffect(() => {
    const q = query(collection(db, `/companies/${user.company.companyId}/partners`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const queryData = [];
      querySnapshot.forEach((doc) => {
        queryData.push(doc.data());
      });
      partnersRefresh(dispatch, queryData);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    dispatch({ type: UI_ERROR_CLEAR });
  }, []);

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox pt={6} pb={3}>
        <Card>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="flex-start"
            padding={3}
          >
            <Grid item xs={12} md={6}>
              <SuiTypography
                textTransform="capitalize"
                fontWeight="bold"
                variant={window.innerWidth < values.sm ? "h5" : "h4"}
              >
                Partners
              </SuiTypography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                spacing={2}
                direction="row"
                justifyContent={window.innerWidth > values.md ? "end" : "center"}
              >
                <Link to="/pipeline/partners/add" className="decoration-none">
                  <SuiButton variant="gradient" buttonColor="error" size="small">
                    + new partner
                  </SuiButton>
                </Link>
              </Stack>
            </Grid>
          </Grid>
          {partnersAll && partnersAll.length !== 0 ? (
            <LeadsDataTable
              entriesPerPage={{
                defaultValue: 10,
                entries: [10, 25, 50],
              }}
              table={data}
              canSearch
            />
          ) : (
            <Grid container textAlign="center" padding={window.innerWidth > values.md ? 20 : 8}>
              <Grid item xs={12}>
                <SuiTypography variant="body2">No partners have been added</SuiTypography>
              </Grid>
            </Grid>
          )}
        </Card>
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Are you sure you wish to delete ${currentPartner.basic.name}?`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action will permanently delete this partner from your company&apos;s partners
              collection.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <SuiButton
              variant="outlined"
              buttonColor="error"
              size="small"
              onClick={handleDeleteClose}
            >
              cancel
            </SuiButton>
            <SuiButton
              variant="gradient"
              buttonColor="error"
              size="small"
              onClick={handleDeletePartner}
            >
              delete
            </SuiButton>
          </DialogActions>
        </Dialog>
      </SuiBox>
    </DashboardLayout>
  );
}

export default Partners;
