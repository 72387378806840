import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiPostWithReturn, apiGetWithReturn } from "redux/actions/dataActions";
import { SET_PROFILE, UI_ERROR_CLEAR } from "redux/types";

// @mui material components
import { Grid } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
// import SmsIcon from "@mui/icons-material/Sms";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import PaidIcon from "@mui/icons-material/Paid";
import CakeIcon from "@mui/icons-material/Cake";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

// Components
import AutomationsCard from "./components/AutomationsCard";

function Automations() {
  const bypass = true;
  const dispatch = useDispatch();

  // User, Data, UI Redux Objects
  const loading = useSelector((state) => state.ui.loading);
  const user = useSelector((state) => state.user);

  // Switch states
  const [birthdayState, setBirthdayState] = useState(user.profile.birthdaysActive);
  const [helloState, setHelloState] = useState(user.profile.helloLoanaActive);
  const [refinanceState, setRefinanceState] = useState(user.profile.refinanceActive);

  // Show switch options
  const options = {
    email: false,
    sms: false,
    birthday: true,
    hello: true,
    refinance: true,
  };

  const changeAutomationActivation = (type, active) => {
    dispatch(
      apiPostWithReturn("/automation/edit", {
        automation: type,
        activate: active,
      })
    ).then((res) => {
      if (res.status === 200) {
        dispatch(apiGetWithReturn("/user/profile")).then((result) => {
          dispatch({ type: SET_PROFILE, payload: result.data.message });
        });
      }
    });
  };

  const handleSwitchChange = (event, type) => {
    event.preventDefault();
    switch (type) {
      case "birthday":
        changeAutomationActivation("birthdaysActive", !birthdayState);
        setBirthdayState(!birthdayState);
        break;
      case "hello":
        changeAutomationActivation("helloLoanaActive", !helloState);
        setHelloState(!helloState);
        break;
      case "refinance":
        changeAutomationActivation("refinanceActive", !refinanceState);
        setRefinanceState(!refinanceState);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch({ type: UI_ERROR_CLEAR });
  }, []);

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <AutomationsCard
              title="Birthdays"
              description="Automatically send Happy Birthday messages to your opportunities"
              icon={<CakeIcon fontSize="large" color={birthdayState ? "light" : "dark"} />}
              showSwitch={options.birthday}
              path="/tools/automations/birthdays"
              state={birthdayState}
              onChange={(e) => handleSwitchChange(e, "birthday")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <AutomationsCard
              title="Hello Loana"
              description="Receive a daily message containing your tasks and follow ups"
              icon={<EmojiPeopleIcon fontSize="large" color={helloState ? "light" : "dark"} />}
              showSwitch={options.hello}
              path="/tools/automations/helloloana"
              state={helloState}
              onChange={(e) => handleSwitchChange(e, "hello")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <AutomationsCard
              title="Refinance Journey"
              description="Create an automated journey for potential refinance customers"
              icon={<PaidIcon fontSize="large" color={refinanceState ? "light" : "dark"} />}
              showSwitch={options.refinance}
              path="/tools/automations/refinance"
              state={refinanceState}
              onChange={(e) => handleSwitchChange(e, "refinance")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <AutomationsCard
              title="Email Automations"
              description="Create templates to quickly follow up with your opportunities via email"
              icon={<EmailIcon fontSize="large" color="light" />}
              showSwitch={options.email}
              path="/tools/automations/email"
            />
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <AutomationsCard
              title="SMS Automations"
              description="Create templates to quickly follow up with your opportunities via SMS"
              icon={<SmsIcon fontSize="large" color="light" />}
              showSwitch={options.sms}
              path="/dashboard/tools/automations/sms"
            />
          </Grid> */}
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
}

export default Automations;
