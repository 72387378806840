import { useState } from "react";
import { useHistory } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Grid, Stack, Checkbox, Divider, Tooltip } from "@mui/material";
import Email from "@mui/icons-material/Email";
import StickyNote2 from "@mui/icons-material/StickyNote2";
import Phone from "@mui/icons-material/Phone";
import Person from "@mui/icons-material/Person";

// Soft UI Dasboard PRO Material components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dasboard PRO Material base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import breakpoints from "assets/theme/base/breakpoints";

function LoanaFollowup(props) {
  const { color, followup, noDivider, onClick } = props;
  const { borderWidth } = borders;
  const { values } = breakpoints;

  const history = useHistory();

  const [checked, setChecked] = useState(false);

  const handleOnCheck = () => {
    if (!checked) {
      setChecked(true);
      onClick(followup.type, followup.followupId, followup.oppId);
    }
  };

  const handleEditRedirect = (event, id) => {
    event.preventDefault();
    history.push({
      pathname: `/pipeline/opportunities/edit/${id}`,
      state: { redirectToFollowups: true },
    });
  };

  return (
    <SuiBox
      component="li"
      width="100%"
      pr={2}
      mb={2}
      borderLeft={`${borderWidth[3]} solid ${colors[color].main}`}
      customClass="no-list-style"
    >
      <SuiBox width="100%" pl={1} ml={2}>
        <SuiBox display="flex" alignItems="center">
          <Checkbox checked={checked} onClick={handleOnCheck} />
          <SuiBox
            ml={0.2}
            lineHeight={1}
            onClick={(e) => handleEditRedirect(e, followup.oppId)}
            style={{ cursor: "pointer" }}
          >
            <SuiTypography variant="button" fontWeight="medium">
              {followup.followup}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        {/* <Divider /> */}
        {followup.leads && followup.leads.length !== 0
          ? followup.leads.map((lead, index) => (
              <SuiBox
                ml={window.innerWidth > values.md ? 5 : 0}
                my={window.innerWidth > values.md ? 1 : 3}
                display="flex"
                alignItems="center"
                key={crypto.randomUUID()}
              >
                <Grid container align="start" spacing={1}>
                  <Grid item xs={12} md={4}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Person />
                      <SuiTypography variant="caption">{lead.name}</SuiTypography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Email />
                      <SuiTypography variant="caption">{lead.email}</SuiTypography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Phone />
                      <SuiTypography variant="caption">{lead.phone}</SuiTypography>
                    </Stack>
                  </Grid>

                  {window.innerWidth > values.md && index === 0 ? (
                    <Grid item xs={1}>
                      <Tooltip
                        title={
                          followup.notes && followup.notes.length !== 0
                            ? followup.notes[followup.notes.length - 1].message
                            : "No notes have been added for this opportunity"
                        }
                      >
                        <StickyNote2 />
                      </Tooltip>
                    </Grid>
                  ) : null}
                </Grid>
              </SuiBox>
            ))
          : null}
      </SuiBox>
      {noDivider ? null : <Divider sx={{ marginBottom: 0 }} />}
    </SuiBox>
  );
}

// Setting default values for the props of LoanaFollowUp
LoanaFollowup.defaultProps = {
  color: "info",
  noDivider: false,
};

// Typechecking props for the LoanaFollowUp
LoanaFollowup.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  followup: PropTypes.shape({
    oppId: PropTypes.string.isRequired,
    followupId: PropTypes.string.isRequired,
    followup: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    leads: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
      })
    ).isRequired,
    notes: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.string,
        message: PropTypes.string,
        noteId: PropTypes.string,
        noteOwner: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
  noDivider: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default LoanaFollowup;
