// Redux
import { SET_ISLOADING, SET_PROFILE, SET_ISEMPTY } from "../../redux/types";

const profileRemove = (dispatch) => {
  dispatch({ type: SET_ISEMPTY, payload: true });
  dispatch({ type: SET_PROFILE, payload: {} });
  dispatch({ type: SET_ISLOADING, payload: false });
};

export default profileRemove;
