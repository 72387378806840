// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Card, CardHeader, CardContent } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";

function NoteInput(props) {
  const { message, onChange } = props;

  return (
    <Card>
      <CardHeader title="Add a note" />
      <CardContent>
        <SuiBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
          mb={2}
          mt={2}
        >
          <SuiInput multiline fullWidth value={message} onChange={(e) => onChange(e, "note")} />
        </SuiBox>
      </CardContent>
    </Card>
  );
}

NoteInput.propTypes = {
  message: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NoteInput;
