const organizeOpps = (data) => {
  const organized = {
    new: [],
    pq: [],
    ai: [],
    docs: [],
    pa: [],
    uc: [],
    les: [],
    closed: [],
    crn: [],
    hold: [],
    dnq: [],
    lost: [],
    nr: [],
  };
  data.forEach((opp) => {
    if (opp.dnq) {
      organized.dnq.push(opp);
    } else if (opp.holdActive) {
      organized.hold.push(opp);
    } else if (opp.creditRepairActive) {
      organized.crn.push(opp);
    } else if (opp.noResponseActive) {
      organized.nr.push(opp);
    } else {
      organized[opp.status].push(opp);
    }
  });
  return organized;
};

export default organizeOpps;
