// @mui material components
import Grid from "@mui/material/Grid";
import { Checkbox, Divider } from "material-ui";

// Proptypes
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useState } from "react";

function LeadTemplate({ onChange }) {
  const [options, setOptions] = useState({
    basics: true,
    addresses: false,
    employment: false,
    calculator: false,
    declarations: false,
  });

  const handleChange = (event) => {
    if (options[event.target.name]) {
      setOptions((values) => ({ ...values, [event.target.name]: false }));
      onChange(event.target.name);
    } else {
      setOptions((values) => ({ ...values, [event.target.name]: true }));
      onChange(event.target.name);
    }
  };

  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SuiBox m={2} textAlign="left">
          <SuiTypography variant="h5" textColor="text">
            Awesome, we&apos;re making great progress! Next, we are going to establish a
            company-wide template for all incoming leads.
          </SuiTypography>
        </SuiBox>
        <SuiBox m={2} textAlign="left">
          <SuiTypography variant="body2" textColor="text">
            <span style={{ color: "#17c1e8" }}>
              <b>Why?</b>
            </span>{" "}
            This structure guarantees that every member of your team is using the same format for
            gathering info on any new lead.
          </SuiTypography>
        </SuiBox>
        <SuiBox m={2} textAlign="left">
          <SuiTypography variant="body2" textColor="text">
            <span style={{ color: "#17c1e8" }}>
              <b>How?</b>
            </span>{" "}
            Below you will select what pieces of information you want to capture for each lead.
            We&apos;ve broken down the info into 5 categories - Basics, Addresses, Employment,
            Qualification Questions, and 1003 Declarations.
          </SuiTypography>
        </SuiBox>

        <SuiBox m={2} textAlign="left">
          <SuiTypography variant="h5" textColor="info" fontWeight="medium">
            Categories
          </SuiTypography>
          <Divider />
          <SuiBox mt={2} textAlign="left">
            <SuiTypography variant="body2" textColor="text">
              <span style={{ color: "#cb0c9f" }}>
                <b>Basics:</b>
              </span>{" "}
              Contact info and personal data such as birthdate and social security number.
            </SuiTypography>
            <SuiTypography variant="body2" textColor="text">
              <span style={{ color: "#cb0c9f" }}>
                <b>Addresses:</b>
              </span>{" "}
              Current and previous address information.
            </SuiTypography>
            <SuiTypography variant="body2" textColor="text">
              <span style={{ color: "#cb0c9f" }}>
                <b>Employment:</b>
              </span>{" "}
              Current and previous employment.
            </SuiTypography>
            <SuiTypography variant="body2" textColor="text">
              <span style={{ color: "#cb0c9f" }}>
                <b>Qualification Questions:</b>
              </span>{" "}
              Debt and income related questions that include a calculated pre-qualified monthly
              amount.
            </SuiTypography>
            <SuiTypography variant="body2" textColor="text">
              <span style={{ color: "#cb0c9f" }}>
                <b>1003 Declarations:</b>
              </span>{" "}
              Questions directly from the 1003 application.
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox m={2} textAlign="left">
          <SuiTypography variant="h6" textColor="text" fontWeight="medium">
            Please check the categories you wish to include in your lead template:
          </SuiTypography>
          <Divider />
          <Grid container spacing={3} marginTop={1} justifyContent="center">
            <Grid item xs={12}>
              <SuiBox display="flex">
                <Grid container alignItems="center">
                  <Grid item xs={2}>
                    <Checkbox
                      name="basics"
                      checked={options.basics}
                      onClick={handleChange}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <SuiTypography
                      variant="button"
                      fontWeight="regular"
                      customClass="cursor-pointer user-select-none"
                    >
                      Basics
                    </SuiTypography>
                  </Grid>
                </Grid>
              </SuiBox>
              <SuiBox display="flex">
                <Grid container alignItems="center">
                  <Grid item xs={2}>
                    <Checkbox name="addresses" checked={options.addresses} onClick={handleChange} />
                  </Grid>
                  <Grid item xs={10}>
                    <SuiTypography
                      variant="button"
                      fontWeight="regular"
                      customClass="cursor-pointer user-select-none"
                    >
                      Addresses
                    </SuiTypography>
                  </Grid>
                </Grid>
              </SuiBox>
              <SuiBox display="flex">
                <Grid container alignItems="center">
                  <Grid item xs={2}>
                    <Checkbox
                      name="employment"
                      checked={options.employment}
                      onClick={handleChange}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <SuiTypography
                      variant="button"
                      fontWeight="regular"
                      customClass="cursor-pointer user-select-none"
                    >
                      Employment
                    </SuiTypography>
                  </Grid>
                </Grid>
              </SuiBox>
              <SuiBox display="flex">
                <Grid container alignItems="center">
                  <Grid item xs={2}>
                    <Checkbox
                      name="calculator"
                      checked={options.calculator}
                      onClick={handleChange}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <SuiTypography
                      variant="button"
                      fontWeight="regular"
                      customClass="cursor-pointer user-select-none"
                    >
                      Qualification Questions
                    </SuiTypography>
                  </Grid>
                </Grid>
              </SuiBox>
              <SuiBox display="flex">
                <Grid container alignItems="center">
                  <Grid item xs={2}>
                    <Checkbox
                      name="declarations"
                      checked={options.declarations}
                      onClick={handleChange}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <SuiTypography
                      variant="button"
                      fontWeight="regular"
                      customClass="cursor-pointer user-select-none"
                    >
                      1003 Declarations
                    </SuiTypography>
                  </Grid>
                </Grid>
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

LeadTemplate.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default LeadTemplate;
