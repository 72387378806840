import { Link } from "react-router-dom";

// Proptypes
import PropTypes from "prop-types";

// @mui material components
import { Card, CardContent, Stack, Switch, Grid } from "@mui/material";

// Sui components
import SuiTypography from "components/SuiTypography";

// Components

function AutomationsCard(props) {
  const { title, description, icon, state, onChange, showSwitch, path } = props;

  return (
    <Card
      style={{
        backgroundImage: state
          ? "linear-gradient(to top left, #2152ff, #21d4fd)"
          : "linear-gradient(to top left, #ffff, #ffff)",
        padding: 20,
      }}
    >
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {showSwitch ? (
              <Stack direction="row" spacing={3} alignItems="center" justifyContent="end">
                <SuiTypography variant="button" textColor={state ? "light" : "dark"}>
                  {state ? "On" : "Off"}
                </SuiTypography>
                <Switch checked={state} onChange={onChange} />{" "}
              </Stack>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Link to={path}>
              <Stack spacing={3} justifyContent="center" alignItems="center">
                {icon}
                <SuiTypography variant="h3" fontWeight="bold" textColor={state ? "light" : "dark"}>
                  {title}
                </SuiTypography>
                <SuiTypography
                  variant="body2"
                  fontWeight="medium"
                  textColor={state ? "light" : "dark"}
                >
                  {description}
                </SuiTypography>
              </Stack>
            </Link>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

AutomationsCard.defaultProps = {
  state: true,
  onChange: () => {},
};

AutomationsCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  showSwitch: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  state: PropTypes.bool,
  onChange: PropTypes.func,
};

export default AutomationsCard;
