import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function MuiLoader(props) {
  const { loading } = props;

  // Old backdrop style backgroundColor: "#e9ecef", opacity: "0.65"

  return (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }} open={loading}>
      <CircularProgress color="info" />
    </Backdrop>
  );
}

MuiLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default MuiLoader;
