import React from "react";
import { Route, Redirect } from "react-router-dom";

// Proptypes
import PropTypes from "prop-types";

const AuthRoute = (props) => {
  const { isAuthenticated } = props;

  return isAuthenticated ? <Route {...props} /> : <Redirect to="/authentication/sign-in" />;
};

AuthRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default AuthRoute;
