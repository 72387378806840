import { apiGetWithReturn } from "redux/actions/dataActions";

// Utils
import notificationsAdd from "./notificationsAdd";

const handleNotificationsRefresh = (dispatch) => {
  try {
    return dispatch(apiGetWithReturn("/notifications/all")).then((res) => {
      notificationsAdd(dispatch, res.data.message);
    });
  } catch (error) {
    return error;
  }
};

export default handleNotificationsRefresh;
