// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
import SuiSelect from "components/SuiSelect";

function DeclarationsLeadForm(props) {
  const { values } = breakpoints;
  const { onSelectChange, inputs } = props;

  const options = {
    questionOwnership: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    questionPrimary: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    questionCitizen: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    questionNote: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    questionBorrowed: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    questionAlimony: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    questionDelinquent: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    questionObligation: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    questionLawsuit: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    questionForclosure: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    questionBankrupt: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    questionJudgements: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
  };

  return (
    <Accordion style={{ borderRadius: 10, boxShadow: "0px 0px 20px #ECEDEE" }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="basics-accordion"
        id="basics-accordion-header"
        style={{ marginLeft: 15 }}
      >
        <SuiTypography
          textTransform="capitalize"
          fontWeight="bold"
          variant={window.innerWidth < values.sm ? "h6" : "h5"}
        >
          1003 Declarations
        </SuiTypography>
      </AccordionSummary>
      <SuiBox p={3}>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Are there any outstanding judgments against you?
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.questionJudgements === ""
                    ? ""
                    : options.questionJudgements.filter(
                        (option) => option.value === inputs.questionJudgements
                      )
                }
                placeholder=""
                name="questionJudgements"
                options={options.questionJudgements}
                onChange={(e) => onSelectChange(e, "declarations", "questionJudgements")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Have you been declared bankruptcy in the past 7 years?
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.questionBankrupt === ""
                    ? ""
                    : options.questionBankrupt.filter(
                        (option) => option.value === inputs.questionBankrupt
                      )
                }
                placeholder=""
                name="questionBankrupt"
                options={options.questionBankrupt}
                onChange={(e) => onSelectChange(e, "declarations", "questionBankrupt")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Have you had property foreclosed upon or given title or deed in lieu thereof in
                  the last 7 years?
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.questionForclosure === ""
                    ? ""
                    : options.questionForclosure.filter(
                        (option) => option.value === inputs.questionForclosure
                      )
                }
                placeholder=""
                name="questionForclosure"
                options={options.questionForclosure}
                onChange={(e) => onSelectChange(e, "declarations", "questionForclosure")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Are you a party to a lawsuit?
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.questionLawsuit === ""
                    ? ""
                    : options.questionLawsuit.filter(
                        (option) => option.value === inputs.questionLawsuit
                      )
                }
                placeholder=""
                name="questionLawsuit"
                options={options.questionLawsuit}
                onChange={(e) => onSelectChange(e, "declarations", "questionLawsuit")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Have you been obligated on any loan resulted in foreclosure, transfer of title in
                  lieu of foreclosure, or judgment?
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.questionObligation === ""
                    ? ""
                    : options.questionObligation.filter(
                        (option) => option.value === inputs.questionObligation
                      )
                }
                placeholder=""
                name="questionObligation"
                options={options.questionObligation}
                onChange={(e) => onSelectChange(e, "declarations", "questionObligation")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Are you presently delinquent or in default on any federal debt or any other loan,
                  mortgage, financial obligation, bond or loan guarantee?
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.questionDelinquent === ""
                    ? ""
                    : options.questionDelinquent.filter(
                        (option) => option.value === inputs.questionDelinquent
                      )
                }
                placeholder=""
                name="questionDelinquent"
                options={options.questionDelinquent}
                onChange={(e) => onSelectChange(e, "declarations", "questionDelinquent")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Are you obligated to pay alimony, child support, or separate maintenance?
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.questionAlimony === ""
                    ? ""
                    : options.questionAlimony.filter(
                        (option) => option.value === inputs.questionAlimony
                      )
                }
                placeholder=""
                name="questionAlimony"
                options={options.questionAlimony}
                onChange={(e) => onSelectChange(e, "declarations", "questionAlimony")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Is any part of the down payment borrowed?
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.questionBorrowed === ""
                    ? ""
                    : options.questionBorrowed.filter(
                        (option) => option.value === inputs.questionBorrowed
                      )
                }
                placeholder=""
                name="questionBorrowed"
                options={options.questionBorrowed}
                onChange={(e) => onSelectChange(e, "declarations", "questionBorrowed")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Are you a co-maker or endorser on a note?
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.questionNote === ""
                    ? ""
                    : options.questionNote.filter((option) => option.value === inputs.questionNote)
                }
                placeholder=""
                name="questionNote"
                options={options.questionNote}
                onChange={(e) => onSelectChange(e, "declarations", "questionNote")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Are you a U.S. citizen?
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.questionCitizen === ""
                    ? ""
                    : options.questionCitizen.filter(
                        (option) => option.value === inputs.questionCitizen
                      )
                }
                placeholder=""
                name="questionCitizen"
                options={options.questionCitizen}
                onChange={(e) => onSelectChange(e, "declarations", "questionCitizen")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Do you intend to occupy the property as your primary residence?
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.questionPrimary === ""
                    ? ""
                    : options.questionPrimary.filter(
                        (option) => option.value === inputs.questionPrimary
                      )
                }
                placeholder=""
                name="questionPrimary"
                options={options.questionPrimary}
                onChange={(e) => onSelectChange(e, "declarations", "questionPrimary")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Have you had an ownership interest in a property in the last three years?
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.questionOwnership === ""
                    ? ""
                    : options.questionOwnership.filter(
                        (option) => option.value === inputs.questionOwnership
                      )
                }
                placeholder=""
                name="questionOwnership"
                options={options.questionOwnership}
                onChange={(e) => onSelectChange(e, "declarations", "questionOwnership")}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </SuiBox>
    </Accordion>
  );
}

DeclarationsLeadForm.propTypes = {
  onSelectChange: PropTypes.func.isRequired,
  inputs: PropTypes.shape({
    questionOwnership: PropTypes.string,
    questionPrimary: PropTypes.string,
    questionCitizen: PropTypes.string,
    questionNote: PropTypes.string,
    questionBorrowed: PropTypes.string,
    questionAlimony: PropTypes.string,
    questionDelinquent: PropTypes.string,
    questionObligation: PropTypes.string,
    questionLawsuit: PropTypes.string,
    questionForclosure: PropTypes.string,
    questionBankrupt: PropTypes.string,
    questionJudgements: PropTypes.string,
  }).isRequired,
};

export default DeclarationsLeadForm;
