import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiPostWithReturn } from "redux/actions/dataActions";
import { UI_ERROR } from "redux/types";
import { useHistory } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material UI
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack } from "@mui/material";

// SUI Components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import LoanaUpgradePricingCard from "components/LoanaUpgradePricingCard";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import LoanaSubscriptionImg from "assets/images/loana-subscription.png";
import MuiLoader from "components/MuiLoader";
import SuiAlert from "components/SuiAlert";

function LoanaSubscriptionUpgrade(props) {
  const { open, onClose, title, type } = props;
  const { values } = breakpoints;

  // Opens UI Error
  const [openError, setOpenError] = useState(false);

  // Dispatch and History declarations
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => state.user.profile);
  const company = useSelector((state) => state.user.company);
  const inputError = useSelector((state) => state.ui.error);

  const [price, setPrice] = useState("250");
  const [seats, setSeats] = useState(company.team.length);

  const [loading, setLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [preview, setPreview] = useState({ amountDue: 0, date: 0 });

  const handleSeatAdd = () => {
    setSeats(seats + 1);
  };

  const handleSeatMinus = () => {
    if (seats > company.team.length) {
      setSeats(seats - 1);
    }
  };

  const handleUpgradePro = () => {
    setLoading(true);
    dispatch(
      apiPostWithReturn("/stripe/upgrade", {
        stripeSubscription: company.stripeSubscription,
        quantity: seats,
        companyId: company.companyId,
      })
    ).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        history.push("/dashboard/checkout/upgrade");
      }
      dispatch({
        type: UI_ERROR,
        payload:
          "Something went wrong. Please contact our support team at support@loana.freshdesk.com",
      });
    });
  };

  useEffect(() => {
    if (seats && company && open) {
      setPreviewLoading(true);
      dispatch(
        apiPostWithReturn("/stripe/preview", {
          stripeCustomer: company.stripeCustomer,
          stripeSubscription: company.stripeSubscription,
          companyId: company.companyId,
          quantity: seats,
        })
      ).then((res) => {
        setPreview({
          amountDue: res.data.message.total,
          date: res.data.message.lines.data[0].period.end,
        });
        setPreviewLoading(false);
      });
    }
  }, [seats, company, open]);

  useEffect(() => {
    if (seats > 5) {
      const seatPrice = (seats - 5) * 40;
      const totalPrice = seatPrice + 250;
      setPrice(totalPrice.toString());
    } else {
      setPrice("250");
    }
  }, [seats]);

  // Opens error alert
  useEffect(() => {
    if (inputError !== "") setOpenError(true);
  }, [inputError]);

  return (
    <>
      <MuiLoader loading={loading} />
      <Dialog open={open} fullScreen style={{ padding: 10, backgroundColor: "#F8F9FA" }}>
        <DialogTitle>
          <SuiBox pt={3} mb={3} textAlign="center">
            {user.admin ? (
              <SuiTypography
                variant={window.innerWidth > values.md ? "h2" : "h4"}
                textColor="error"
                fontWeight="bold"
              >
                {title}
              </SuiTypography>
            ) : (
              <SuiTypography
                variant={window.innerWidth > values.md ? "h2" : "h4"}
                textColor="error"
                fontWeight="bold"
              >
                Please contact your company administrator to upgrade your Pro plan
              </SuiTypography>
            )}
          </SuiBox>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Grid container spacing={3} justifyContent="center">
              {window.innerWidth < values.md ? (
                <Grid item xs={12} alignContent="center">
                  <img
                    src={LoanaSubscriptionImg}
                    alt="upgrade to loana pro"
                    width="100%"
                    height="auto"
                    style={{ borderRadius: 10 }}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid
              container
              spacing={window.innerWidth > values.md ? 3 : 0}
              justifyContent="center"
              alignItems="center"
            >
              {openError ? (
                <Grid item xs={12}>
                  <SuiAlert color="warning">
                    <SuiTypography variant="body2" textColor="white">
                      <SuiTypography
                        component="a"
                        href="#"
                        variant="body2"
                        fontWeight="medium"
                        textColor="white"
                      >
                        Oops!&nbsp;
                      </SuiTypography>
                      {inputError}
                    </SuiTypography>
                  </SuiAlert>
                </Grid>
              ) : null}
              {window.innerWidth > values.md ? (
                <Grid item md={4} alignContent="center">
                  <img
                    src={LoanaSubscriptionImg}
                    alt="upgrade to loana pro"
                    width="100%"
                    height="auto"
                    style={{ borderRadius: 10 }}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} md={4}>
                <Stack spacing={2}>
                  <LoanaUpgradePricingCard
                    badge={{
                      color: "secondary",
                      label: "Team",
                    }}
                    price={{ currency: "$", value: price }}
                    seats={seats}
                    onAdd={handleSeatAdd}
                    onMinus={handleSeatMinus}
                    action={{
                      color: "error",
                      label: "Purchase Upgrade",
                    }}
                    loading={previewLoading}
                    onSubmit={handleUpgradePro}
                    invoice={preview}
                    type={type}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions>
          <SuiButton variant="outlined" buttonColor="error" onClick={onClose}>
            No thanks
          </SuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

LoanaSubscriptionUpgrade.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default LoanaSubscriptionUpgrade;
