import {
  API_REQUEST,
  API_SUCCESS,
  API_ERROR,
  UI_ERROR,
  UI_ERROR_CLEAR,
  UI_LOADING,
  UI_LOADING_CLEAR,
  API_LOADING_CLEAR,
  SET_UI_ADMIN,
} from "../types";

const initialState = {
  loading: false,
  isLoading: 0,
  error: "",
  uiAdmin: false,
};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case API_REQUEST:
      return {
        ...state,
        isLoading: state.isLoading + 1,
      };
    case API_SUCCESS:
      return {
        ...state,
        isLoading: state.isLoading - 1,
      };
    case API_ERROR:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        error: action.payload,
      };
    case API_LOADING_CLEAR:
      return {
        ...state,
        isLoading: 0,
      };
    case UI_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case UI_ERROR_CLEAR:
      return {
        ...state,
        error: "",
      };
    case UI_LOADING:
      return {
        ...state,
        loading: true,
      };
    case UI_LOADING_CLEAR:
      return {
        ...state,
        loading: false,
      };
    case SET_UI_ADMIN:
      return {
        ...state,
        uiAdmin: action.payload,
      };
    default:
      return state;
  }
}
