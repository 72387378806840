import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// @mui material components
import { Card, CardContent, Grid } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function HelloLoanaAutomation() {
  const bypass = true;
  const { values } = breakpoints;

  // User, Data, UI Redux Objects
  const loading = useSelector((state) => state.ui.loading);
  const user = useSelector((state) => state.user);

  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (user.profile.helloLoanaPhone) {
      const newPhone = `${user.profile.helloLoanaPhone.slice(
        0,
        3
      )}-${user.profile.helloLoanaPhone.slice(3, 6)}-${user.profile.helloLoanaPhone.slice(6)}`;
      setPhone(newPhone);
    }
  }, [user]);

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiTypography variant={window.innerWidth > values.md ? "h3" : "h4"} fontWeight="bold">
              Hello Loana
            </SuiTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card style={{ padding: 10 }}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <SuiTypography variant="h6" fontWeight="medium" textColor="info">
                      What is Hello Loana?
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <SuiTypography variant="body2">
                      Hello Loana is an automated SMS messaging system. Please note that this is not
                      a support number. If the Hello Loana automation is activated, you will receive
                      a daily text with an update on how many Loana tasks and follow-ups you have
                      for the day. This message will be sent Monday through Friday at 8 AM MST.
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <SuiTypography variant="h6" fontWeight="medium" textColor="info">
                      How do I use it?
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <SuiTypography variant="body2">
                      First, save your Hello Loana phone number as a contact. This ensures you will
                      always be able to find this number. Loana will automatically send you your
                      daily message. If you&apos;d like to see your daily tasks, reply with
                      &quot;Tasks&quot;. Loana will then send you a second text with your list of
                      daily tasks. If you wish to stop receiving a daily Hello Loana text, reply
                      with Stop. However, please note, if you reply stop and then wish to reactivate
                      Hello Loana, you will need to reply Start to the same number to resubscribe to
                      our messages.
                    </SuiTypography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card style={{ padding: 10 }}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <SuiTypography variant="h6" fontWeight="medium">
                      Your Hello Loana Phone #
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <SuiTypography variant="h2" fontWeight="bold" textColor="success">
                      {phone}
                    </SuiTypography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
}

export default HelloLoanaAutomation;
