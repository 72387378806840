import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Day js
import dayjs from "dayjs";

// @mui material components
import { Card, Divider, Switch, Tooltip, Grid, IconButton, Chip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Custom Components
import LoanaSearchbar from "components/LoanaSearchbar";

function OpportunityForm(props) {
  const {
    onChange,
    user,
    team,
    onLeadsAdd,
    onLeadsRemove,
    leads,
    inputs,
    onHoldChange,
    onCrnChange,
    onNrChange,
    onDnqChange,
    onDncChange,
    checkedHold,
    checkedCrn,
    checkedDnc,
    checkedNr,
    checkedDnq,
    onRemoveHold,
    holdDate,
  } = props;

  const { values } = breakpoints;

  const history = useHistory();

  const [ownerSelect, setOwnerSelect] = useState([{ value: "new", label: "new" }]);
  const [showRefiOptions, setShowRefiOptions] = useState(false);

  const options = {
    type: [
      { value: "buying", label: "Buying" },
      { value: "refinancing", label: "Refinancing" },
    ],
    status: [
      { value: "new", label: "New" },
      { value: "pq", label: "Pre-Qualified" },
      { value: "ai", label: "Application In" },
      { value: "docs", label: "Waiting on Documents" },
      { value: "pa", label: "Pre-Approved" },
      { value: "uc", label: "Under Contract" },
      { value: "les", label: "Loan Estimate Sent" },
      { value: "closed", label: "Closed" },
      { value: "lost", label: "Lost" },
    ],
    oppOwner: ownerSelect,
    referralSource: [
      { value: "ad", label: "Advertisement" },
      { value: "biz", label: "Business Referral" },
      { value: "cc", label: "Current Client Referral" },
      { value: "bldr", label: "Home Builder Referral" },
      { value: "pc", label: "Past Client" },
      { value: "pcr", label: "Past Client Referral" },
      { value: "pfr", label: "Personal Friend/Relationship" },
      { value: "rltr", label: "Realtor" },
    ],
    refinanceType: [
      { value: "cashOut", label: "Cash-Out Refinance" },
      { value: "cashIn", label: "Cash-In Refinance" },
      { value: "rate", label: "Rate and Term Refinance" },
      { value: "streamline", label: "Streamline Refinance" },
      { value: "reverse", label: "Reverse Mortgage" },
    ],
    refinanceFocus: [
      { value: "conventional", label: "Conventional" },
      { value: "fha", label: "FHA" },
      { value: "usda", label: "USDA" },
      { value: "va", label: "VA" },
    ],
  };

  const handleEditRedirect = (event, id, type) => {
    event.preventDefault();
    history.push({
      pathname: `/pipeline/${type}/edit/${id}`,
      state: { redirectToOpps: true, returnURL: window.location.pathname },
    });
  };

  useEffect(() => {
    const tempArr = [];
    team.forEach((member) => {
      tempArr.push({ value: member.uid, label: `${member.firstName} ${member.lastName}` });
    });
    setOwnerSelect(tempArr);
  }, [user]);

  useEffect(() => {
    if (inputs.type === "refinancing") {
      setShowRefiOptions(true);
    } else if (inputs.type === "buying") {
      setShowRefiOptions(false);
    }
  }, [inputs]);

  return (
    <Card>
      <SuiBox pl={3} pt={3} pb={1} pr={3} lineHeight={1} mb={1}>
        <SuiTypography variant="h5" fontWeight="medium" textColor="info">
          Leads
        </SuiTypography>
        <Divider />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} pb={6}>
            <LoanaSearchbar onLeadsAdd={onLeadsAdd} />
          </Grid>
          <Grid item xs={12} align="center">
            <SuiBox pl={3} pt={1} pr={3}>
              <Grid container align="start">
                {window.innerWidth > values.md ? (
                  <>
                    <Grid item xs={3}>
                      <SuiTypography variant="body2" fontWeight="medium">
                        Name
                      </SuiTypography>
                    </Grid>
                    <Grid item xs={3}>
                      <SuiTypography variant="body2" fontWeight="medium">
                        Email
                      </SuiTypography>
                    </Grid>
                    <Grid item xs={2}>
                      <SuiTypography variant="body2" fontWeight="medium">
                        Phone
                      </SuiTypography>
                    </Grid>
                    <Grid item xs={3}>
                      <SuiTypography variant="body2" fontWeight="medium">
                        Partner
                      </SuiTypography>
                    </Grid>
                    <Grid item xs={1}>
                      <SuiTypography variant="body2" fontWeight="medium">
                        Actions
                      </SuiTypography>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </SuiBox>
            {leads && leads.length !== 0 ? (
              leads.map((lead, index) =>
                window.innerWidth > values.md ? (
                  <SuiBox pl={3} pt={1} pb={1} pr={3} key={lead.leadId}>
                    <Grid container align="start">
                      {index === 0 ? (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      ) : null}
                      <Grid
                        item
                        xs={3}
                        onClick={(e) => handleEditRedirect(e, lead.leadId, "leads")}
                        style={{ cursor: "pointer" }}
                      >
                        <SuiTypography variant="body2">{lead.name}</SuiTypography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        onClick={(e) => handleEditRedirect(e, lead.leadId, "leads")}
                        style={{ cursor: "pointer" }}
                      >
                        <SuiTypography variant="body2">{lead.email}</SuiTypography>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        onClick={(e) => handleEditRedirect(e, lead.leadId, "leads")}
                        style={{ cursor: "pointer" }}
                      >
                        <SuiTypography variant="body2">{lead.phone}</SuiTypography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        onClick={(e) => handleEditRedirect(e, lead.partnerId, "partners")}
                        style={{ cursor: "pointer" }}
                      >
                        <SuiTypography variant="body2">
                          {lead.partnerId && lead.partnerId !== ""
                            ? lead.partnerName
                            : "No Partner Added"}
                        </SuiTypography>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip title="Remove Lead">
                          <IconButton
                            aria-label="remove lead"
                            color="error"
                            onClick={(e) => onLeadsRemove(e, lead)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </SuiBox>
                ) : (
                  <SuiBox
                    pl={3}
                    pt={1}
                    pb={1}
                    pr={3}
                    key={lead.leadId}
                    onClick={(e) => handleEditRedirect(e, lead.leadId)}
                    style={{ cursor: "pointer" }}
                  >
                    <Grid container alignItems="center">
                      {index === 0 ? (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      ) : null}
                      <Grid item xs={11} align="start">
                        <SuiTypography variant="body2">{lead.name}</SuiTypography>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip title="Remove Lead">
                          <IconButton
                            aria-label="remove lead"
                            color="error"
                            onClick={(e) => onLeadsRemove(e, lead)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </SuiBox>
                )
              )
            ) : (
              <SuiBox p={5}>
                <SuiTypography variant="body2">
                  No leads have been added to this opportunity
                </SuiTypography>
              </SuiBox>
            )}
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox pl={3} pt={1} pb={1} pr={3} lineHeight={1} mb={1}>
        <SuiTypography variant="h5" fontWeight="medium" textColor="info">
          Basics
        </SuiTypography>
        <Divider />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Opportunity Type
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.type === ""
                    ? ""
                    : options.type.filter((option) => option.value === inputs.type)
                }
                placeholder=""
                options={options.type}
                onChange={(e) => onChange(e, "type")}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Opportunity Status
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.status === ""
                    ? ""
                    : options.status.filter((option) => option.value === inputs.status)
                }
                placeholder=""
                options={options.status}
                onChange={(e) => onChange(e, "status")}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Opportunity Owner
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.oppOwner === ""
                    ? ""
                    : options.oppOwner.filter((option) => option.value === inputs.oppOwner)
                }
                placeholder={`${user.firstName} ${user.lastName}`}
                options={options.oppOwner}
                onChange={(e) => onChange(e, "oppOwner")}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Referall Source
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.referralSource === ""
                    ? ""
                    : options.referralSource.filter(
                        (option) => option.value === inputs.referralSource
                      )
                }
                placeholder=""
                options={options.referralSource}
                onChange={(e) => onChange(e, "referralSource")}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
      {showRefiOptions ? (
        <SuiBox pl={3} pt={1} pb={1} pr={3} lineHeight={1} mb={1}>
          <SuiTypography variant="h5" fontWeight="medium" textColor="info">
            Refinancing
          </SuiTypography>
          <Divider />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Refinance Type
                  </SuiTypography>
                </SuiBox>
                <SuiSelect
                  value={
                    inputs.refinanceType === ""
                      ? ""
                      : options.refinanceType.filter(
                          (option) => option.value === inputs.refinanceType
                        )
                  }
                  placeholder=""
                  options={options.refinanceType}
                  onChange={(e) => onChange(e, "refinanceType")}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} sm={4}>
              <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Refinance Focus
                  </SuiTypography>
                </SuiBox>
                <SuiSelect
                  value={
                    inputs.refinanceFocus === ""
                      ? ""
                      : options.refinanceFocus.filter(
                          (option) => option.value === inputs.refinanceFocus
                        )
                  }
                  placeholder=""
                  options={options.refinanceFocus}
                  onChange={(e) => onChange(e, "refinanceFocus")}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
      ) : null}
      <SuiBox pl={3} pt={1} pb={1} pr={3} lineHeight={1} mb={1}>
        <SuiTypography variant="h5" fontWeight="medium" textColor="info">
          Follow-ups <Chip label="PRO" color="primary" size="small" variant="outlined" />
        </SuiTypography>
        <Divider />
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={3}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                Hold for Future Opportunity
              </SuiTypography>
            </SuiBox>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <SuiBox ml={0.5} mb={0.25}>
                  <Switch
                    checked={checkedHold}
                    onChange={onHoldChange}
                    disabled={!user.proActive || false}
                  />
                </SuiBox>
              </Grid>
              {holdDate !== "" && checkedHold ? (
                <>
                  <Grid item align="end">
                    <SuiBox ml={0.5} mb={0.25}>
                      <SuiTypography variant="body2" textColor="text">
                        {dayjs(holdDate).format("MM/DD/YYYY")}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item align="start">
                    <SuiBox ml={0.5} mb={0.25}>
                      <Tooltip title="Remove Hold Date">
                        <IconButton
                          aria-label="remove hold date"
                          color="error"
                          onClick={onRemoveHold}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </SuiBox>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                Credit Repair Needed
              </SuiTypography>
            </SuiBox>
            <SuiBox ml={0.5} mb={0.25}>
              <Switch
                checked={checkedCrn}
                onChange={onCrnChange}
                disabled={!user.proActive || false}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                No Response
              </SuiTypography>
            </SuiBox>
            <SuiBox ml={0.5} mb={0.25}>
              <Switch
                checked={checkedNr}
                onChange={onNrChange}
                disabled={!user.proActive || false}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox pl={3} pt={1} pb={1} pr={3} lineHeight={1} mb={1}>
        <SuiTypography variant="h5" fontWeight="medium" textColor="info">
          Tags <Chip label="PRO" color="primary" size="small" variant="outlined" />
        </SuiTypography>
        <Divider />
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={3}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                Do Not Contact
              </SuiTypography>
            </SuiBox>
            <SuiBox ml={0.5} mb={0.25}>
              <Switch
                checked={checkedDnc}
                onChange={onDncChange}
                disabled={!user.proActive || false}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                Does Not Qualify
              </SuiTypography>
            </SuiBox>
            <SuiBox ml={0.5} mb={0.25}>
              <Switch
                checked={checkedDnq}
                onChange={onDnqChange}
                disabled={!user.proActive || false}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

OpportunityForm.defaultProps = {
  leads: [],
  inputs: {},
};

OpportunityForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onLeadsAdd: PropTypes.func.isRequired,
  onLeadsRemove: PropTypes.func.isRequired,
  onHoldChange: PropTypes.func.isRequired,
  onCrnChange: PropTypes.func.isRequired,
  onNrChange: PropTypes.func.isRequired,
  onDnqChange: PropTypes.func.isRequired,
  onDncChange: PropTypes.func.isRequired,
  checkedHold: PropTypes.bool.isRequired,
  checkedCrn: PropTypes.bool.isRequired,
  checkedNr: PropTypes.bool.isRequired,
  checkedDnq: PropTypes.bool.isRequired,
  checkedDnc: PropTypes.bool.isRequired,
  onRemoveHold: PropTypes.func.isRequired,
  holdDate: PropTypes.string.isRequired,
  leads: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      leadId: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      createdAt: PropTypes.string,
      partnerId: PropTypes.string,
      partnerName: PropTypes.string,
    })
  ),
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    uid: PropTypes.string,
    proActive: PropTypes.bool,
  }).isRequired,
  team: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      uid: PropTypes.string,
    })
  ).isRequired,
  inputs: PropTypes.shape({
    type: PropTypes.string,
    status: PropTypes.string,
    oppOwner: PropTypes.string,
    referralSource: PropTypes.string,
    refinanceType: PropTypes.string,
    refinanceFocus: PropTypes.string,
  }),
};

export default OpportunityForm;
