// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Material UI
import { Grid, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

function Trial() {
  const labels = [
    "Create Unlimited Leads",
    "Manage Unlimited Opportunities",
    "Company and Personal Performance Analytics",
    "Automated Emails & Templates",
    "Automated Customer Birthday Emails",
    "Customer Refinance Journey",
    "Lead Follow Up Reminders",
    "Daily Tasks",
    "Access to Hello Loana SMS",
  ];
  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SuiBox mb={3}>
          <SuiTypography variant="h3" fontWeight="bold" textColor="info">
            Enjoy 14 Days of Loana Pro on us!
          </SuiTypography>
        </SuiBox>
        <SuiBox mb={3}>
          <SuiTypography variant="h5" fontWeight="bold" textColor="text">
            What benefits and features do I get with Loana Pro?
          </SuiTypography>
        </SuiBox>
        <SuiBox mt={2}>
          <Grid container spacing={1} justifyContent="center">
            {labels.map((label) => (
              <Grid item xs={12} key={label}>
                <Grid container spacing={1}>
                  <Grid item xs={1} sm={4} textAlign="right">
                    <CheckCircle fontSize="medium" color="info" />
                  </Grid>
                  <Grid item xs={11} sm={8} textAlign="left">
                    <Typography>{label}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </SuiBox>
      </SuiBox>

      <SuiBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SuiBox mb={1}>
          <SuiTypography variant="body2" fontWeight="regular" textColor="text">
            Loana is free to use so we don&apos;t need a credit card from you at this time.
          </SuiTypography>
        </SuiBox>
        <SuiBox mb={1}>
          <SuiTypography variant="body2" fontWeight="regular" textColor="text">
            Your company can purchase a PRO plan at anytime from your Loana Dashboard.
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default Trial;
