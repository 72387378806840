import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UI_ERROR } from "redux/types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved14 from "assets/images/curved-images/curved14.jpg";
import { resetPassword } from "redux/actions/userActions";
import SuiAlert from "components/SuiAlert";

function ResetPassword() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [openError, setOpenError] = useState(false);
  const inputError = useSelector((state) => state.ui.error);

  const handleEmailChange = (event) => {
    event.persist();
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    if (email !== "") {
      dispatch(resetPassword(email, history));
    } else {
      dispatch({ type: UI_ERROR, payload: "Please enter a valid email" });
    }
  };

  useEffect(() => {
    if (inputError !== "") {
      setOpenError(true);
    } else {
      setOpenError(false);
    }
  }, [inputError]);

  return (
    <CoverLayout
      title="Reset Password"
      description="You will receive an e-mail shortly!"
      image={curved14}
      top={30}
    >
      <SuiBox component="form" role="form">
        {openError ? (
          <SuiAlert color="warning">
            <SuiTypography variant="body2" textColor="white">
              <SuiTypography
                component="a"
                href="#"
                variant="body2"
                fontWeight="medium"
                textColor="white"
              >
                Oops!&nbsp;
              </SuiTypography>
              {inputError}
            </SuiTypography>
          </SuiAlert>
        ) : null}
        <SuiBox mb={2} lineHeight={1.25}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SuiTypography>
          </SuiBox>
          <SuiInput onChange={handleEmailChange} type="email" placeholder="Enter your e-mail" />
        </SuiBox>
        <SuiBox mt={4} mb={1}>
          <SuiButton onClick={handleSubmit} variant="gradient" buttonColor="error" fullWidth>
            reset
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </CoverLayout>
  );
}

export default ResetPassword;
