import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { apiPostWithReturn } from "redux/actions/dataActions";
import { UI_ERROR, UI_ERROR_CLEAR, SET_OPPS } from "redux/types";

// Firebase
import { collection, query, onSnapshot, getFirestore } from "firebase/firestore";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// Material UI
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
} from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

// Utils
import refinanceBoard from "utils/schemas/refinanceBoard";
import organizeOpps from "utils/helpers/organizeOpps";
import oppSchema from "utils/schemas/opp";

// Kanban application components
import RefinanceHeader from "./components/RefinanceHeader";
import RefinanceList from "./components/RefinanceList";

function Refinance() {
  const bypass = true;

  const dispatch = useDispatch();
  const history = useHistory();
  const db = getFirestore();

  // Redux objects
  const user = useSelector((state) => state.user);
  const opps = useSelector((state) => state.data.opps);
  const loading = useSelector((state) => state.ui.loading);

  // Pipeline state
  const [pipeline, setPipeline] = useState({
    opener: [],
    nudge: [],
    reminderOne: [],
    reminderTwo: [],
    reminderThree: [],
    finalChance: [],
  });

  /* ----------------------- Dialog ------------------------- */
  // Dialog states
  const [openDialog, setOpenDialog] = useState(false);
  const [currentOpp, setCurrentOpp] = useState();

  const handleCloseDialog = () => setOpenDialog(false);

  const handleOpenDialog = (event, opp) => {
    event.preventDefault();
    setCurrentOpp(opp);
    setOpenDialog(true);
  };

  const handleCreateOpp = (event) => {
    event.preventDefault();
    const submitData = {
      opp: {
        ...oppSchema,
        leads: [...currentOpp.leads],
        type: "refinancing",
        notes: [
          {
            createdAt: new Date().toISOString(),
            message: "Returning customer. Opportunity created from Loana's Refinance Journey.",
            noteId: uuidv4(),
            noteOwner: user.profile.uid,
          },
        ],
        oppOwner: user.profile.uid,
      },
      companyId: user.company.companyId,
      oldOppId: currentOpp.oppId,
    };
    dispatch(apiPostWithReturn("/opp/refinance/add", submitData))
      .then((res) => {
        if (res.status === 200) {
          history.push(`/pipeline/opportunities/edit/${res.data.message}`);
        }
      })
      .catch((error) => {
        dispatch({
          type: UI_ERROR,
          payload: `Something went wrong. Please contact support@loana.freshdesk.com for help or try again. Error: ${error}`,
        });
      });
  };

  /* ----------------------- Board ------------------------- */

  /* ----------------------- Filters ------------------------- */
  // Stores show all or show my opps state
  const [showAll, setShowAll] = useState(false);

  // Creates the virtualized lists
  const handleCreateBoard = () => {
    const tempPipeline = {
      opener: [],
      nudge: [],
      reminderOne: [],
      reminderTwo: [],
      reminderThree: [],
      finalChance: [],
    };

    Object.keys(refinanceBoard).forEach((status) => {
      opps.closed.forEach((opp) => {
        if (showAll && opp.refinanceJourneyActive && opp.refinanceJourneyStatus === status) {
          tempPipeline[status].push(opp);
        }
        if (
          !showAll &&
          opp.refinanceJourneyActive &&
          opp.refinanceJourneyStatus === status &&
          opp.oppOwner === user.profile.uid
        ) {
          tempPipeline[status].push(opp);
        }
      });
    });

    setPipeline(tempPipeline);
  };

  // Sets the boards filter
  // const handleFilterOpps = () =>

  // Handles filter when user clicks to show all or my refinance
  const handleShowChange = () => setShowAll(!showAll);

  // Invokes the filter handler when a variable is updated
  useEffect(() => handleCreateBoard(), [showAll]);

  // Refreshes boards when Redux data is loaded
  // Creates Firestore channel to listen for realtime document updates
  useEffect(() => {
    const q = query(collection(db, `/companies/${user.company.companyId}/opps`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
      const organized = organizeOpps(data);
      dispatch({ type: SET_OPPS, payload: organized });
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (user.company.team.length !== 0 && Object.keys(opps).length !== 0) {
      handleCreateBoard();
    }
  }, [opps, user]);

  /* ----------------------------------------------------------- */

  useEffect(() => {
    dispatch({ type: UI_ERROR_CLEAR });
  }, []);

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox pt={3}>
        <SuiBox display="flex" justifyContent="flex-end" m={2}>
          <RefinanceHeader onShowChange={handleShowChange} showVariable={showAll} />
        </SuiBox>
      </SuiBox>
      <SuiBox style={{ display: "flex", overflowX: "scroll" }}>
        <SuiBox mr={2}>
          <RefinanceList
            opps={pipeline.opener}
            labels={refinanceBoard.opener}
            onOpen={handleOpenDialog}
          />
        </SuiBox>
        <SuiBox mr={2}>
          <RefinanceList
            opps={pipeline.nudge}
            labels={refinanceBoard.nudge}
            onOpen={handleOpenDialog}
          />
        </SuiBox>
        <SuiBox mr={2}>
          <RefinanceList
            opps={pipeline.reminderOne}
            labels={refinanceBoard.reminderOne}
            onOpen={handleOpenDialog}
          />
        </SuiBox>
        <SuiBox mr={2}>
          <RefinanceList
            opps={pipeline.reminderTwo}
            labels={refinanceBoard.reminderTwo}
            onOpen={handleOpenDialog}
          />
        </SuiBox>
        <SuiBox mr={2}>
          <RefinanceList
            opps={pipeline.reminderThree}
            labels={refinanceBoard.reminderThree}
            onOpen={handleOpenDialog}
          />
        </SuiBox>
        <SuiBox mr={2}>
          <RefinanceList
            opps={pipeline.finalChance}
            labels={refinanceBoard.finalChance}
            onOpen={handleOpenDialog}
          />
        </SuiBox>
      </SuiBox>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="create-opp-dialog"
        aria-describedby="dialog to create a new opportunity"
      >
        <DialogTitle>Would you like to create an opportunity?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Woohoo! Congrats, it seems a previous customer would like to refinance with you. Do you
            want Loana to create a new opportunity?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SuiButton
            variant="outlined"
            buttonColor="error"
            size="small"
            onClick={handleCloseDialog}
          >
            Cancel
          </SuiButton>
          <SuiButton variant="gradient" buttonColor="error" size="small" onClick={handleCreateOpp}>
            Create Opportunity
          </SuiButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Refinance;
