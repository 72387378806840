// Day JS
import dayjs from "dayjs";

// Redux
import { SET_PARTNERS } from "../../redux/types";

const partnersRefresh = (dispatch, data) => {
  // Format partner createdAt dates to dayjs format
  const formattedPartners = [];
  data.forEach((partner) => {
    formattedPartners.push({
      ...partner,
      basic: {
        ...partner.basic,
        createdAt: dayjs(partner.basic.createdAt).format("MM/DD/YYYY"),
      },
    });
  });
  dispatch({ type: SET_PARTNERS, payload: formattedPartners });
};

export default partnersRefresh;
