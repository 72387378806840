import { useSelector } from "react-redux";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// dayjs
import dayjs from "dayjs";

// @mui material components
import { Stack, Card, Icon, CircularProgress, Grid, Divider } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";
import SuiBadge from "components/SuiBadge";
import SuiButton from "components/SuiButton";

// Soft UI Theme and Styles
import breakpoints from "assets/theme/base/breakpoints";

function LoanaUpgradePricingCard(props) {
  const { badge, price, action, seats, onAdd, onMinus, loading, onSubmit, invoice, type } = props;
  const { values } = breakpoints;

  const user = useSelector((state) => state.user);

  return (
    <Card>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        px={window.innerWidth > values.md ? 10 : 3}
        pt={3}
      >
        <Grid item xs={12} align="center" py={3}>
          <SuiBadge
            variant="contained"
            color={badge.color}
            badgeContent={badge.label}
            circular
            container
          />
        </Grid>
        <Grid item xs={12} align="center">
          <SuiTypography variant="h1">
            <SuiTypography display="inline" component="small" variant="h2">
              {price.currency}
            </SuiTypography>
            {price.value}
            <SuiTypography display="inline" component="small" variant="h5">
              {" "}
              per month
            </SuiTypography>
          </SuiTypography>
        </Grid>
        <Grid item xs={12} align="center">
          <SuiTypography variant="caption" fontWeight="light">
            Base $250 for up to 5 members, $40 per additional member
          </SuiTypography>
        </Grid>
        <Grid item xs={12} py={4}>
          <Stack direction="row" spacing={3} alignItems="center" justifyContent="center">
            <SuiButton variant="gradient" buttonColor="info" onClick={onMinus}>
              -
            </SuiButton>
            <SuiTypography variant="h6" fontWeight="medium">
              {seats} members
            </SuiTypography>
            <SuiButton variant="gradient" buttonColor="info" onClick={onAdd}>
              +
            </SuiButton>
          </Stack>
        </Grid>
        {loading ? (
          <Grid item xs={12} py={4} align="center">
            <CircularProgress color="info" />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {(user.company.proSeats === seats || seats <= 5) && type === "team" ? (
              <Grid item xs={12} align="center" px={3}>
                <SuiTypography variant="caption" fontWeight="bold" textColor="dark">
                  This is your current plan
                </SuiTypography>
              </Grid>
            ) : (
              <>
                <Grid item xs={6} align="start" pt={2} pb={1}>
                  <Stack spacing={1}>
                    <SuiTypography variant="caption">
                      What you&apos;ll pay monthly starting
                    </SuiTypography>
                    <SuiTypography variant="caption">
                      {dayjs.unix(invoice.date).format("MMMM DD YYYY")}
                    </SuiTypography>
                  </Stack>
                </Grid>
                <Grid item xs={6} align="end" pt={2} pb={1}>
                  <SuiTypography variant="caption">${price.value}</SuiTypography>
                </Grid>
                <Grid item xs={6} align="start" pt={2} pb={1}>
                  <SuiTypography variant="caption" fontWeight="bold" textColor="dark">
                    Amount due today
                  </SuiTypography>
                </Grid>
                <Grid item xs={6} align="end" pt={2} pb={1}>
                  <SuiTypography variant="caption" fontWeight="bold" textColor="dark">
                    ${invoice.amountDue / 100}
                  </SuiTypography>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}

        <Grid item xs={12} py={4}>
          <SuiButton
            variant="gradient"
            buttonColor={action.color}
            disabled={
              loading ||
              ((user.company.proSeats === seats || seats <= 5) && type === "team") ||
              false
            }
            fullWidth
            onClick={onSubmit}
          >
            {action.label}&nbsp;
            <Icon className=" font-bold">arrow_forward</Icon>
          </SuiButton>
        </Grid>
      </Grid>
    </Card>
  );
}

// Typechecking props for the DefaultPricingCard
LoanaUpgradePricingCard.propTypes = {
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  action: PropTypes.shape({
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired,
  seats: PropTypes.number.isRequired,
  onAdd: PropTypes.func.isRequired,
  onMinus: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  invoice: PropTypes.shape({
    date: PropTypes.number,
    amountDue: PropTypes.number,
  }).isRequired,
  type: PropTypes.string.isRequired,
};

export default LoanaUpgradePricingCard;
