// React
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// React Redux
import { apiPostWithReturn } from "redux/actions/dataActions";
import { logoutUser } from "redux/actions/userActions";

import { useDispatch, useSelector } from "react-redux";
import { UI_ERROR } from "redux/types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";

// Soft UI Dashboard PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Wizard page components
import About from "layouts/profile/components/About";
import MuiLoader from "components/MuiLoader";
import Company from "./components/Company";
import Trial from "./components/Trial";
import Verification from "./components/Verification";
import LeadTemplate from "./components/LeadTemplate";

function getSteps() {
  return ["About", "Company", "Company Lead Template", "Trial", "Verify"];
}

function getStepContent(
  stepIndex,
  handleImageChange,
  handleUserInputsChange,
  userInputs,
  profileImageSrc,
  companyInputs,
  handleCompanyInputsChange,
  handleTemplateInputsChange
) {
  switch (stepIndex) {
    case 0:
      return (
        <About
          onImageChange={handleImageChange}
          onChange={handleUserInputsChange}
          inputs={userInputs}
          profileImageSrc={profileImageSrc}
        />
      );
    case 1:
      return <Company onChange={handleCompanyInputsChange} inputs={companyInputs} />;
    case 2:
      return <LeadTemplate onChange={handleTemplateInputsChange} />;
    case 3:
      return <Trial />;
    case 4:
      return <Verification />;
    default:
      return null;
  }
}

function ProfileWizard() {
  const loading = useSelector((state) => state.ui.loading);
  const inputError = useSelector((state) => state.ui.error);
  const [openError, setOpenError] = useState(false);
  const [userInputs, setUserInputs] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    role: "mortgage",
    nmls: "Eg. 123456",
    realtorLicense: "Eg. 123456",
    companyTitle: "",
  });
  const [profileImageSrc, setProfileImageSrc] = useState(
    "https://firebasestorage.googleapis.com/v0/b/loana-f75fa.appspot.com/o/no-image.png?alt=media"
  );

  const [companyInputs, setCompanyInputs] = useState({
    companyName: "",
    companyNmls: "Eg. 123456",
  });

  const [templateInputs, setTemplateInputs] = useState({
    basics: true,
    addresses: false,
    employment: false,
    calculator: false,
    declarations: false,
  });

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const dispatch = useDispatch();
  const history = useHistory();

  const handleNext = () => setActiveStep(activeStep + 1);

  const handleUserInputsChange = (event) => {
    event.persist();
    setUserInputs((values) => ({ ...values, [event.target.name]: event.target.value }));
  };

  const handleCompanyInputsChange = (event) => {
    event.persist();
    setCompanyInputs((values) => ({ ...values, [event.target.name]: event.target.value }));
  };

  const handleTemplateInputsChange = (name) => {
    if (templateInputs[name]) {
      setTemplateInputs((values) => ({ ...values, [name]: false }));
    } else {
      setTemplateInputs((values) => ({ ...values, [name]: true }));
    }
  };

  const handleImageChange = (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("file", image);
    dispatch(apiPostWithReturn("/user/profile/image", formData)).then((res) => {
      if (res.status === 200) {
        setProfileImageSrc(res.data.message);
      }
    });
  };

  const validateSubmit = (obj) => {
    let valid = true;
    Object.values(obj).forEach((value) => {
      if (typeof value === "string") {
        if (value === "") {
          valid = false;
        }
      } else if (typeof value === "number") {
        if (value <= 0) {
          valid = false;
        }
      }
    });
    return valid;
  };

  const submitHelper = (path, inputs) => {
    dispatch(apiPostWithReturn(path, inputs)).then((res) => {
      if (res.status === 200) {
        handleNext();
      }
    });
  };

  const handleSubmit = (event, step) => {
    event.preventDefault();
    if (step === 0) {
      if (validateSubmit(userInputs)) {
        submitHelper("/user/profile", userInputs);
      } else {
        dispatch({ type: UI_ERROR, payload: "Please make sure each field is completed below" });
      }
    } else if (step === 1) {
      if (validateSubmit(companyInputs)) {
        submitHelper("/company/add", companyInputs);
      } else {
        dispatch({ type: UI_ERROR, payload: "Please make sure each field is completed below" });
      }
    } else if (step === 2) {
      submitHelper("/company/template", templateInputs);
    } else if (step === 3) {
      handleNext();
    } else if (step === 4) {
      dispatch(logoutUser()).then(() => history.push("/authentication/sign-in"));
    }
  };

  useEffect(() => {
    if (inputError !== "") {
      setOpenError(true);
    } else {
      setOpenError(false);
    }
  }, [inputError]);

  return (
    <>
      <MuiLoader loading={loading} />
      <PageLayout>
        <SuiBox pt={3} pb={8}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={8}>
              <SuiBox mt={6} mb={1} textAlign="center">
                <SuiBox mb={1}>
                  <SuiTypography variant="h3" fontWeight="bold">
                    Build Your Loana Profile
                  </SuiTypography>
                </SuiBox>
              </SuiBox>

              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Card>
                <SuiBox p={4}>
                  <SuiBox>
                    {openError ? (
                      <SuiAlert color="warning">
                        <SuiTypography variant="body2" textColor="white">
                          <SuiTypography
                            component="a"
                            href="#"
                            variant="body2"
                            fontWeight="medium"
                            textColor="white"
                          >
                            Oops!&nbsp;
                          </SuiTypography>
                          {inputError}
                        </SuiTypography>
                      </SuiAlert>
                    ) : null}
                    {getStepContent(
                      activeStep,
                      handleImageChange,
                      handleUserInputsChange,
                      userInputs,
                      profileImageSrc,
                      companyInputs,
                      handleCompanyInputsChange,
                      handleTemplateInputsChange
                    )}
                    <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
                      <SuiBox />
                      <SuiButton
                        variant="gradient"
                        buttonColor="dark"
                        onClick={(e) => {
                          handleSubmit(e, activeStep);
                        }}
                      >
                        {isLastStep ? "finish" : "next"}
                      </SuiButton>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
              </Card>
            </Grid>
          </Grid>
        </SuiBox>
      </PageLayout>
    </>
  );
}

export default ProfileWizard;
