// Firebase
import { initializeApp, getApps, getApp } from "firebase/app";
import { doc, setDoc, getFirestore, updateDoc } from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendEmailVerification,
  signOut,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  deleteUser,
} from "firebase/auth";

// Axios
import axios from "axios";

// React Redux
import { UI_ERROR_CLEAR } from "redux/types";

// Utils
import userSchema from "utils/schemas/user";
import apiSuccess from "utils/helpers/apiSuccess";
import apiError from "utils/helpers/apiError";
import apiStart from "utils/helpers/apiStart";
import setLocalToken from "utils/helpers/setLocalToken";
import removeLocalToken from "utils/helpers/removeLocalToken";
import profileStart from "utils/helpers/profileStart";
import profileSuccess from "utils/helpers/profileSuccess";
import authStart from "utils/helpers/authStart";
import authSuccess from "utils/helpers/authSuccess";
import authRemove from "utils/helpers/authRemove";
import profileRemove from "utils/helpers/profileRemove";
import companyStart from "utils/helpers/companyStart";
import companyRemove from "utils/helpers/companyRemove";
import companySuccess from "utils/helpers/companySuccess";
import tasks from "utils/schemas/tasks";
import handleOppsRefresh from "utils/helpers/handleOppsRefresh";
import handleLeadsRefresh from "utils/helpers/handleLeadsRefresh";
import dataRemove from "utils/helpers/dataRemove";
import handlePartnersRefresh from "utils/helpers/handlePartnersRefresh";
import handleNotificationsRefresh from "utils/helpers/handleNotificationsRefresh";

const firebaseConfig = {
  apiKey: "AIzaSyAWMJgCJacOexWY2aYjpJjy5JBmW-GL52o",
  authDomain: "loana-f75fa.firebaseapp.com",
  projectId: "loana-f75fa",
  storageBucket: "loana-f75fa.appspot.com",
  messagingSenderId: "342388019128",
  appId: "1:342388019128:web:2a59921353009df7c1894d",
  measurementId: "G-82KDS1MXB3",
};

if (getApps().length === 0) {
  initializeApp(firebaseConfig);
} else {
  getApp();
}

const auth = getAuth();
const db = getFirestore();
const googleProvider = new GoogleAuthProvider();

/* --------------------------------- User Auth Actions -------------------------------*/
// Gets authenticated user
export const getUserAuth = (data) => (dispatch) => {
  authStart(dispatch, data);
  authSuccess(dispatch);
};

/* --------------------------------- User Profile Actions -------------------------------*/

// Gets user profile
export const getUserProfile = () => async (dispatch) => {
  try {
    apiStart(dispatch);

    const profile = await axios.get("/user/profile").then((res) => {
      profileStart(dispatch, res.data.message);
      profileSuccess(dispatch);
      return res.data.message;
    });

    const company = await axios.get(`/company/${profile.companyId}`).then((res) => {
      companyStart(dispatch, res.data.message);
      companySuccess(dispatch);
    });

    const opps = await handleOppsRefresh(dispatch, profile.companyId);
    const leads = await handleLeadsRefresh(dispatch, profile.companyId);
    const partners = await handlePartnersRefresh(dispatch, profile.companyId);
    const notifications = await handleNotificationsRefresh(dispatch);

    return Promise.all([profile, company, opps, leads, partners, notifications]).then((results) => {
      apiSuccess(dispatch);
      return results;
    });
  } catch (error) {
    apiError(dispatch, error.message);
    return error;
  }
};

// Sends password reset email from account settings page
export const changePassword = (email) => async (dispatch) => {
  apiStart(dispatch);
  return sendPasswordResetEmail(auth, email)
    .then(() => {
      apiSuccess(dispatch);
    })
    .catch((error) => {
      apiError(dispatch, error.message);
    });
};

/* --------------------------------- User Authentication Actions -------------------------------*/
// Creates user with email and password
export const signUpWithEmailAndPassword = (inputs, history) => async (dispatch) => {
  apiStart(dispatch);
  return createUserWithEmailAndPassword(auth, inputs.email, inputs.password)
    .then((result) => {
      setLocalToken(result.user.accessToken);
      return result.user;
    })
    .then((user) => {
      setDoc(doc(db, "users", user.uid), {
        ...userSchema,
        email: user.email,
        uid: user.uid,
      });
      return user.uid;
    })
    .then((uid) => {
      tasks.data.forEach((task) => {
        setDoc(doc(db, `/users/${uid}/tasks`, task.taskId), { ...task, taskOwner: uid });
      });
    })
    .then(() => {
      sendEmailVerification(auth.currentUser);
    })
    .then(() => {
      apiSuccess(dispatch);
      history.push("/profile");
    })
    .catch((error) => {
      let message = "";
      if (error.code === "auth/invalid-email") {
        message = "Please enter a valid email address";
      } else if (error.code === "auth/email-already-in-use") {
        message = "This email is already in use";
      } else if (error.code === "auth/weak-password") {
        message = "Password must at least be 6 characters long";
      } else {
        message = "Something went wrong. Please contact support@loana.freshdesk.com";
      }
      apiError(dispatch, message);
    });
};

// Creates user with email and password for invited user
export const signUpWithEmailAndPasswordForInvite =
  (inputs, invite, history) => async (dispatch) => {
    apiStart(dispatch);
    createUserWithEmailAndPassword(auth, inputs.email, inputs.password)
      .then((result) => {
        setLocalToken(result.user.accessToken);
        setDoc(doc(db, "users", result.user.uid), {
          ...userSchema,
          companyId: invite.companyId,
          proActive: invite.proActive,
          trialActive: invite.trialActive,
          admin: invite.role === "admin" || false,
          email: result.user.email,
          uid: result.user.uid,
        })
          .then(() => {
            tasks.data.forEach((task) => {
              setDoc(doc(db, `/users/${result.user.uid}/tasks`, task.taskId), {
                ...task,
                taskOwner: result.user.uid,
              });
            });
          })
          .then(() => {
            sendEmailVerification(auth.currentUser);
            updateDoc(doc(db, `invites/${invite.inviteId}`), { done: true });
          })
          .then(() => {
            apiSuccess(dispatch);
            history.push(`/profile/invite/${invite.companyId}/${invite.inviteId}`);
          });
      })
      .catch((error) => {
        let message = "";
        if (error.code === "auth/invalid-email") {
          message = "Please enter a valid email address";
        } else if (error.code === "auth/email-already-in-use") {
          message = "This email is already in use";
        } else if (error.code === "auth/weak-password") {
          message = "Password must at least be 6 characters long";
        } else {
          message = "Something went wrong. Please contact support@loana.freshdesk.com";
        }
        apiError(dispatch, message);
      });
  };

// Creates user with Google OAuth
export const signUpWithGoogle = (history) => async (dispatch) => {
  apiStart(dispatch);
  signInWithPopup(auth, googleProvider)
    .then((result) => {
      setLocalToken(result.user.accessToken);
      return result.user;
    })
    .then((user) => {
      setDoc(doc(db, "users", user.uid), {
        ...userSchema,
        email: user.email,
        uid: user.uid,
      });
      tasks.data.forEach((task) => {
        setDoc(doc(db, `/users/${user.uid}/tasks`, task.taskId), { ...task, taskOwner: user.uid });
      });
    })
    .then(() => {
      apiSuccess(dispatch);
      history.push("/profile");
    })
    .catch((error) => {
      apiError(dispatch, error.message);
    });
};

// Creates user with Google OAuth and checks email matches invite email
export const signUpWithGoogleForInvite = (history, invite) => async (dispatch) => {
  apiStart(dispatch);
  signInWithPopup(auth, googleProvider)
    .then((result) => {
      if (result.user.email === invite.email) {
        setLocalToken(result.user.accessToken);
        setDoc(doc(db, "users", result.user.uid), {
          ...userSchema,
          companyId: invite.companyId,
          proActive: invite.proActive,
          trialActive: invite.trialActive,
          admin: invite.role === "admin" || false,
          email: result.user.email,
          uid: result.user.uid,
        })
          .then(() => {
            tasks.data.forEach((task) => {
              setDoc(doc(db, `/users/${result.user.uid}/tasks`, task.taskId), {
                ...task,
                taskOwner: result.user.uid,
              });
            });
          })
          .then(() => {
            updateDoc(doc(db, `invites/${invite.inviteId}`), { done: true });
            apiSuccess(dispatch);
            history.push(`/profile/invite/${invite.companyId}/${invite.inviteId}`);
          });
      } else {
        const authUser = auth.currentUser;
        deleteUser(authUser).then(() => {
          removeLocalToken();
          apiError(
            dispatch,
            "The selected email does not match the email address that was originally invited"
          );
        });
      }
    })
    .catch((error) => {
      apiError(dispatch, error.message);
    });
};

// Logs user out
export const logoutUser = () => async (dispatch) => {
  apiStart(dispatch);
  return signOut(auth)
    .then(() => {
      removeLocalToken();
      authRemove(dispatch);
      profileRemove(dispatch);
      companyRemove(dispatch);
      dataRemove(dispatch);
      apiSuccess(dispatch);
      dispatch({ type: UI_ERROR_CLEAR });
    })
    .catch((error) => {
      apiError(dispatch, error.message);
    });
};

// Logs user in with email and password
export const loginUserWithEmailAndPassword = (inputs) => async (dispatch) => {
  apiStart(dispatch);
  return signInWithEmailAndPassword(auth, inputs.email, inputs.password)
    .then((result) => {
      setLocalToken(result.user.accessToken);
      apiSuccess(dispatch);
      return result;
    })
    .catch((error) => {
      let message = "";
      if (error.code === "auth/invalid-email") {
        message = "Please enter a valid email address";
      } else if (error.code === "auth/user-not-found") {
        message = "We have no records of that email address";
      } else {
        message = "Something went wrong. Please contact support@loana.freshdesk.com";
      }
      apiError(dispatch, message);
      return error;
    });
};

// Logs user in with Google
export const loginUserWithGoogle = () => async (dispatch) => {
  apiStart(dispatch);
  return signInWithPopup(auth, googleProvider)
    .then((result) => {
      setLocalToken(result.user.accessToken);
      apiSuccess(dispatch);
      return result;
    })
    .catch((error) => {
      let message = "";
      if (error.code === "auth/invalid-email") {
        message = "Please enter a valid email address";
      } else if (error.code === "auth/user-not-found") {
        message = "We have no records of that email address";
      } else {
        message = "Something went wrong. Please contact support@loana.freshdesk.com";
      }
      apiError(dispatch, message);
      return error;
    });
};

// Sends password reset email
export const resetPassword = (email, history) => (dispatch) => {
  apiStart(dispatch);
  sendPasswordResetEmail(auth, email)
    .then(() => {
      apiSuccess(dispatch);
      history.push("/authentication/sign-in");
    })
    .catch((error) => {
      let message = "";
      if (error.code === "auth/invalid-email") {
        message = "Please enter a valid email address";
      } else if (error.code === "auth/user-not-found") {
        message = "We have no records of that email address";
      } else {
        message = "Something went wrong. Please contact support@loana.freshdesk.com";
      }
      apiError(dispatch, message);
    });
};

/* ------------------------------------------------------------------------------------------*/
