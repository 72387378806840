import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material UI
import { Divider, Grid, IconButton, Stack } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import SuiDatePicker from "components/SuiDatePicker";
import SuiSelect from "components/SuiSelect";
import FormField from "layouts/profile/components/FormField";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function OpportunityHeader(props) {
  const {
    title,
    type,
    onDateStartChange,
    onDateEndChange,
    onReset,
    filters,
    onStatusChange,
    onOwnerChange,
    onSearch,
  } = props;

  const { values } = breakpoints;

  const team = useSelector((state) => state.user.company.team);
  const user = useSelector((state) => state.user.profile);

  const getOwnerOptions = () => {
    const owners = [
      { value: "all", label: "Show All" },
      { value: "my", label: "Show My Opportunities" },
    ];

    team.forEach((owner) => {
      if (owner.uid !== user.uid) {
        owners.push({
          value: owner.uid,
          label: `${owner.firstName} ${owner.lastName}`,
        });
      }
    });

    return owners;
  };

  const options = {
    status: [
      { value: "new", label: "New" },
      { value: "pq", label: "Pre-Qualified" },
      { value: "ai", label: "Application In" },
      { value: "docs", label: "Waiting on Documents" },
      { value: "pa", label: "Pre-Approved" },
      { value: "uc", label: "Under Contract" },
      { value: "les", label: "Loan Estimate Sent" },
      { value: "closed", label: "Closed" },
      { value: "lost", label: "Lost" },
    ],
    owner: getOwnerOptions(),
  };

  const [showFilters, setShowFilters] = useState(false);
  const handleShowFilterChange = () => setShowFilters(!showFilters);

  // Debounces the search input
  const debounceSearch = useMemo(() => debounce(onSearch, 300), []);

  // Clearn up debounced results on unmount
  useEffect(() => debounceSearch.cancel());

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid
          container
          spacing={window.innerWidth > values.md ? 3 : 4}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          {type === "main" ? (
            window.innerWidth < values.md ? (
              <>
                <Grid item xs={12} md={5} alignSelf="flex-start">
                  <Stack spacing={3} direction="row" justifyContent="space-between">
                    <SuiTypography variant="h3">Opportunities</SuiTypography>
                    <IconButton aria-label="filters" onClick={handleShowFilterChange} color="error">
                      {showFilters ? <FilterListOffIcon /> : <FilterListIcon />}
                    </IconButton>
                  </Stack>
                </Grid>
                {showFilters ? (
                  <>
                    <Grid item xs={12} md={4}>
                      <Stack spacing={2} direction="row" alignItems="flex-end">
                        <SuiBox display="flex" flexDirection="column" height="100%">
                          <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                              Filter By Date - Start
                            </SuiTypography>
                          </SuiBox>
                          <SuiDatePicker
                            value={filters.startDate || ""}
                            onChange={onDateStartChange}
                          />
                        </SuiBox>
                        <SuiBox display="flex" flexDirection="column" height="100%">
                          <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                              Filter By Date - End
                            </SuiTypography>
                          </SuiBox>
                          <SuiDatePicker value={filters.endDate || ""} onChange={onDateEndChange} />
                        </SuiBox>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3} justifyContent="flex-end">
                        <Grid item xs={12} md={4} align="flex-start">
                          <FormField
                            type="text"
                            name="search"
                            defaultValue={filters.search}
                            label="Search"
                            placeholder=""
                            onChange={debounceSearch}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <SuiBox
                            display="flex"
                            flexDirection="column"
                            justifyContent="flex-end"
                            height="100%"
                          >
                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SuiTypography component="label" variant="caption" fontWeight="bold">
                                Opportunity Status
                              </SuiTypography>
                            </SuiBox>
                            <SuiSelect
                              value={
                                filters.status === ""
                                  ? ""
                                  : options.status.filter(
                                      (option) => option.value === filters.status
                                    )
                              }
                              placeholder=""
                              options={options.status}
                              onChange={onStatusChange}
                            />
                          </SuiBox>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <SuiBox
                            display="flex"
                            flexDirection="column"
                            justifyContent="flex-end"
                            height="100%"
                          >
                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SuiTypography component="label" variant="caption" fontWeight="bold">
                                Opportunity Owner
                              </SuiTypography>
                            </SuiBox>
                            <SuiSelect
                              value={
                                filters.owner === ""
                                  ? ""
                                  : options.owner.filter((option) => option.value === filters.owner)
                              }
                              placeholder=""
                              options={options.owner}
                              onChange={onOwnerChange}
                            />
                          </SuiBox>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={1} mt={4}>
                        <IconButton aria-label="clear" onClick={onReset} color="error">
                          <Stack direction="row" alignItems="center">
                            <ClearIcon />
                            <SuiTypography variant="button" textColor="error">
                              Clear Filters
                            </SuiTypography>
                          </Stack>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </>
                ) : null}
              </>
            ) : (
              <>
                <Grid item xs={12} md={4} alignSelf="flex-start">
                  <SuiTypography variant="h3">Opportunities</SuiTypography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3} justifyContent="flex-end">
                    <Grid item xs={12} md={3}>
                      <SuiBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                          <SuiTypography component="label" variant="caption" fontWeight="bold">
                            Opportunity Status
                          </SuiTypography>
                        </SuiBox>
                        <SuiSelect
                          value={
                            filters.status === ""
                              ? ""
                              : options.status.filter((option) => option.value === filters.status)
                          }
                          placeholder=""
                          options={options.status}
                          onChange={onStatusChange}
                        />
                      </SuiBox>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <SuiBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                          <SuiTypography component="label" variant="caption" fontWeight="bold">
                            Opportunity Owner
                          </SuiTypography>
                        </SuiBox>
                        <SuiSelect
                          value={
                            filters.owner === ""
                              ? ""
                              : options.owner.filter((option) => option.value === filters.owner)
                          }
                          placeholder=""
                          options={options.owner}
                          onChange={onOwnerChange}
                        />
                      </SuiBox>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3} justifyContent="flex-end" alignItems="flex-end">
                    <Grid item xs={12} md={4}>
                      <Stack spacing={3} direction="row" justifyContent="flex-end">
                        <SuiBox display="flex" flexDirection="column" height="100%">
                          <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                              Filter By Date - Start
                            </SuiTypography>
                          </SuiBox>
                          <SuiDatePicker
                            value={filters.startDate || ""}
                            onChange={onDateStartChange}
                          />
                        </SuiBox>
                        <SuiBox display="flex" flexDirection="column" height="100%">
                          <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                              Filter By Date - End
                            </SuiTypography>
                          </SuiBox>
                          <SuiDatePicker value={filters.endDate || ""} onChange={onDateEndChange} />
                        </SuiBox>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormField
                        type="text"
                        name="search"
                        defaultValue={filters.search}
                        label="Search"
                        placeholder=""
                        onChange={debounceSearch}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} align="end">
                  <SuiButton variant="outlined" buttonColor="error" size="small" onClick={onReset}>
                    clear filters
                  </SuiButton>
                </Grid>
              </>
            )
          ) : (
            <Grid item>
              <SuiTypography variant="h4">{title}</SuiTypography>
            </Grid>
          )}
          {type === "pipeline" ? (
            <Grid item>
              <SuiBox display="flex" alignItems="center">
                <Link to="/pipeline/opportunities/add" className="decoration-none">
                  <SuiButton variant="gradient" buttonColor="error" size="small">
                    + new opportunity
                  </SuiButton>
                </Link>
              </SuiBox>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}

OpportunityHeader.defaultProps = {
  onDateStartChange: () => {},
  onDateEndChange: () => {},
  onReset: () => {},
  onStatusChange: () => {},
  onOwnerChange: () => {},
  onSearch: () => {},
  filters: {
    startDate: null,
    endDate: null,
    status: null,
    owner: "my",
    search: "",
  },
};

OpportunityHeader.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onDateStartChange: PropTypes.func,
  onDateEndChange: PropTypes.func,
  onReset: PropTypes.func,
  onStatusChange: PropTypes.func,
  onOwnerChange: PropTypes.func,
  filters: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    status: PropTypes.string,
    search: PropTypes.string,
    owner: PropTypes.string,
  }),
  onSearch: PropTypes.func,
};

export default OpportunityHeader;
