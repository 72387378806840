import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiPostWithReturn } from "redux/actions/dataActions";
import { changePassword } from "redux/actions/userActions";
import { UI_ERROR, UI_ERROR_CLEAR, SET_PROFILE } from "redux/types";

// Firebase Firestore
import { doc, getFirestore, onSnapshot } from "firebase/firestore";

// sweetalert2 components
import Swal from "sweetalert2";

// @mui material components
import { Card, CardContent, CardHeader, Grid } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
import SuiButton from "components/SuiButton";

// Custom components
import SettingsProfile from "./components/SettingsProfile";
import SettingsLayout from "./components/SettingsLayout";

function Settings() {
  const bypass = true;

  const db = getFirestore();

  // Dispatch declaration
  const dispatch = useDispatch();

  // Page tab states
  const [showProfile, setShowProfile] = useState(true);
  const [showChangePassword, setShowChangePassword] = useState(false);

  // Opens UI Error
  const [openError, setOpenError] = useState(false);

  // User, Data, UI Redux Objects
  const user = useSelector((state) => state.user);
  const loading = useSelector((state) => state.ui.loading);
  const inputError = useSelector((state) => state.ui.error);

  // Creates Firestore channel to listen for realtime document updates
  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "users", `${user.profile.uid}`), (document) => {
      dispatch({ type: SET_PROFILE, payload: document.data() });
    });

    return unsubscribe;
  }, []);

  /* --------------------- CHANGE PASSWORD COMPONENT --------------------- */
  const handleChangePasswordSubmit = (event) => {
    event.preventDefault();
    if (user.auth.providerData[0].providerId === "password") {
      dispatch(changePassword(user.profile.email)).then(() => {
        Swal.fire("Password reset email sent!", "", "success");
      });
    }
  };

  /* --------------------- PROFILE COMPONENT --------------------- */

  const [userInputs, setUserInputs] = useState({ ...user.profile });

  const [profileImageSrc, setProfileImageSrc] = useState(user.profile.photoURL);

  const handleUserInputsChange = (event) => {
    event.persist();
    setUserInputs((values) => ({ ...values, [event.target.name]: event.target.value }));
  };

  const handleImageChange = (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("file", image);
    dispatch(apiPostWithReturn("/user/profile/image", formData)).then((res) => {
      if (res.status === 200) {
        setProfileImageSrc(res.data.message);
        setUserInputs((values) => ({ ...values, photoURL: res.data.message }));
        dispatch(
          apiPostWithReturn("/company/team/edit", {
            companyId: user.company.companyId,
            member: {
              admin: user.profile.admin,
              companyTitle: user.profile.companyTitle,
              createdAt: user.profile.createdAt,
              firstName: user.profile.firstName,
              lastName: user.profile.lastName,
              photoURL: res.data.message,
              uid: user.profile.uid,
            },
            team: user.company.team,
          })
        );
        setOpenError(false);
      }
    });
  };

  const validateSubmit = (obj) => {
    let valid = true;
    Object.values(obj).forEach((value) => {
      if (typeof value === "string") {
        if (value === "") {
          valid = false;
        }
      } else if (typeof value === "number") {
        if (value <= 0) {
          valid = false;
        }
      }
    });
    return valid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateSubmit(userInputs)) {
      dispatch(apiPostWithReturn("/user/profile", userInputs)).then((res) => {
        if (res.status === 200) {
          dispatch(
            apiPostWithReturn("/company/team/edit", {
              companyId: user.company.companyId,
              member: {
                admin: user.profile.admin,
                companyTitle: userInputs.companyTitle,
                createdAt: user.profile.createdAt,
                firstName: userInputs.firstName,
                lastName: userInputs.lastName,
                photoURL: user.profile.photoURL,
                uid: user.profile.uid,
              },
              team: user.company.team,
            })
          );
          setOpenError(false);
        }
      });
    } else {
      dispatch({ type: UI_ERROR, payload: "Please make sure each field is completed below" });
    }
  };

  /* --------------------- TAB COMPONENT --------------------- */
  const handleContentChange = (value) => {
    switch (value) {
      case 0:
        setShowProfile(true);
        setShowChangePassword(false);
        break;
      case 1:
        setShowProfile(false);
        setShowChangePassword(true);
        break;
      default:
        break;
    }
  };

  /* --------------------- CORE --------------------- */
  useEffect(() => {
    if (inputError !== "") {
      setOpenError(true);
    }
  }, [inputError]);

  useEffect(() => {
    dispatch({ type: UI_ERROR_CLEAR });
  }, []);

  return (
    <SettingsLayout onTabChange={handleContentChange} loading={loading} bypass={bypass}>
      <SuiBox mt={5}>
        {showProfile ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {openError ? (
                <Grid item xs={12}>
                  <SuiAlert color="warning">
                    <SuiTypography variant="body2" textColor="white">
                      <SuiTypography
                        component="a"
                        href="#"
                        variant="body2"
                        fontWeight="medium"
                        textColor="white"
                      >
                        Oops!&nbsp;
                      </SuiTypography>
                      {inputError}
                    </SuiTypography>
                  </SuiAlert>
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={8}>
              <Card>
                <SuiBox pt={1} pl={3} pr={3} pb={3}>
                  <CardHeader title="Edit Profile" />
                  <CardContent>
                    <SettingsProfile
                      onImageChange={handleImageChange}
                      onChange={handleUserInputsChange}
                      inputs={userInputs}
                      profileImageSrc={profileImageSrc}
                    />
                  </CardContent>
                  <Grid container>
                    <Grid item xs={12} align="end" style={{ marginTop: 10 }}>
                      <SuiButton
                        variant="gradient"
                        buttonColor="error"
                        size="medium"
                        onClick={handleSubmit}
                      >
                        Save Changes
                      </SuiButton>
                    </Grid>
                  </Grid>
                </SuiBox>
              </Card>
            </Grid>
          </Grid>
        ) : null}
        {showChangePassword ? (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <Card>
                <SuiBox pt={1} pl={3} pr={3} pb={3}>
                  <CardHeader title="Change Password" />
                  <CardContent>
                    <SuiBox mt={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <SuiTypography variant="body2">
                            Please note: This action will only effect accounts created with an email
                            and password.
                          </SuiTypography>
                        </Grid>
                      </Grid>
                    </SuiBox>
                  </CardContent>
                  <Grid container>
                    <Grid item xs={12} align="end" style={{ marginTop: 10 }}>
                      <SuiButton
                        variant="gradient"
                        buttonColor="error"
                        size="medium"
                        onClick={handleChangePasswordSubmit}
                      >
                        Change Password
                      </SuiButton>
                    </Grid>
                  </Grid>
                </SuiBox>
              </Card>
            </Grid>
          </Grid>
        ) : null}
      </SuiBox>
    </SettingsLayout>
  );
}

export default Settings;
