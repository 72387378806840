import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UI_ERROR } from "redux/types";
import { apiPostWithReturn } from "redux/actions/dataActions";
import { useHistory } from "react-router-dom";

// dayjs
import dayjs from "dayjs";

// @mui material components
import Grid from "@mui/material/Grid";
import { Divider } from "material-ui";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Schemas and helpers
// import leadSchema from "utils/schemas/lead";
import oppSchema from "utils/schemas/opp";

// Custom Components
import BasicsLeadForm from "./components/BasicsLeadForm";
import AddressesLeadForm from "./components/AddressesLeadForm";
import LeadFormActions from "./components/LeadFormActions";
import EmploymentLeadForm from "./components/EmploymentLeadForm";
import CalculatorLeadForm from "./components/CalculatorLeadForm";
import DeclarationsLeadForm from "./components/DeclarationsLeadForm";
import NoteInput from "./components/NoteInput";

function AddLead() {
  const { values } = breakpoints;
  const bypass = true;
  // Is the Create Opp from Lead option available?
  const oppCheckAvailable = true;

  const dispatch = useDispatch();
  const history = useHistory();

  // User and Data Redux Objects
  const user = useSelector((state) => state.user);
  const loading = useSelector((state) => state.ui.loading);
  const inputError = useSelector((state) => state.ui.error);

  // Opens error alert if true
  const [openError, setOpenError] = useState(false);

  // User Input to create opp from lead
  const [oppCheck, setOppCheck] = useState(false);

  // Calculated Monthly Pre-Qualified Amount
  const [estimatedAmount, setEstimatedAmount] = useState(0);

  // User Inputs for new lead
  const [basicInputs, setBasicInputs] = useState({
    birthdate: "",
    createdAt: new Date().toISOString(),
    email: "",
    estimatedFico: "",
    name: "",
    phone: "",
    ssn: "",
    statusCitizen: "",
    statusMarital: "",
    partner: "",
  });
  const [addressInputs, setAddressInputs] = useState({
    currentAddress: "",
    currentAddressLength: "",
    currentCity: "",
    currentPayment: "",
    currentState: "",
    currentZip: "",
    estimatedHomeValue: "",
    interestRate: "",
    mortgageBalance: "",
    previousAddress: "",
    previousAddressLength: "",
    previousCity: "",
    previousState: "",
    previousZip: "",
  });
  const [employmentInputs, setEmploymentInputs] = useState({
    currentEmployer: "",
    currentEmployerEnd: "",
    currentEmployerStart: "",
    currentPosition: "",
    previousEmployer: "",
    previousEmployerEnd: "",
    previousEmployerStart: "",
    previousPosition: "",
  });
  const [calculatorInputs, setCalculatorInputs] = useState({
    debtAuto: 0,
    debtBankrupt: 0,
    debtChild: 0,
    debtCollections: 0,
    debtCreditMin: 0,
    debtCreditNum: 0,
    debtDeferred: 0,
    debtForeclosure: 0,
    debtJudgements: 0,
    debtOther: 0,
    debtStudent: 0,
    income: 0,
    incomeType: "",
    downPaymentAmount: 0,
    downPaymentLocation: "",
    dtiPercentage: 49,
  });
  const [declarationsInputs, setDeclarationsInputs] = useState({
    questionAlimony: "",
    questionBankrupt: "",
    questionBorrowed: "",
    questionCitizen: "",
    questionDelinquent: "",
    questionForclosure: "",
    questionJudgements: "",
    questionLawsuit: "",
    questionNote: "",
    questionObligation: "",
    questionOwnership: "",
    questionPrimary: "",
  });
  const [note, setNote] = useState({
    createdAt: new Date().toISOString(),
    message: "",
    noteId: crypto.randomUUID(),
    noteOwner: user.profile.uid,
  });

  // onChange handlers for user inputs
  const handleInputsChange = (event, type) => {
    event.persist();
    switch (type) {
      case "basics":
        setBasicInputs((inputs) => ({
          ...inputs,
          [event.target.name]: event.target.value,
        }));
        break;
      case "addresses":
        setAddressInputs((inputs) => ({
          ...inputs,
          [event.target.name]: event.target.value,
        }));
        break;
      case "employment":
        setEmploymentInputs((inputs) => ({
          ...inputs,
          [event.target.name]: event.target.value,
        }));
        break;
      case "calculator":
        setCalculatorInputs((inputs) => ({
          ...inputs,
          [event.target.name]: parseInt(event.target.value, 10),
        }));
        break;
      case "declarations":
        setDeclarationsInputs((inputs) => ({
          ...inputs,
          [event.target.name]: event.target.value,
        }));
        break;
      case "note":
        setNote((inputs) => ({
          ...inputs,
          message: event.target.value,
        }));
        break;
      default:
        break;
    }
  };

  const handleSelectChange = (event, type, name) => {
    switch (type) {
      case "basics":
        setBasicInputs((inputs) => ({
          ...inputs,
          [name]: event.value,
        }));
        break;
      case "calculator":
        setCalculatorInputs((inputs) => ({
          ...inputs,
          [name]: event.value,
        }));
        break;
      case "declarations":
        setDeclarationsInputs((inputs) => ({
          ...inputs,
          [name]: event.value,
        }));
        break;
      default:
        break;
    }
  };

  const handleDateChange = (event, type, name) => {
    switch (type) {
      case "basics":
        setBasicInputs((inputs) => ({
          ...inputs,
          [name]: event[0].toISOString(),
        }));
        break;
      case "employment":
        setEmploymentInputs((inputs) => ({
          ...inputs,
          [name]: event[0].toISOString(),
        }));
        break;
      default:
        break;
    }
  };

  const handleOppCheck = (value) => {
    setOppCheck(value);
  };

  // User input validator
  const validateInputs = () => {
    let valid = true;
    if (basicInputs.name === "") {
      valid = false;
      dispatch({
        type: UI_ERROR,
        payload: "Please enter at least a name for the new lead.",
      });
    }
    return valid;
  };

  // User submits a new lead and wants to create an opp from the lead
  const handleLeadAndOppAdd = async (submitData) => {
    try {
      const addLead = await dispatch(apiPostWithReturn("/lead/add", submitData)).then((res) => ({
        message: res.data.message,
        type: "lead",
        code: res.data.code,
      }));

      const addOpp = await dispatch(
        apiPostWithReturn("/opp/add", {
          opp: {
            ...oppSchema,
            oppOwner: user.profile.uid,
            notes: submitData.lead.notes,
            leads: [
              {
                leadId: addLead.message,
                name: submitData.lead.basic.name,
                phone: submitData.lead.basic.phone,
                email: submitData.lead.basic.email,
                createdAt: dayjs(submitData.lead.basic.createdAt).format("MM/DD/YYYY"),
              },
            ],
          },
          companyId: submitData.companyId,
        })
      ).then((res) => ({ message: res.data.message, type: "opp", code: res.data.code }));

      return Promise.all([addLead, addOpp]).then((results) => results);
    } catch (error) {
      dispatch({
        type: UI_ERROR,
        payload: `Something went wrong. Please contact support@loana.freshdesk.com for help or try again. Error: ${error}`,
      });
      return error;
    }
  };

  // Handles user submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    const submitData = {
      lead: {
        address: {
          ...addressInputs,
        },
        basic: {
          ...basicInputs,
        },
        calculator: {
          ...calculatorInputs,
          estimatedAmount,
        },
        declarations: {
          ...declarationsInputs,
        },
        employment: {
          ...employmentInputs,
        },
        leadId: "",
        leadOwner: user.profile.uid,
        notes: note.message !== "" ? [{ ...note }] : [],
        opps: [],
      },
      companyId: user.company.companyId,
    };
    const valid = validateInputs();
    if (valid) {
      if (oppCheck) {
        handleLeadAndOppAdd(submitData).then((res) => {
          const resultsArr = res.filter((obj) => obj.type === "opp");
          history.push({
            pathname: `/pipeline/opportunities/edit/${resultsArr[0].message}`,
            state: { redirectToFollowups: false },
          });
        });
      } else {
        dispatch(apiPostWithReturn("/lead/add", submitData))
          .then((response) => {
            if (response.status === 200) {
              history.push("/pipeline/leads");
            }
          })
          .catch((error) => {
            dispatch({
              type: UI_ERROR,
              payload: `Something went wrong. Please contact support@loana.freshdesk.com for help or try again. Error: ${error}`,
            });
          });
      }
    }
  };

  // Use effect to update estimatedAmount
  useEffect(() => {
    const monthlyIncome = calculatorInputs.income / 12;
    const dti = calculatorInputs.dtiPercentage / 100;
    const percentIncome = monthlyIncome * dti;
    const creditDebt = calculatorInputs.debtCreditNum * calculatorInputs.debtCreditMin;
    const studentDebt = calculatorInputs.debtStudent * 0.01;
    let collectionsDebt = 0;
    if (calculatorInputs.debtCollections > 2000) {
      collectionsDebt = calculatorInputs.debtCollections * 0.05;
    }
    let total = percentIncome - calculatorInputs.debtAuto;
    total -= creditDebt;
    total -= studentDebt;
    total -= collectionsDebt;
    total = Math.round(total);
    setEstimatedAmount(total);
  }, [calculatorInputs]);

  // Opens error alert
  useEffect(() => {
    if (inputError !== "") setOpenError(true);
  }, [inputError]);

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiBox lineHeight={1} pl={3}>
              <SuiTypography
                textTransform="capitalize"
                fontWeight="bold"
                variant={window.innerWidth < values.sm ? "h5" : "h4"}
              >
                Add a New Lead
              </SuiTypography>
            </SuiBox>
            <Divider />
          </Grid>
          {openError ? (
            <Grid item xs={12}>
              <SuiAlert color="warning">
                <SuiTypography variant="body2" textColor="white">
                  <SuiTypography
                    component="a"
                    href="#"
                    variant="body2"
                    fontWeight="medium"
                    textColor="white"
                  >
                    Oops!&nbsp;
                  </SuiTypography>
                  {inputError}
                </SuiTypography>
              </SuiAlert>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={9}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BasicsLeadForm
                  inputs={basicInputs}
                  onChange={handleInputsChange}
                  onSelectChange={handleSelectChange}
                  onDateChange={handleDateChange}
                />
              </Grid>
              {user.company.leadTemplate && user.company.leadTemplate.addresses ? (
                <Grid item xs={12}>
                  <AddressesLeadForm inputs={addressInputs} onChange={handleInputsChange} />
                </Grid>
              ) : null}
              {user.company.leadTemplate && user.company.leadTemplate.employment ? (
                <Grid item xs={12}>
                  <EmploymentLeadForm
                    inputs={employmentInputs}
                    onChange={handleInputsChange}
                    onDateChange={handleDateChange}
                  />
                </Grid>
              ) : null}
              {user.company.leadTemplate && user.company.leadTemplate.calculator ? (
                <Grid item xs={12}>
                  <CalculatorLeadForm
                    inputs={calculatorInputs}
                    onChange={handleInputsChange}
                    onSelectChange={handleSelectChange}
                  />
                </Grid>
              ) : null}
              {user.company.leadTemplate && user.company.leadTemplate.declarations ? (
                <Grid item xs={12}>
                  <DeclarationsLeadForm
                    inputs={declarationsInputs}
                    onSelectChange={handleSelectChange}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <LeadFormActions
                  onSubmit={handleSubmit}
                  onCheck={handleOppCheck}
                  oppAddAvailable={oppCheckAvailable}
                />
              </Grid>
              {user.company.leadTemplate && user.company.leadTemplate.calculator ? (
                <Grid item xs={12}>
                  <DefaultCounterCard
                    count={estimatedAmount || 0}
                    prefix="$"
                    title="Est. Qualified Monthly Amount"
                    description=""
                  />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <NoteInput message={note.message} onChange={handleInputsChange} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
}

export default AddLead;
