/* eslint-disable react/jsx-props-no-spreading */

import { useState, forwardRef, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// clsx is a utility for constructing className strings conditionally
import clsx from "clsx";

// @material-ui core components
import Icon from "@mui/material/Icon";
import { styled } from "@mui/material/styles";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import FormField from "layouts/profile/components/FormField";

// Custom styles for SuiInput
import styles from "components/SuiInput/styles";

const Listbox = styled("ul")(
  () => `
  width: 500px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #FFFFFF;
  overflow: auto;
  max-height: 250px;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 0px 20px #ECEDEE;
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    &:hover {
      background-color: #e9ecef;
    }
  }
`
);

const LoanaSearchbar = forwardRef(
  ({ size, withIcon, error, success, customClass, disabled, onLeadsAdd }, ref) => {
    const leads = useSelector((state) => state.data.leads);
    const [filteredLeads, setFilteredLeads] = useState(leads);

    const classes = styles({ size, error, success, withIcon, disabled });

    const [searchInput, setSearchInput] = useState("");

    const handleKeyDown = (event, option) => {
      event.preventDefault();
      if (event.key === "Enter") {
        onLeadsAdd(event, {
          createdAt: option.basic.createdAt,
          email: option.basic.email,
          leadId: option.leadId,
          name: option.basic.name,
          phone: option.basic.phone,
        });
      }
    };

    const handleAddLead = (event, option) => {
      setSearchInput("");
      setFilteredLeads(leads);
      onLeadsAdd(event, {
        createdAt: option.basic.createdAt,
        email: option.basic.email,
        leadId: option.leadId,
        name: option.basic.name,
        phone: option.basic.phone,
      });
    };

    const handleSearch = (event) => {
      const tempLeadsArr = filteredLeads;
      const filteredArr = tempLeadsArr.filter((lead) =>
        lead.basic.name.toUpperCase().includes(event.target.value.toUpperCase())
      );
      setFilteredLeads(filteredArr);
      setSearchInput(event.target.value);
    };

    // Debounces the search input
    const debounceSearch = useMemo(() => debounce(handleSearch, 300), []);

    // Clearn up debounced results on unmount
    useEffect(() => debounceSearch.cancel());

    return (
      <SuiBox pr={1}>
        <SuiBox ref={ref} customClass={clsx(classes.suiInputIcon, customClass)}>
          <SuiBox customClass={classes.suiInputIcon_right}>
            <Icon className={classes.suiInputIcon_icon} fontSize="small">
              search
            </Icon>
          </SuiBox>
          <FormField
            type="text"
            name="search"
            defaultValue={searchInput}
            label=""
            placeholder=""
            onChange={debounceSearch}
          />
        </SuiBox>
        {filteredLeads.length > 0 && searchInput !== "" ? (
          <Listbox>
            {filteredLeads.map((option) => (
              <li key={uuidv4()}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  textColor="secondary"
                  onClick={(e) => handleAddLead(e, option)}
                  onKeyDown={(e) => handleKeyDown(e, option)}
                >
                  {option.basic.name}
                </SuiTypography>
              </li>
            ))}
          </Listbox>
        ) : null}
      </SuiBox>
    );
  }
);

// Setting default values for the props of LoanaSearchbar
LoanaSearchbar.defaultProps = {
  size: "medium",
  withIcon: {
    icon: false,
  },
  error: false,
  success: false,
  customClass: "",
  disabled: false,
};

LoanaSearchbar.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  withIcon: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  }),
  error: PropTypes.bool,
  success: PropTypes.bool,
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
  onLeadsAdd: PropTypes.func.isRequired,
};

export default LoanaSearchbar;
