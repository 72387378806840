import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UI_ERROR } from "redux/types";
import { apiPostWithReturn } from "redux/actions/dataActions";
import { useHistory, useLocation, useParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import { Divider } from "material-ui";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Custom Components
import BasicsLeadForm from "../add-lead/components/BasicsLeadForm";
import AddressesLeadForm from "../add-lead/components/AddressesLeadForm";
import LeadFormActions from "../add-lead/components/LeadFormActions";
import EmploymentLeadForm from "../add-lead/components/EmploymentLeadForm";
import CalculatorLeadForm from "../add-lead/components/CalculatorLeadForm";
import DeclarationsLeadForm from "../add-lead/components/DeclarationsLeadForm";
import NoteInput from "../add-lead/components/NoteInput";

function EditLead() {
  const { values } = breakpoints;
  const bypass = true;
  const leadsAll = useSelector((state) => state.data.leads);
  const loading = useSelector((state) => state.ui.loading);
  const inputError = useSelector((state) => state.ui.error);
  const [openError, setOpenError] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { leadId } = useParams();

  // User and Data Redux Objects
  const user = useSelector((state) => state.user);

  // Is the Create Opp from Lead option available?
  const oppCheckAvailable = false;

  // Calculated Monthly Pre-Qualified Amount
  const [estimatedAmount, setEstimatedAmount] = useState(0);

  // Gets current lead from redux data
  const currentLead = leadsAll.filter((lead) => lead.leadId === leadId);

  // User Inputs for new lead
  const [basicInputs, setBasicInputs] = useState(currentLead[0].basic);
  const [addressInputs, setAddressInputs] = useState(currentLead[0].address);
  const [employmentInputs, setEmploymentInputs] = useState(currentLead[0].employment);
  const [calculatorInputs, setCalculatorInputs] = useState({
    ...currentLead[0].calculator,
    dtiPercentage: currentLead[0].calculator.dtiPercentage || 49,
  });
  const [declarationsInputs, setDeclarationsInputs] = useState(currentLead[0].declarations);
  const [note, setNote] = useState(
    currentLead[0].notes && currentLead[0].notes.length !== 0
      ? { ...currentLead[0].notes[0] }
      : {
          createdAt: new Date().toISOString(),
          message: "",
          noteId: crypto.randomUUID(),
          noteOwner: user.profile.uid,
        }
  );

  // onChange handlers for user inputs
  const handleInputsChange = (event, type) => {
    event.persist();
    switch (type) {
      case "basics":
        setBasicInputs((inputs) => ({
          ...inputs,
          [event.target.name]: event.target.value,
        }));
        break;
      case "addresses":
        setAddressInputs((inputs) => ({
          ...inputs,
          [event.target.name]: event.target.value,
        }));
        break;
      case "employment":
        setEmploymentInputs((inputs) => ({
          ...inputs,
          [event.target.name]: event.target.value,
        }));
        break;
      case "calculator":
        setCalculatorInputs((inputs) => ({
          ...inputs,
          [event.target.name]: parseInt(event.target.value, 10),
        }));
        break;
      case "declarations":
        setDeclarationsInputs((inputs) => ({
          ...inputs,
          [event.target.name]: event.target.value,
        }));
        break;
      case "note":
        setNote((inputs) => ({
          ...inputs,
          message: event.target.value,
        }));
        break;
      default:
        break;
    }
  };

  const handleSelectChange = (event, type, name) => {
    switch (type) {
      case "basics":
        setBasicInputs((inputs) => ({
          ...inputs,
          [name]: event.value,
        }));
        break;
      case "calculator":
        setCalculatorInputs((inputs) => ({
          ...inputs,
          [name]: event.value,
        }));
        break;
      case "declarations":
        setDeclarationsInputs((inputs) => ({
          ...inputs,
          [name]: event.value,
        }));
        break;
      default:
        break;
    }
  };

  const handleDateChange = (event, type, name) => {
    switch (type) {
      case "basics":
        setBasicInputs((inputs) => ({
          ...inputs,
          [name]: event[0].toISOString(),
        }));
        break;
      case "employment":
        setEmploymentInputs((inputs) => ({
          ...inputs,
          [name]: event[0].toISOString(),
        }));
        break;
      default:
        break;
    }
  };

  // User input validator
  const validateInputs = () => {
    let valid = true;
    if (basicInputs.name === "") {
      valid = false;
      dispatch({
        type: UI_ERROR,
        payload: "Please enter at least a name for the new lead.",
      });
    }
    return valid;
  };

  // Handles user submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    const submitData = {
      lead: {
        address: {
          ...addressInputs,
        },
        basic: {
          ...basicInputs,
        },
        calculator: {
          ...calculatorInputs,
          estimatedAmount,
        },
        declarations: {
          ...declarationsInputs,
        },
        employment: {
          ...employmentInputs,
        },
        notes: note.message !== "" ? [{ ...note }] : [],
        leadId: currentLead[0].leadId,
        leadOwner: currentLead[0].leadOwner,
        opps: currentLead[0].opps,
      },
      companyId: user.company.companyId,
    };
    const valid = validateInputs();
    if (valid) {
      dispatch(apiPostWithReturn("/lead/edit", submitData))
        .then((res) => {
          if (res.status === 200) {
            if (location.state.redirectToOpps) {
              history.push(location.state.returnURL);
            } else {
              history.push("/pipeline/leads");
            }
          }
        })
        .catch((error) => {
          dispatch({
            type: UI_ERROR,
            payload: `Something went wrong. Please contact support@loana.freshdesk.com for help or try again. Error: ${error}`,
          });
        });
    }
  };

  // Use effect to update estimatedAmount
  useEffect(() => {
    const monthlyIncome = calculatorInputs.income / 12;
    const dti = calculatorInputs.dtiPercentage / 100;
    const percentIncome = monthlyIncome * dti;
    const creditDebt = calculatorInputs.debtCreditNum * calculatorInputs.debtCreditMin;
    const studentDebt = calculatorInputs.debtStudent * 0.01;
    let collectionsDebt = 0;
    if (calculatorInputs.debtCollections > 2000) {
      collectionsDebt = calculatorInputs.debtCollections * 0.05;
    }
    let total = percentIncome - calculatorInputs.debtAuto;
    total -= creditDebt;
    total -= studentDebt;
    total -= collectionsDebt;
    total = Math.round(total);
    setEstimatedAmount(total);
  }, [calculatorInputs]);

  // Opens error alert
  useEffect(() => {
    if (inputError !== "") setOpenError(true);
  }, [inputError]);

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiBox lineHeight={1} pl={3}>
              <SuiTypography
                textTransform="capitalize"
                fontWeight="bold"
                variant={window.innerWidth < values.sm ? "h5" : "h4"}
              >
                Edit Lead
              </SuiTypography>
            </SuiBox>
            <Divider />
          </Grid>
          {currentLead && currentLead.length !== 0 && currentLead[0].dnc ? (
            <Grid item xs={12}>
              <SuiAlert color="info">
                <SuiTypography
                  component="a"
                  href="#"
                  variant="body2"
                  fontWeight="medium"
                  textColor="white"
                >
                  <b>Do not contact!</b> A member of your team tagged this lead as &quot;Do Not
                  Contact&quot;.
                </SuiTypography>
              </SuiAlert>
            </Grid>
          ) : null}
          {openError ? (
            <Grid item xs={12}>
              <SuiAlert color="warning">
                <SuiTypography variant="body2" textColor="white">
                  <SuiTypography
                    component="a"
                    href="#"
                    variant="body2"
                    fontWeight="medium"
                    textColor="white"
                  >
                    Oops!&nbsp;
                  </SuiTypography>
                  {inputError}
                </SuiTypography>
              </SuiAlert>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={9}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BasicsLeadForm
                  inputs={basicInputs}
                  onChange={handleInputsChange}
                  onSelectChange={handleSelectChange}
                  onDateChange={handleDateChange}
                />
              </Grid>
              {user.company.leadTemplate && user.company.leadTemplate.addresses ? (
                <Grid item xs={12}>
                  <AddressesLeadForm inputs={addressInputs} onChange={handleInputsChange} />
                </Grid>
              ) : null}
              {user.company.leadTemplate && user.company.leadTemplate.employment ? (
                <Grid item xs={12}>
                  <EmploymentLeadForm
                    inputs={employmentInputs}
                    onChange={handleInputsChange}
                    onDateChange={handleDateChange}
                  />
                </Grid>
              ) : null}
              {user.company.leadTemplate && user.company.leadTemplate.calculator ? (
                <Grid item xs={12}>
                  <CalculatorLeadForm
                    inputs={calculatorInputs}
                    onChange={handleInputsChange}
                    onSelectChange={handleSelectChange}
                  />
                </Grid>
              ) : null}
              {user.company.leadTemplate && user.company.leadTemplate.declarations ? (
                <Grid item xs={12}>
                  <DeclarationsLeadForm
                    inputs={declarationsInputs}
                    onSelectChange={handleSelectChange}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <LeadFormActions onSubmit={handleSubmit} oppAddAvailable={oppCheckAvailable} />
              </Grid>
              {user.company.leadTemplate && user.company.leadTemplate.calculator ? (
                <Grid item xs={12}>
                  <DefaultCounterCard
                    count={estimatedAmount || 0}
                    prefix="$"
                    title="Est. Qualified Monthly Amount"
                    description=""
                  />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <NoteInput message={note.message} onChange={handleInputsChange} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
}

export default EditLead;
