import { useDispatch } from "react-redux";
import { apiPostWithReturn } from "redux/actions/dataActions";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Soft UI Dasboard PRO Material components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// General page components
import LoanaFollowup from "../LoanaFollowup";

function LoanaFollowupList(props) {
  const { followups, triggerComplete, companyId } = props;
  const { values } = breakpoints;

  const dispatch = useDispatch();

  const handleFollowupComplete = (type, followupId, oppId) => {
    dispatch(apiPostWithReturn("/followup/edit", { type, oppId, followupId, companyId })).then(
      (response) => {
        if (response.status === 200) {
          triggerComplete();
        }
      }
    );
  };

  return (
    <Card>
      <SuiBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
        <SuiBox lineHeight={1}>
          <SuiTypography
            textTransform="capitalize"
            fontWeight="bold"
            variant={window.innerWidth < values.sm ? "h5" : "h4"}
          >
            Follow Ups
          </SuiTypography>
        </SuiBox>
      </SuiBox>
      <Divider />
      <SuiBox pb={2} px={2}>
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {followups && followups.length !== 0 ? (
            followups
              .sort((a, b) => a.createdAt - b.createdAt)
              .map((item) =>
                !item.done ? (
                  <LoanaFollowup
                    key={item.followupId}
                    followup={item}
                    onClick={handleFollowupComplete}
                  />
                ) : null
              )
          ) : (
            <SuiTypography
              variant="button"
              fontWeight="regular"
              textColor="text"
              align="center"
              py={2}
            >
              No current follow ups todo!
            </SuiTypography>
          )}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

LoanaFollowupList.propTypes = {
  followups: PropTypes.arrayOf(
    PropTypes.shape({
      followup: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      done: PropTypes.bool.isRequired,
    })
  ).isRequired,
  triggerComplete: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
};

export default LoanaFollowupList;
