export default {
  data: [
    {
      task: "Getting Started: Add a new lead - Go to Leads and click the +NEW LEAD button",
      createdAt: new Date().toISOString(),
      done: false,
      orderId: 1,
      taskId: crypto.randomUUID(),
    },
    {
      task: "Getting Started: Add your first opportunity - Go to Opportunities and click the +NEW OPPORTUNITY button",
      createdAt: new Date().toISOString(),
      done: false,
      orderId: 2,
      taskId: crypto.randomUUID(),
    },
    {
      task: "Getting Started: Invite a team member - Go to Administration and follow the instructions",
      createdAt: new Date().toISOString(),
      done: false,
      orderId: 3,
      taskId: crypto.randomUUID(),
    },
    {
      task: "Getting Started: Set up email automations - Go to Automations and click Email Automations",
      createdAt: new Date().toISOString(),
      done: false,
      orderId: 4,
      taskId: crypto.randomUUID(),
    },
    {
      task: "Getting Started: Set up birthday automations - Go to Automations and click Birthday Automations",
      createdAt: new Date().toISOString(),
      done: false,
      orderId: 6,
      taskId: crypto.randomUUID(),
    },
    {
      task: "Getting Started: Create your customer refinance journey - Go to Automations and click Refinance Journey",
      createdAt: new Date().toISOString(),
      done: false,
      orderId: 7,
      taskId: crypto.randomUUID(),
    },
  ],
};
