// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import FormField from "layouts/profile/components/FormField";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
import SuiSelect from "components/SuiSelect";

function CalculatorLeadForm(props) {
  const { values } = breakpoints;
  const { inputs, onChange, onSelectChange } = props;

  const options = {
    incomeType: [
      { value: "salary", label: "Salary" },
      { value: "hourly", label: "Hourly" },
      { value: "other", label: "Other" },
    ],
    downPaymentLocation: [
      { value: "checkingSavings", label: "Checking / Savings" },
      { value: "cash", label: "Cash" },
      { value: "other", label: "Other" },
    ],
  };

  return (
    <Accordion style={{ borderRadius: 10, boxShadow: "0px 0px 20px #ECEDEE" }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="basics-accordion"
        id="basics-accordion-header"
        style={{ marginLeft: 15 }}
      >
        <SuiTypography
          textTransform="capitalize"
          fontWeight="bold"
          variant={window.innerWidth < values.sm ? "h6" : "h5"}
        >
          Qualification Questions
        </SuiTypography>
      </AccordionSummary>
      <SuiBox p={3}>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type="number"
                name="dtiPercentage"
                value={inputs.dtiPercentage || ""}
                label="Debt-to-Income Percentage"
                placeholder="0"
                onChange={(e) => onChange(e, "calculator")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="number"
                name="income"
                value={inputs.income || ""}
                label="Annual Income Amount"
                placeholder="0"
                onChange={(e) => onChange(e, "calculator")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Income Type
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.incomeType === ""
                    ? ""
                    : options.incomeType.filter((option) => option.value === inputs.incomeType)
                }
                placeholder=""
                name="incomeType"
                options={options.incomeType}
                onChange={(e) => onSelectChange(e, "calculator", "incomeType")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="number"
                name="downPaymentAmount"
                value={inputs.downPaymentAmount || ""}
                label="Down Payment Amount"
                placeholder="0"
                onChange={(e) => onChange(e, "calculator")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Down Payment Location
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.downPaymentLocation === ""
                    ? ""
                    : options.downPaymentLocation.filter(
                        (option) => option.value === inputs.downPaymentLocation
                      )
                }
                placeholder=""
                name="downPaymentLocation"
                options={options.downPaymentLocation}
                onChange={(e) => onSelectChange(e, "calculator", "downPaymentLocation")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="number"
                name="debtAuto"
                value={inputs.debtAuto || ""}
                label="Monthly Car Payment"
                placeholder="0"
                onChange={(e) => onChange(e, "calculator")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="number"
                name="debtStudent"
                value={inputs.debtStudent || ""}
                label="Amount Owed for Student Loans"
                placeholder="0"
                onChange={(e) => onChange(e, "calculator")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="number"
                name="debtCreditNum"
                value={inputs.debtCreditNum || ""}
                label="Number of Credit Cards"
                placeholder="0"
                onChange={(e) => onChange(e, "calculator")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="number"
                name="debtCreditMin"
                value={inputs.debtCreditMin || ""}
                label="Average Monthly Credit Card Minimum Payment"
                placeholder="0"
                onChange={(e) => onChange(e, "calculator")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="number"
                name="debtChild"
                value={inputs.debtChild || ""}
                label="Monthly Child Support Payment"
                placeholder="0"
                onChange={(e) => onChange(e, "calculator")}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormField
                type="number"
                name="debtJudgements"
                value={inputs.debtJudgements || ""}
                label="Amount Owed for Legal Judgements"
                placeholder="0"
                onChange={(e) => onChange(e, "calculator")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="number"
                name="debtForeclosure"
                value={inputs.debtForeclosure || ""}
                label="Amount Owed for Forclosure"
                placeholder="0"
                onChange={(e) => onChange(e, "calculator")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="number"
                name="debtDeferred"
                value={inputs.debtDeferred || ""}
                label="Amount Owed for Deferred Debt"
                placeholder="0"
                onChange={(e) => onChange(e, "calculator")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="number"
                name="debtCollections"
                value={inputs.debtCollections || ""}
                label="Amount Owed to Collections"
                placeholder="0"
                onChange={(e) => onChange(e, "calculator")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="number"
                name="debtBankrupt"
                value={inputs.debtBankrupt || ""}
                label="Amount Owed for Bankruptcy"
                placeholder="0"
                onChange={(e) => onChange(e, "calculator")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="number"
                name="debtOther"
                value={inputs.debtOther || ""}
                label="Total Amount for Other Debts Owed"
                placeholder="0"
                onChange={(e) => onChange(e, "calculator")}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </SuiBox>
    </Accordion>
  );
}

CalculatorLeadForm.propTypes = {
  inputs: PropTypes.shape({
    dtiPercentage: PropTypes.number.isRequired,
    debtAuto: PropTypes.number.isRequired,
    debtBankrupt: PropTypes.number.isRequired,
    debtChild: PropTypes.number.isRequired,
    debtCollections: PropTypes.number.isRequired,
    debtCreditMin: PropTypes.number.isRequired,
    debtCreditNum: PropTypes.number.isRequired,
    debtDeferred: PropTypes.number.isRequired,
    debtForeclosure: PropTypes.number.isRequired,
    debtJudgements: PropTypes.number.isRequired,
    debtOther: PropTypes.number.isRequired,
    debtStudent: PropTypes.number.isRequired,
    income: PropTypes.number.isRequired,
    incomeType: PropTypes.string.isRequired,
    downPaymentAmount: PropTypes.number.isRequired,
    downPaymentLocation: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
};

export default CalculatorLeadForm;
