// Proptypes
import PropTypes from "prop-types";

// @mui material components
import { Card, CardContent, Grid, CardHeader, Stack, Tooltip, Divider } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

// Soft UI Dashboard PRO React components
import SuiEditor from "components/SuiEditor";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import FormField from "layouts/profile/components/FormField";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function EmailTemplateEditor(props) {
  const { title, message, templateName, onChange, onSubmit, hint, showName, onNameChange, label } =
    props;
  const { values } = breakpoints;

  return (
    <Card style={{ padding: window.innerWidth > values.md ? 10 : 5 }}>
      <CardHeader
        title={
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            style={{ paddingLeft: 1, paddingTop: 3 }}
          >
            <SuiTypography
              variant={window.innerWidth > values.md ? "h4" : "h6"}
              fontWeight="medium"
              textColor="dark"
            >
              {title}
            </SuiTypography>
            <Tooltip title={hint}>
              <HelpIcon color="#8392ab" />
            </Tooltip>
            <Divider />
          </Stack>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          {showName ? (
            <Grid item xs={12}>
              <SuiBox>
                <FormField
                  type="text"
                  name="name"
                  value={templateName || ""}
                  label={label}
                  placeholder="E.g. 1st Contact Email"
                  onChange={onNameChange}
                />
              </SuiBox>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <SuiEditor value={message} onChange={(content) => onChange(content)} />
          </Grid>
          <Grid item xs={12} align="end" style={{ marginTop: 10 }}>
            <SuiButton variant="gradient" buttonColor="error" size="medium" onClick={onSubmit}>
              Save Changes
            </SuiButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

EmailTemplateEditor.defaultProps = {
  showName: false,
  templateName: "",
  onNameChange: () => {},
  label: "Template Name",
};

EmailTemplateEditor.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  templateName: PropTypes.string,
  hint: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onNameChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  showName: PropTypes.bool,
  label: PropTypes.string,
};

export default EmailTemplateEditor;
