import { useEffect, useState } from "react";
import { UI_ERROR_CLEAR, SET_TASKS } from "redux/types";
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import Grid from "@mui/material/Grid";

// Firebase
import { collection, query, onSnapshot, getFirestore } from "firebase/firestore";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiSnackbar from "components/SuiSnackbar";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";
import ProgressLineChart from "examples/Charts/LineCharts/ProgressLineChart";
import ProgressDoughnutChart from "examples/Charts/DoughnutCharts/ProgressDoughnutChart";

// Components
import LoanaTaskList from "./components/LoanaTaskList";
import LoanaTaskCompletedList from "./components/LoanaTaskCompletedList";

function Tasks() {
  const bypass = true;

  const dispatch = useDispatch();
  const db = getFirestore();

  // User, Data, UI Redux Objects
  const loading = useSelector((state) => state.ui.loading);
  const userTasks = useSelector((state) => state.data.tasks);
  const user = useSelector((state) => state.user);

  const [showTaskComplete, setShowTaskComplete] = useState(false);
  const [taskCompleteTrigger, setTaskCompleteTrigger] = useState(false);
  const [showTaskAdded, setShowTaskAdded] = useState(false);
  const [taskAddedTrigger, setTaskAddedTrigger] = useState(false);

  /* ---------------- Analytics ----------------- */
  // Analytics Data - Annual
  const [lineChartData, setLineChartData] = useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  });
  const [lineChartCount, setLineChartCount] = useState(0);
  const [lineChartProgress] = useState(Math.round((new Date().getMonth() / 11) * 100));

  // Analytics Data - Breakdown
  const [doughnutChartData, setDoughnutChartData] = useState({
    labels: ["Done", "In progress"],
    datasets: {
      label: "Breakdown",
      backgroundColors: ["info", "secondary"],
      data: [0, 0],
    },
  });
  const [doughnutChartCount, setDoughnutChartCount] = useState(0);

  // Updates analytics charts after redux data has been loaded
  useEffect(() => {
    if (userTasks && userTasks.length !== 0) {
      const lineData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const doughnutData = [0, 0];
      let lineCount = 0;
      let doughnutCount = 0;
      userTasks.forEach((item) => {
        const year = new Date(item.createdAt).getFullYear();
        const month = new Date(item.createdAt).getMonth();
        const currentYear = new Date().getFullYear();
        doughnutCount += 1;
        if (item.done) {
          doughnutData[0] += 1;
        } else {
          doughnutData[1] += 1;
        }
        if (year === currentYear) {
          lineData[month] += 1;
          lineCount += 1;
        }
      });
      setLineChartData((inputs) => ({
        ...inputs,
        data: lineData,
      }));
      setDoughnutChartData((inputs) => ({
        ...inputs,
        datasets: {
          label: "Breakdown",
          backgroundColors: ["info", "secondary"],
          data: doughnutData,
        },
      }));
      setLineChartCount(lineCount);
      setDoughnutChartCount(doughnutCount);
    }
  }, [userTasks]);
  /* ----------------------------------------- */

  const toggleTaskCompleteSnack = () => {
    setShowTaskComplete(true);
    setTaskCompleteTrigger(true);
  };

  const clearTaskComplete = () => {
    setShowTaskComplete(false);
    setTaskCompleteTrigger(false);
  };

  const toggleTaskAddedSnack = () => {
    setShowTaskAdded(true);
    setTaskAddedTrigger(true);
  };

  const clearTaskAdded = () => {
    setShowTaskAdded(false);
    setTaskAddedTrigger(false);
  };

  useEffect(() => {
    if (taskAddedTrigger) {
      setTimeout(() => {
        clearTaskAdded();
      }, 5000);
    }
  }, [taskAddedTrigger]);

  useEffect(() => {
    if (taskCompleteTrigger) {
      setTimeout(() => {
        clearTaskComplete();
      }, 5000);
    }
  }, [taskCompleteTrigger]);

  // Creates Firestore channel to listen for realtime document updates
  useEffect(() => {
    const q = query(collection(db, `/users/${user.profile.uid}/tasks`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
      dispatch({ type: SET_TASKS, payload: data });
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    dispatch({ type: UI_ERROR_CLEAR });
  }, []);

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {userTasks && userTasks.length !== 0 ? (
                  <LoanaTaskList
                    tasks={userTasks.filter((item) => !item.done)}
                    triggerComplete={toggleTaskCompleteSnack}
                    triggerAdded={toggleTaskAddedSnack}
                    user={user.profile}
                    team={user.company.team}
                  />
                ) : (
                  <LoanaTaskList
                    tasks={userTasks}
                    triggerComplete={toggleTaskCompleteSnack}
                    triggerAdded={toggleTaskAddedSnack}
                    user={user.profile}
                    team={user.company.team}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {userTasks && userTasks.length !== 0 ? (
                  <LoanaTaskCompletedList tasks={userTasks.sort((a, b) => a.orderId - b.orderId)} />
                ) : (
                  <LoanaTaskCompletedList tasks={userTasks} />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ProgressLineChart
                  icon="date_range"
                  title="Tasks (Annual)"
                  count={lineChartCount}
                  progress={lineChartProgress}
                  chart={lineChartData}
                />
              </Grid>
              <Grid item xs={12}>
                <ProgressDoughnutChart
                  icon="workspace_premium"
                  title="tasks breakdown"
                  count={doughnutChartCount}
                  chart={doughnutChartData}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
      <SuiSnackbar
        color="info"
        icon="notifications"
        title="Loana Tasks"
        content="Congrats! A task has been completed."
        dateTime=""
        open={showTaskComplete}
        close={toggleTaskCompleteSnack}
      />
      <SuiSnackbar
        color="primary"
        icon="notifications"
        title="Loana Tasks"
        content="A task has been added."
        dateTime=""
        open={showTaskAdded}
        close={toggleTaskAddedSnack}
      />
    </DashboardLayout>
  );
}

export default Tasks;
