// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Checkbox,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import { useState } from "react";

function LeadTemplateAdministrationCard(props) {
  const { currentTemplate, onChange, onSubmit } = props;

  const [options, setOptions] = useState({ ...currentTemplate });

  const handleChange = (event) => {
    if (options[event.target.name]) {
      setOptions((values) => ({ ...values, [event.target.name]: false }));
      onChange(event.target.name);
    } else {
      setOptions((values) => ({ ...values, [event.target.name]: true }));
      onChange(event.target.name);
    }
  };

  return (
    <Card style={{ paddingTop: 5, paddingRight: 5, paddingLeft: 5 }}>
      <CardHeader
        title={
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <SuiTypography variant="h5" fontWeight="bold">
              Lead Template
            </SuiTypography>
            <Tooltip title="Below you will select what pieces of information you want to capture for each lead. This structure guarantees that every member of your team is using the same format for gathering info on any new lead.">
              <HelpIcon color="#8392ab" />
            </Tooltip>
          </Stack>
        }
      />
      <CardContent>
        <SuiBox textAlign="center" mx="auto" mb={4}>
          <SuiBox m={2} textAlign="left">
            <SuiTypography variant="h5" textColor="info" fontWeight="medium">
              Categories
            </SuiTypography>
            <Divider />
            <SuiBox mt={2} textAlign="left">
              <SuiTypography variant="body2" textColor="text">
                <span style={{ color: "#cb0c9f" }}>
                  <b>Basics:</b>
                </span>{" "}
                Contact info and personal data such as birthdate and social security number.
              </SuiTypography>
              <SuiTypography variant="body2" textColor="text">
                <span style={{ color: "#cb0c9f" }}>
                  <b>Addresses:</b>
                </span>{" "}
                Current and previous address information.
              </SuiTypography>
              <SuiTypography variant="body2" textColor="text">
                <span style={{ color: "#cb0c9f" }}>
                  <b>Employment:</b>
                </span>{" "}
                Current and previous employment.
              </SuiTypography>
              <SuiTypography variant="body2" textColor="text">
                <span style={{ color: "#cb0c9f" }}>
                  <b>Qualification Questions:</b>
                </span>{" "}
                Debt and income related questions that include a calculated pre-qualified monthly
                amount.
              </SuiTypography>
              <SuiTypography variant="body2" textColor="text">
                <span style={{ color: "#cb0c9f" }}>
                  <b>1003 Declarations:</b>
                </span>{" "}
                Questions directly from the 1003 application.
              </SuiTypography>
            </SuiBox>
          </SuiBox>
          <SuiBox m={2} textAlign="left">
            <SuiTypography variant="h6" textColor="text" fontWeight="medium">
              Please check the categories you wish to include in your lead template:
            </SuiTypography>
            <Divider />
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12}>
                <SuiBox display="flex">
                  <Grid container alignItems="center">
                    <Grid item xs={2}>
                      <Checkbox
                        name="basics"
                        checked={currentTemplate.basics}
                        onClick={handleChange}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <SuiTypography
                        variant="button"
                        fontWeight="regular"
                        customClass="cursor-pointer user-select-none"
                      >
                        Basics
                      </SuiTypography>
                    </Grid>
                  </Grid>
                </SuiBox>
                <SuiBox display="flex">
                  <Grid container alignItems="center">
                    <Grid item xs={2}>
                      <Checkbox
                        name="addresses"
                        checked={currentTemplate.addresses}
                        onClick={handleChange}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <SuiTypography
                        variant="button"
                        fontWeight="regular"
                        customClass="cursor-pointer user-select-none"
                      >
                        Addresses
                      </SuiTypography>
                    </Grid>
                  </Grid>
                </SuiBox>
                <SuiBox display="flex">
                  <Grid container alignItems="center">
                    <Grid item xs={2}>
                      <Checkbox
                        name="employment"
                        checked={currentTemplate.employment}
                        onClick={handleChange}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <SuiTypography
                        variant="button"
                        fontWeight="regular"
                        customClass="cursor-pointer user-select-none"
                      >
                        Employment
                      </SuiTypography>
                    </Grid>
                  </Grid>
                </SuiBox>
                <SuiBox display="flex">
                  <Grid container alignItems="center">
                    <Grid item xs={2}>
                      <Checkbox
                        name="calculator"
                        checked={currentTemplate.calculator}
                        onClick={handleChange}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <SuiTypography
                        variant="button"
                        fontWeight="regular"
                        customClass="cursor-pointer user-select-none"
                      >
                        Qualification Questions
                      </SuiTypography>
                    </Grid>
                  </Grid>
                </SuiBox>
                <SuiBox display="flex">
                  <Grid container alignItems="center">
                    <Grid item xs={2}>
                      <Checkbox
                        name="declarations"
                        checked={currentTemplate.declarations}
                        onClick={handleChange}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <SuiTypography
                        variant="button"
                        fontWeight="regular"
                        customClass="cursor-pointer user-select-none"
                      >
                        1003 Declarations
                      </SuiTypography>
                    </Grid>
                  </Grid>
                </SuiBox>
              </Grid>
            </Grid>
          </SuiBox>
          <Grid container>
            <Grid item xs={12} align="end" style={{ marginTop: 10 }}>
              <SuiButton variant="gradient" buttonColor="error" size="medium" onClick={onSubmit}>
                Save Changes
              </SuiButton>
            </Grid>
          </Grid>
        </SuiBox>
      </CardContent>
    </Card>
  );
}

LeadTemplateAdministrationCard.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentTemplate: PropTypes.shape({
    basics: PropTypes.bool,
    addresses: PropTypes.bool,
    employment: PropTypes.bool,
    calculator: PropTypes.bool,
    declarations: PropTypes.bool,
  }).isRequired,
};

export default LeadTemplateAdministrationCard;
