// Redux
import { UI_ERROR_CLEAR, API_SUCCESS, UI_LOADING_CLEAR } from "../../redux/types";

const apiSuccess = (dispatch) => {
  dispatch({ type: API_SUCCESS });
  dispatch({ type: UI_ERROR_CLEAR });
  dispatch({ type: UI_LOADING_CLEAR });
};

export default apiSuccess;
