// Redux
import { UI_ERROR_CLEAR, API_REQUEST, UI_LOADING } from "../../redux/types";

const apiStart = (dispatch) => {
  dispatch({ type: UI_ERROR_CLEAR });
  dispatch({ type: UI_LOADING });
  dispatch({ type: API_REQUEST });
};

export default apiStart;
