import {
  SET_ISLOADING,
  SET_ISEMPTY,
  SET_ISAUTHENTICATED,
  SET_PROFILE,
  SET_AUTH,
  SET_ISAUTHLOADING,
  SET_COMPANY,
  SET_ISCOMPANYLOADING,
} from "../types";

const initialState = {
  isLoading: false,
  isAuthLoading: true,
  isCompanyLoading: true,
  isEmpty: true,
  isAuthenticated: false,
  profile: {},
  auth: {},
  company: {},
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ISLOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_ISAUTHLOADING:
      return {
        ...state,
        isAuthLoading: action.payload,
      };
    case SET_ISCOMPANYLOADING:
      return {
        ...state,
        isCompanyLoading: action.payload,
      };
    case SET_ISEMPTY:
      return {
        ...state,
        isEmpty: action.payload,
      };
    case SET_ISAUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case SET_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case SET_AUTH:
      return {
        ...state,
        auth: action.payload,
      };
    default:
      return state;
  }
}
