// @mui material components
import { makeStyles } from "@mui/styles";

const styles = makeStyles(({ palette, functions, typography }) => {
  const { white } = palette;
  const { pxToRem } = functions;
  const { size } = typography;

  return {
    closedDialog: {
      backgroundImage: "linear-gradient(to top left, #17ad37, #98ec2d)",
      backgroundSize: "cover",
    },
    closedDialog_button: {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      color: `${white.main} !important`,

      "& .material-icons": {
        fontSize: size.regular,
        marginLeft: pxToRem(4),
        transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
      },

      "&:hover .material-icons": {
        transform: `translateX(${pxToRem(5)})`,
      },
    },
  };
});

export default styles;
