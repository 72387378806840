// @mui material components
import Grid from "@mui/material/Grid";

// Proptypes
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField";

function Company({ onChange, inputs }) {
  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SuiBox mb={1}>
          <SuiTypography variant="h5" fontWeight="regular">
            Great! Next, we&apos;ll need a bit of info on your company
          </SuiTypography>
        </SuiBox>
      </SuiBox>
      <SuiBox mt={2}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SuiBox>
                  <FormField
                    type="text"
                    name="companyName"
                    value={inputs.companyName || ""}
                    label="company name"
                    placeholder="Eg. ABC Mortgage LLC."
                    onChange={onChange}
                  />
                </SuiBox>
              </Grid>
              <Grid item xs={12}>
                <SuiBox>
                  <FormField
                    type="text"
                    name="companyNmls"
                    value={inputs.companyNmls || ""}
                    label="Company NMLS # (If applicable)"
                    placeholder="Eg. 123456"
                    onChange={onChange}
                  />
                </SuiBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

Company.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputs: PropTypes.shape({
    companyName: PropTypes.string,
    companyNmls: PropTypes.string,
  }).isRequired,
};

export default Company;
