module.exports = {
  new: { title: "New", status: "new", color: "dark", label: "new" },
  pq: { title: "Pre-Qualified", status: "pq", color: "secondary", label: "pre-qualified" },
  ai: { title: "Application In", status: "ai", color: "primary", label: "application in" },
  docs: {
    title: "Waiting on Documents",
    status: "docs",
    color: "info",
    label: "waiting on documents",
  },
  pa: { title: "Pre-Approved", status: "pa", color: "primary", label: "pre-approved" },
  uc: { title: "Under Contract", status: "uc", color: "warning", label: "under contract" },
  les: {
    title: "Loan Estimate Sent",
    status: "les",
    color: "secondary",
    label: "loan estimate sent",
  },
  closed: { title: "Closed", status: "closed", color: "success", label: "closed" },
  hold: { title: "Holding", status: "hold", color: "dark", label: "holding" },
  crn: { title: "Credit Repair Needed", status: "crn", color: "dark", label: "credit repair" },
  nr: { title: "No Response", status: "nr", color: "dark", label: "no response" },
  lost: { title: "Lost", status: "lost", color: "error", label: "lost" },
  dnq: { title: "Does Not Qualify", status: "dnq", color: "dark", label: "does not qualify" },
};
