import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UI_ERROR, SET_OPPS } from "redux/types";
import { apiPostWithReturn } from "redux/actions/dataActions";
import { useHistory } from "react-router-dom";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// Firebase
import { collection, query, onSnapshot, getFirestore } from "firebase/firestore";

// Day JS
import dayjs from "dayjs";

// @mui material components
import { Dialog, DialogActions, DialogTitle, Grid, Divider, DialogContent } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
import SuiButton from "components/SuiButton";
import SuiDatePicker from "components/SuiDatePicker";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Utils
import oppSchema from "utils/schemas/opp";
import organizeOpps from "utils/helpers/organizeOpps";
import leadsRefresh from "utils/helpers/leadsRefresh";

// Custom Components
import OpportunityForm from "./components/OpportunityForm";
import OpportunityFormActions from "./components/OpportunityFormActions";
import ClosedDialog from "../components/ClosedDialog";

function AddOpportunity() {
  const { values } = breakpoints;
  const bypass = true;
  const addDeleteAction = false;

  // Opens UI Error
  const [openError, setOpenError] = useState(false);

  // Dispatch and History declarations
  const dispatch = useDispatch();
  const history = useHistory();
  const db = getFirestore();

  // User, Data, UI Redux Objects
  const user = useSelector((state) => state.user);
  const loading = useSelector((state) => state.ui.loading);
  const inputError = useSelector((state) => state.ui.error);
  const leadsAll = useSelector((state) => state.data.leads);

  // User Inputs
  const [oppInputs, setOppInputs] = useState({ ...oppSchema, oppOwner: user.profile.uid });
  const [addedLeads, setAddedLeads] = useState([]);

  /* ------------------ Hold Dialog ------------------ */
  const [openHold, setOpenHold] = useState(false);
  const [holdDate, setHoldDate] = useState("");
  const [checkedHold, setCheckedHold] = useState(false);

  // Clears the checked, holdDate, and resets the user input fields
  const handleHoldRemove = () => {
    setCheckedHold(false);
    setHoldDate("");
    setOppInputs((inputs) => ({
      ...inputs,
      holdUntil: "",
      holdActive: false,
    }));
  };

  // Controls the switch for Hold for Future Opportunity
  const handleCheckHold = (event) => {
    if (!event.target.checked) {
      handleHoldRemove();
    }
    setCheckedHold(event.target.checked);
  };

  // Closes the Hold Dialog and clears items. Used for if user clicks off the dialog
  const handleCloseHoldDialog = () => {
    handleHoldRemove();
    setOpenHold(false);
  };

  // Sets a temporary state to store the hold date user input
  const handleHoldChange = (event) => setHoldDate(event[0].toISOString());

  // Handles user cancelling the date select for the hold date
  const handleHoldCancel = () => {
    setHoldDate("");
    setCheckedHold(false);
  };

  // Handles user saving the selected date
  const handleHoldSave = () => {
    setOppInputs((inputs) => ({
      ...inputs,
      holdUntil: holdDate,
      holdActive: true,
    }));
    setOpenHold(false);
  };

  // Opens the dialog depending on the switch's state
  useEffect(() => {
    if (checkedHold) {
      setOpenHold(true);
    } else {
      setOpenHold(false);
    }
  }, [checkedHold]);
  /* ----------------------------------------------------------- */

  // ------------------ Credit Repair Switch ------------------ //
  const [checkedCrn, setCheckedCrn] = useState(false);

  // Switch state handler
  const handleCheckCrn = (event) => {
    setCheckedCrn(event.target.checked);
    setOppInputs((inputs) => ({
      ...inputs,
      creditRepairActive: event.target.checked,
      creditRepairUntil: event.target.checked
        ? new Date(
            Date.now() + user.company.followupTemplate.creditRepair * 24 * 60 * 60 * 1000
          ).toISOString()
        : "",
    }));
  };
  /* ----------------------------------------------------------- */

  /* ------------------ No Response Switch ------------------ */
  const [checkedNr, setCheckedNr] = useState(false);

  // Switch state handler
  const handleCheckNr = (event) => {
    setCheckedNr(event.target.checked);
    setOppInputs((inputs) => ({
      ...inputs,
      noResponseActive: event.target.checked,
      noResponseTask: event.target.checked,
      noResponseUntil: event.target.checked
        ? new Date(
            Date.now() + user.company.followupTemplate.noResponse * 24 * 60 * 60 * 1000
          ).toISOString()
        : "",
    }));
  };
  /* ----------------------------------------------------------- */

  /* -------------------- Do Not Contact and Does Not Qualify Switches ---------------- */
  const [checkedDnc, setCheckedDnc] = useState(false);
  const [checkedDnq, setCheckedDnq] = useState(false);

  // Switch state handler
  const handleCheckDnc = (event) => {
    if (event.target.checked) {
      setCheckedDnc(event.target.checked);
      setCheckedDnq(false);
      setCheckedHold(false);
      setHoldDate("");
      setCheckedNr(false);
      setCheckedCrn(false);
      setOppInputs((inputs) => ({
        ...inputs,
        dnc: event.target.checked,
        dnq: false,
        status: "lost",
        holdUntil: "",
        holdActive: false,
        creditRepairActive: false,
        creditRepairUntil: "",
        noResponseActive: false,
        noResponseUntil: "",
      }));
    } else {
      setCheckedDnc(event.target.checked);
      setOppInputs((inputs) => ({
        ...inputs,
        dnc: event.target.checked,
        status: "new",
      }));
    }
  };

  // Switch state handler
  const handleCheckDnq = (event) => {
    if (event.target.checked) {
      setCheckedDnq(event.target.checked);
      setCheckedDnc(false);
      setCheckedHold(false);
      setHoldDate("");
      setCheckedNr(false);
      setCheckedCrn(false);
      setOppInputs((inputs) => ({
        ...inputs,
        dnq: event.target.checked,
        status: "lost",
        holdUntil: "",
        holdActive: false,
        creditRepairActive: false,
        creditRepairUntil: "",
        noResponseActive: false,
        noResponseUntil: "",
      }));
    } else {
      setCheckedDnq(event.target.checked);
      setOppInputs((inputs) => ({
        ...inputs,
        dnq: event.target.checked,
        status: "new",
      }));
    }
  };
  /* ----------------------------------------------------------- */

  /* ----------------------- Closed Dialog ------------------------- */

  const [openClosedDialog, setOpenClosedDialog] = useState(false);
  const [closedInputs, setClosedInputs] = useState({
    closedAmount: 0,
    closedRate: "",
    closedAt: new Date().toISOString(),
  });

  const handleClosedAmountInput = (event) => {
    event.persist();
    setClosedInputs((inputs) => ({
      ...inputs,
      closedAmount: event.target.value,
    }));
  };

  const handleClosedRateChange = (event) => {
    event.persist();
    setClosedInputs((inputs) => ({
      ...inputs,
      closedRate: event.target.value,
    }));
  };

  const handleClosedDateChange = (event) => {
    if (event && event.length !== 0) {
      setClosedInputs((inputs) => ({ ...inputs, closedAt: event[0].toISOString() }));
    } else {
      setClosedInputs((inputs) => ({ ...inputs, closedAt: new Date().toISOString() }));
    }
  };

  const handleOpenClosedDialog = () => setOpenClosedDialog(true);

  // Handles User submit action of closed amount
  const handleSubmitClosedAmount = async (event) => {
    event.preventDefault();
    const submitData = {
      opp: {
        ...oppInputs,
        closedAmount: parseInt(closedInputs.closedAmount, 10),
        closedAt: closedInputs.closedAt,
        closedRate: closedInputs.closedRate,
        refinanceJourneyActive: true,
        refinanceJourneyStatus: "opener",
      },
      companyId: user.company.companyId,
    };
    dispatch(apiPostWithReturn("/opp/add", submitData))
      .then((res) => {
        if (res.status === 200) {
          history.push("/pipeline/opportunities");
        }
      })
      .catch((error) => {
        dispatch({
          type: UI_ERROR,
          payload: `Something went wrong. Please contact support@loana.freshdesk.com for help or try again. Error: ${error}`,
        });
      });
  };

  /* ----------------------------------------------------------- */

  /* -------------------- Core Functions --------------------- */
  // onChange handlers for user inputs
  const handleInputsChange = (event, name) => {
    setOppInputs((inputs) => ({
      ...inputs,
      [name]: event.value,
    }));
  };

  // Sets leads to be displayed and added to inputs
  const handleSetLeads = (event, option) => {
    event.preventDefault();
    const leadArr = addedLeads;
    leadArr.push(option);
    setAddedLeads(leadArr);
    setOppInputs((inputs) => ({
      ...inputs,
      leads: leadArr,
    }));
  };

  // Removes specific lead from being displayed and inputs
  const handleRemoveLeads = (event, option) => {
    event.preventDefault();
    const leadArr = addedLeads.filter((obj) => obj.leadId !== option.leadId);
    setAddedLeads(leadArr);
    setOppInputs((inputs) => ({
      ...inputs,
      leads: leadArr,
    }));
  };

  // User input validator
  const validateInputs = () => {
    let valid = true;
    if (
      oppInputs.type === "" ||
      oppInputs.status === "" ||
      oppInputs.owner === "" ||
      oppInputs.leads.length === 0
    ) {
      valid = false;
      dispatch({
        type: UI_ERROR,
        payload: "Please enter at least a type, status, owner, and add one lead",
      });
    }
    return valid;
  };

  // Adds automated notes based off tags
  const addTagNotes = () => {
    const notesArr = [];

    oppInputs.leads.forEach((currentLead) => {
      const filteredLeads = leadsAll.filter((lead) => lead.leadId === currentLead.leadId);
      return filteredLeads[0].notes && filteredLeads[0].notes.length !== 0
        ? filteredLeads[0].notes.forEach((note) => notesArr.push(note))
        : null;
    });
    if (oppInputs.holdActive) {
      notesArr.push({
        createdAt: new Date().toISOString(),
        message: `Future opportunity hold placed until ${dayjs(oppInputs.holdUntil).format(
          "MM/DD/YYYY"
        )}`,
        noteOwner: user.profile.uid,
        noteId: uuidv4(),
      });
    }
    if (oppInputs.creditRepairActive) {
      notesArr.push({
        createdAt: new Date().toISOString(),
        message: `Credit repair needed`,
        noteOwner: user.profile.uid,
        noteId: uuidv4(),
      });
    }
    if (oppInputs.noResponseActive) {
      notesArr.push({
        createdAt: new Date().toISOString(),
        message: `No response from the leads associated with the opportunity`,
        noteOwner: user.profile.uid,
        noteId: uuidv4(),
      });
    }
    if (oppInputs.dnq) {
      notesArr.push({
        createdAt: new Date().toISOString(),
        message: `Associated leads do not qualify`,
        noteOwner: user.profile.uid,
        noteId: uuidv4(),
      });
    }
    if (oppInputs.dnc) {
      notesArr.push({
        createdAt: new Date().toISOString(),
        message: `Do not contact the associated leads`,
        noteOwner: user.profile.uid,
        noteId: uuidv4(),
      });
    }
    return notesArr;
  };

  // Handles User submit action
  const handleSubmit = async (event) => {
    event.preventDefault();
    const submitData = {
      opp: {
        ...oppInputs,
        createNotification: oppInputs.oppOwner !== user.profile.uid || false,
        notes: addTagNotes(),
      },
      companyId: user.company.companyId,
    };
    const valid = validateInputs();
    if (valid) {
      dispatch(apiPostWithReturn("/opp/add", submitData))
        .then((res) => {
          if (res.status === 200) {
            history.push("/pipeline/opportunities");
          }
        })
        .catch((error) => {
          dispatch({
            type: UI_ERROR,
            payload: `Something went wrong. Please contact support@loana.freshdesk.com for help or try again. Error: ${error}`,
          });
        });
    }
  };

  // Creates Firestore channel to listen for realtime document updates
  useEffect(() => {
    const q = query(collection(db, `/companies/${user.company.companyId}/opps`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
      const organized = organizeOpps(data);
      dispatch({ type: SET_OPPS, payload: organized });
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const q = query(collection(db, `/companies/${user.company.companyId}/leads`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const queryData = [];
      querySnapshot.forEach((doc) => {
        queryData.push(doc.data());
      });
      leadsRefresh(dispatch, queryData);
    });

    return unsubscribe;
  }, []);

  // Opens error alert
  useEffect(() => {
    if (inputError !== "") setOpenError(true);
  }, [inputError]);
  /* ----------------------------------------------------------- */

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiBox lineHeight={1} pl={3}>
              <SuiTypography
                textTransform="capitalize"
                fontWeight="bold"
                variant={window.innerWidth < values.sm ? "h5" : "h4"}
              >
                Add a New Opportunity
              </SuiTypography>
            </SuiBox>
            <Divider />
          </Grid>
          {openError ? (
            <Grid item xs={12}>
              <SuiAlert color="warning">
                <SuiTypography variant="body2" textColor="white">
                  <SuiTypography
                    component="a"
                    href="#"
                    variant="body2"
                    fontWeight="medium"
                    textColor="white"
                  >
                    Oops!&nbsp;
                  </SuiTypography>
                  {inputError}
                </SuiTypography>
              </SuiAlert>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={9}>
            <OpportunityForm
              inputs={oppInputs}
              user={user.profile}
              team={user.company.team}
              leads={addedLeads}
              onChange={handleInputsChange}
              onLeadsAdd={handleSetLeads}
              onLeadsRemove={handleRemoveLeads}
              onHoldChange={handleCheckHold}
              onCrnChange={handleCheckCrn}
              onNrChange={handleCheckNr}
              onDnqChange={handleCheckDnq}
              onDncChange={handleCheckDnc}
              checkedHold={checkedHold}
              checkedCrn={checkedCrn}
              checkedNr={checkedNr}
              checkedDnq={checkedDnq}
              checkedDnc={checkedDnc}
              holdDate={holdDate}
              onRemoveHold={handleHoldRemove}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <OpportunityFormActions
              onSubmit={handleSubmit}
              onSubmitClosed={handleOpenClosedDialog}
              deleteAvailable={addDeleteAction}
              status={oppInputs.status}
            />
          </Grid>
        </Grid>
      </SuiBox>
      {/* CLOSED DIALOG */}
      <ClosedDialog
        open={openClosedDialog}
        amount={closedInputs.closedAmount}
        rate={closedInputs.closedRate}
        date={closedInputs.closedAt}
        onRateChange={handleClosedRateChange}
        onDateChange={handleClosedDateChange}
        onChange={handleClosedAmountInput}
        onSubmit={handleSubmitClosedAmount}
      />
      {/* HOLD FOR FUTURE OPPORTUNITY DIALOG */}
      <Dialog open={openHold} onClose={handleCloseHoldDialog}>
        <DialogTitle>Hold for Future Opportunity</DialogTitle>
        <DialogContent>
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            height="100%"
            mb={2}
            mt={2}
          >
            <SuiTypography variant="body2" textColor="text">
              Please select the date you wish to be reminded to follow up with this opportunity
            </SuiTypography>
          </SuiBox>
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            height="100%"
            mb={2}
            mt={2}
          >
            <SuiDatePicker value={oppInputs.holdUntil} onChange={(e) => handleHoldChange(e)} />
          </SuiBox>
        </DialogContent>
        <DialogActions>
          <SuiButton variant="outlined" buttonColor="error" size="small" onClick={handleHoldCancel}>
            Cancel
          </SuiButton>
          <SuiButton
            variant="gradient"
            buttonColor="error"
            size="small"
            onClick={handleHoldSave}
            disabled={holdDate === "" || false}
          >
            Save Hold Date
          </SuiButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default AddOpportunity;
