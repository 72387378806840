import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Custom styles for the NotificationItem
import styles from "examples/Items/NotificationItem/styles";

const NotificationItemNoDate = forwardRef(({ color, image, title, ...rest }, ref) => {
  const classes = styles({ color });

  return (
    <MenuItem {...rest} ref={ref} className={classes.notificationItem}>
      <SuiBox customClass={classes.notificationItem_img}>{image}</SuiBox>
      <SuiBox>
        <SuiTypography variant="button" fontWeight="regular">
          <strong>{title[0]}</strong> {title[1]}
        </SuiTypography>
      </SuiBox>
    </MenuItem>
  );
});

// Setting default values for the props of NotificationItem
NotificationItemNoDate.defaultProps = {
  color: "dark",
};

// Typechecking props for the NotificationItem
NotificationItemNoDate.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  image: PropTypes.node.isRequired,
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default NotificationItemNoDate;
