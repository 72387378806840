import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiPostWithReturn } from "redux/actions/dataActions";
import { UI_ERROR } from "redux/types";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// MUI
import { Help } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, Stack, Tooltip } from "@mui/material";

// SUI
import SuiAlert from "components/SuiAlert";
import SuiBox from "components/SuiBox";
import SuiSelect from "components/SuiSelect";
import SuiTypography from "components/SuiTypography";
import FormField from "layouts/profile/components/FormField";

// Custom Components
import EmailEditor from "../EmailEditor";

function EmailDialog(props) {
  const { open, onClose, opp } = props;

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const inputError = useSelector((state) => state.ui.error);

  const [emailTemplates, setEmailTemplates] = useState([]);
  const [currentEmailTemplate, setCurrentEmailTemplate] = useState("");
  const [subject, setSubject] = useState("");

  // Opens UI Error
  const [openError, setOpenError] = useState(false);

  const setOptions = (arr) => {
    const temps = [];
    arr.forEach((item) => {
      temps.push({
        value: item.template,
        label: item.name,
      });
    });
    return temps;
  };

  const options = {
    email: setOptions(emailTemplates),
  };

  // Handles closing the email dialog
  const handleCloseEmailDialog = () => {
    setCurrentEmailTemplate("");
    setSubject("");
    onClose();
  };

  // Handles user input of email content
  const handleEmailChange = (content) => setCurrentEmailTemplate(content);

  // Handles user input of email subject
  const handleSubjectChange = (event) => {
    event.persist();
    setSubject(event.target.value);
  };

  // Handles sending the email
  const handleEmailSubmit = (event) => {
    event.preventDefault();
    if (currentEmailTemplate !== "" && subject !== "") {
      handleCloseEmailDialog();
      dispatch(
        apiPostWithReturn("/email/send", {
          from: `${user.profile.firstName} ${user.profile.lastName} <${user.profile.firstName}@mortgage.helloloana.com>`,
          replyTo: user.profile.email,
          emails: opp.leads.map((lead) => lead.email),
          message: currentEmailTemplate,
          companyId: user.company.companyId,
          oppId: opp.oppId,
          subject,
        })
      );
    } else {
      dispatch({
        type: UI_ERROR,
        payload: "Email and subject cannot be blank.",
      });
    }
  };

  // Handles user changing email template
  const handleTemplateChange = (event) => setCurrentEmailTemplate(event.value);

  // Sets email templates from user profile
  useEffect(() => {
    if (user.profile.emailTemplates && user.profile.emailTemplates.length !== 0) {
      setEmailTemplates(user.profile.emailTemplates);
    }
  }, [user]);

  // Opens input error alert
  useEffect(() => {
    if (inputError !== "") setOpenError(true);
  }, [inputError]);

  return (
    <Dialog open={open} onClose={handleCloseEmailDialog} maxWidth="md" fullWidth>
      <DialogTitle>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          style={{ paddingLeft: 1, paddingTop: 3 }}
        >
          <SuiTypography variant="h4" fontWeight="medium" textColor="dark">
            Send an Email
          </SuiTypography>
          <Tooltip title="Edit the email that will automatically be sent to the associated leads.">
            <Help color="#8392ab" />
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {openError ? (
          <SuiAlert color="warning">
            <SuiTypography variant="body2" textColor="white">
              <SuiTypography
                component="a"
                href="#"
                variant="body2"
                fontWeight="medium"
                textColor="white"
              >
                Oops!&nbsp;
              </SuiTypography>
              {inputError}
            </SuiTypography>
          </SuiAlert>
        ) : null}
        <SuiBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
          mb={2}
          mt={2}
        >
          <SuiTypography variant="body2" textColor="text">
            Please select the Email template you wish to use
          </SuiTypography>
        </SuiBox>
        <SuiBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
          mb={4}
          mt={2}
        >
          {emailTemplates && emailTemplates.length !== 0 ? (
            <SuiSelect
              value={
                currentEmailTemplate === ""
                  ? ""
                  : options.email.filter((option) => option.value === currentEmailTemplate)
              }
              placeholder=""
              options={options.email}
              onChange={handleTemplateChange}
            />
          ) : (
            <SuiTypography variant="caption">No Email templates have been saved</SuiTypography>
          )}
        </SuiBox>
        <SuiBox mb={4}>
          <FormField
            type="text"
            name="subject"
            value={subject}
            label="Subject"
            placeholder=""
            onChange={handleSubjectChange}
          />
        </SuiBox>
        <SuiBox>
          <EmailEditor
            message={currentEmailTemplate}
            onChange={handleEmailChange}
            onSubmit={handleEmailSubmit}
          />
        </SuiBox>
      </DialogContent>
    </Dialog>
  );
}

EmailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  opp: PropTypes.shape({
    leads: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string,
      }).isRequired
    ),
    oppId: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EmailDialog;
