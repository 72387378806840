import { useDispatch, useSelector } from "react-redux";
import { apiPostWithReturn } from "redux/actions/dataActions";
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Stripe
import { loadStripe } from "@stripe/stripe-js";

// @mui material components
import { Stack, Card, Icon } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiBadge from "components/SuiBadge";
import SuiButton from "components/SuiButton";

function LoanaDashboardPricingCard(props) {
  const { badge, price, specifications, action, fee, seats, onAdd, onMinus, upgrade } = props;

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const stripePromise = loadStripe(
    "pk_live_51JuMxABs5Bn0Ienuz4uxajzrGTTTxF3Zhw5ka16Tg3U80aqhSGZy8NfSJGAjqWTEEmz1Gnt3sP8rpx5WR4IB9dr200uu4rfQiw"
  );

  const user = useSelector((state) => state.user);

  const renderSpecifications = specifications.map(({ label, includes }) => (
    <SuiBox key={label} display="flex" alignItems="center" p={1}>
      <SuiBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.5rem"
        height="1.5rem"
        borderRadius="50%"
        boxShadow="regular"
        backgroundColor={includes ? "success" : "secondary"}
        backgroundGradient
        mr={2}
      >
        <SuiTypography variant="button" textColor="white" customClass="line-height-0">
          <Icon className=" font-bold">{includes ? "done" : "remove"}</Icon>
        </SuiTypography>
      </SuiBox>
      <SuiTypography variant="body2" textColor="text">
        {label}
      </SuiTypography>
    </SuiBox>
  ));

  const handlePurchasePro = async () => {
    try {
      const data = {
        // DEV
        // subscriptionPrice:
        //   price.value >= "250"
        //     ? "price_1KXuLtBs5Bn0Ienuw9sxNt83"
        //     : "price_1KXuMnBs5Bn0IenuiP21uIGk",
        // onboardingPrice:
        //   price.value >= "250"
        //     ? "price_1KWTMpBs5Bn0IenuKe2YBzmI"
        //     : "price_1KWTNQBs5Bn0IenuPF0A3Wwc",
        subscriptionPrice:
          price.value >= "250"
            ? "price_1KXwtNBs5Bn0IenuPEHSMlJD"
            : "price_1KXwsUBs5Bn0Ienu1VcLFwRB",
        onboardingPrice:
          price.value >= "250"
            ? "price_1KXwugBs5Bn0IenuwDxHiD39"
            : "price_1KXwuKBs5Bn0IenudidMF4IS",
        seats,
        email: user.auth.email,
        companyId: user.profile.companyId,
        pathname,
      };

      const stripe = await stripePromise;

      const response = await dispatch(apiPostWithReturn("/stripe/checkout", data)).then(
        (res) => res.data.message
      );

      const result = await stripe.redirectToCheckout({
        sessionId: response,
      });

      return Promise.all([stripe, response, result]).then((results) => results);
    } catch (error) {
      return error;
    }
  };

  const handleUpgradePro = () =>
    dispatch(
      apiPostWithReturn("/stripe/portal", {
        type: "upgrade",
        customer: user.company.stripeCustomer,
        companyId: user.company.companyId,
      })
    ).then((res) => {
      if (res.status === 200) {
        window.location.replace(res.data.message);
      }
    });

  return (
    <Card>
      <SuiBox pt={3} pb={2} px={2} textAlign="center">
        <SuiBadge
          variant="contained"
          color={badge.color}
          badgeContent={badge.label}
          circular
          container
        />
        <SuiBox my={3}>
          <SuiTypography variant="h1">
            <SuiTypography display="inline" component="small" variant="h2">
              {price.currency}
            </SuiTypography>
            {price.value}
            <SuiTypography display="inline" component="small" variant="h5">
              {" "}
              per month
            </SuiTypography>
          </SuiTypography>
          {fee !== "0" ? (
            <SuiTypography variant="h6" fontWeight="light">
              + one-time onboarding fee of {fee}
            </SuiTypography>
          ) : null}
          {badge.label === "Team" ? (
            <SuiTypography variant="caption" fontWeight="light">
              Base $250 for up to 5 members, $40 per additional member
            </SuiTypography>
          ) : null}
        </SuiBox>
        {badge.label === "Team" ? (
          <SuiBox my={1}>
            <Stack direction="row" spacing={3} alignItems="center" justifyContent="center">
              <SuiButton variant="gradient" buttonColor="info" onClick={onMinus}>
                -
              </SuiButton>
              <SuiTypography variant="h6" fontWeight="medium">
                {seats} members
              </SuiTypography>
              <SuiButton variant="gradient" buttonColor="info" onClick={onAdd}>
                +
              </SuiButton>
            </Stack>
          </SuiBox>
        ) : null}
      </SuiBox>
      <SuiBox pb={3} px={3}>
        {renderSpecifications}
        <SuiBox mt={3}>
          <SuiButton
            variant="gradient"
            buttonColor={action.color}
            disabled={!user.profile.admin || false}
            fullWidth
            onClick={upgrade ? handleUpgradePro : handlePurchasePro}
          >
            {action.label}&nbsp;
            <Icon className=" font-bold">arrow_forward</Icon>
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// Typechecking props for the DefaultPricingCard
LoanaDashboardPricingCard.propTypes = {
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired,
  fee: PropTypes.string.isRequired,
  seats: PropTypes.number.isRequired,
  onAdd: PropTypes.func.isRequired,
  onMinus: PropTypes.func.isRequired,
  upgrade: PropTypes.bool.isRequired,
};

export default LoanaDashboardPricingCard;
