module.exports = {
  opener: { title: "Opener", status: "opener", color: "dark", label: "opener" },
  nudge: { title: "Nudge", status: "nudge", color: "secondary", label: "nudge" },
  reminderOne: {
    title: "Reminder One",
    status: "reminderOne",
    color: "primary",
    label: "reminder one",
  },
  reminderTwo: {
    title: "Reminder Two",
    status: "reminderTwo",
    color: "info",
    label: "reminder two",
  },
  reminderThree: {
    title: "Reminder Three",
    status: "reminderThree",
    color: "primary",
    label: "reminder three",
  },
  finalChance: {
    title: "Final Chance",
    status: "finalChance",
    color: "warning",
    label: "final chance",
  },
};
