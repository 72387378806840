import { useSelector } from "react-redux";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { CardTitle } from "material-ui";
import { Card, Chip, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function SendActions(props) {
  const { values } = breakpoints;
  const { onSend } = props;

  const user = useSelector((state) => state.user.profile);

  return (
    <Card>
      <CardTitle>
        <Grid container spacing={3} alignItems="center" justifyContent="space-between">
          <Grid item>
            <SuiTypography
              textTransform="capitalize"
              fontWeight="bold"
              variant={window.innerWidth < values.sm ? "h6" : "h5"}
            >
              Send <Chip label="PRO" color="primary" size="small" variant="outlined" />
            </SuiTypography>
          </Grid>
          <Grid item>
            <Stack direction="row" justifyContent="flex-end" alignItems="flex-start">
              <Tooltip
                title={
                  user.proActive
                    ? "Send Email"
                    : "Upgrade to Loana Pro to send emails through Loana"
                }
              >
                <span>
                  <IconButton
                    aria-label="send email"
                    color="error"
                    onClick={onSend}
                    disabled={!user.proActive || false}
                  >
                    <EmailIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          </Grid>
        </Grid>
      </CardTitle>
    </Card>
  );
}

SendActions.propTypes = {
  onSend: PropTypes.func.isRequired,
};

export default SendActions;
