import { useSelector } from "react-redux";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Card, CardContent, CardHeader, Divider, Grid, Stack, Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import FormField from "layouts/profile/components/FormField";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function InviteAdministrationCard(props) {
  const { inputs, onChange, onSubmit, onSelectChange, invites, onUpgrade } = props;
  const { values } = breakpoints;

  const options = [
    { value: "admin", label: "Admininstrator" },
    { value: "user", label: "User" },
  ];

  const user = useSelector((state) => state.user);

  return (
    <Card style={{ paddingTop: 5, paddingRight: 5, paddingLeft: 5, paddingBottom: 10 }}>
      <CardHeader
        title={
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <SuiTypography variant="h5" fontWeight="bold">
              Invite a Team Member
            </SuiTypography>
            <Tooltip title="Invite a new member to your team. Enter an email address and select their account privilege level. The invite is valid for 48 hours.">
              <HelpIcon color="#8392ab" />
            </Tooltip>
          </Stack>
        }
      />
      <CardContent>
        <Grid container spacing={2} justifyContent="center" alignItems="flex-end">
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              name="email"
              value={inputs.email}
              label="Email"
              placeholder=""
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                User Role
              </SuiTypography>
            </SuiBox>
            <SuiSelect
              value={
                inputs.role === "" ? "" : options.filter((option) => option.value === inputs.role)
              }
              placeholder="Select Role"
              options={options}
              onChange={(e) => onSelectChange(e, "role")}
            />
          </Grid>
          <Grid item xs={12} md={2} align={window.innerWidth > values.md ? "center" : "end"}>
            <SuiButton
              variant="gradient"
              buttonColor="error"
              size="medium"
              onClick={
                (user && user.company.proPlan === "free") || (user && user.company.trialActive)
                  ? onSubmit
                  : (user && user.company.proPlan === "single") ||
                    (user && user.company.team.length >= user.company.proSeats)
                  ? onUpgrade
                  : onSubmit
              }
            >
              Send
            </SuiButton>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 25 }}>
            <SuiTypography variant="body2" fontWeight="bold">
              Pending Invites
            </SuiTypography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={window.innerWidth > values.md ? 6 : 8}>
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Email
                </SuiTypography>
              </Grid>
              <Grid item xs={window.innerWidth > values.md ? 6 : 4}>
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  User Role
                </SuiTypography>
              </Grid>
            </Grid>
          </Grid>
          {invites && invites.length !== 0 ? (
            invites.map((invite, index) => (
              <Grid item xs={12} key={invite.inviteId}>
                <Grid container alignItems="center" spacing={1}>
                  {index === 0 ? (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  ) : null}
                  <Grid item xs={window.innerWidth > values.md ? 6 : 8}>
                    <SuiTypography
                      variant={window.innerWidth > values.md ? "body2" : "caption"}
                      fontWeight="bold"
                      textColor="info"
                    >
                      {invite.email}
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={window.innerWidth > values.md ? 6 : 4}>
                    <SuiTypography
                      variant={window.innerWidth > values.md ? "body2" : "caption"}
                      fontWeight="medium"
                    >
                      {invite.role === "admin" ? "Administrator" : "User"}
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item xs={12} align="center">
              <SuiTypography variant="body2">No pending invites</SuiTypography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

InviteAdministrationCard.propTypes = {
  invites: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      role: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  inputs: PropTypes.shape({
    email: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onUpgrade: PropTypes.func.isRequired,
};

export default InviteAdministrationCard;
