import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "redux/actions/userActions";
import { SET_NOTIFICATIONS } from "redux/types";

// react-router components
import { Link, useHistory } from "react-router-dom";

// Firebase
import { collection, query, onSnapshot, getFirestore } from "firebase/firestore";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import {
  AppBar,
  Toolbar,
  IconButton,
  Icon,
  Tooltip,
  Stack,
  Badge,
  Menu,
  Divider,
  MenuItem,
  // Button,
} from "@mui/material";
import { Avatar } from "material-ui";
import LogoutIcon from "@mui/icons-material/Logout";
import CheckIcon from "@mui/icons-material/Check";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";

// Custom styles for DashboardNavbar
import styles from "examples/Navbars/DashboardNavbar/styles";
import NotificationItem from "examples/Items/NotificationItem";
import NotificationItemNoDate from "examples/Items/NotificationItemNoDate";

// Soft UI Dashboard PRO React context
import { useSoftUIController } from "context";
import { apiPostNoLoading } from "redux/actions/dataActions";

function LoanaTopNavbar({ absolute, light, isMini }) {
  const reduxDispatch = useDispatch();
  const history = useHistory();
  const db = getFirestore();

  const [navbarType] = useState("sticky");
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar } = controller;
  const classes = styles({ transparentNavbar, absolute, light, isMini });

  const profile = useSelector((state) => state.user.profile);
  const notifications = useSelector((state) => state.data.notifications);

  const handleMiniSidenav = () => dispatch({ type: "MINI_SIDENAV", value: !miniSidenav });

  const handleLogout = (event) => {
    event.preventDefault();
    reduxDispatch(logoutUser());
  };

  /* ----------- Notification Menu ---------------- */

  const [openMenu, setOpenMenu] = useState(false);
  const [notificationItems, setNotificationItems] = useState([]);
  const [followupCount, setFollowupCount] = useState([]);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const organizeNotifications = () => {
    const organized = [];

    notifications.forEach((notification) => {
      if (notification.type !== "followup") {
        organized.push(notification);
      }
    });

    return organized.sort((a, b) => a.createdAt - b.createdAt);
  };

  const followupNotificationCount = () => {
    let count = 0;
    const message = [];

    notifications.forEach((notification) => {
      if (notification.type === "followup" && !notification.done) {
        count = +1;
      }
    });

    if (count === 0) {
      message.push("");
      message.push("No new follow-ups");
      return message;
    }

    message.push(`${count} new follow-ups`);
    message.push("");
    return message;
  };

  const handleClearNotifications = (event, type, id, itemId) => {
    event.preventDefault();
    if (type === "all") {
      reduxDispatch(apiPostNoLoading("/notifications/edit/all", { type: "all" })).then(() =>
        handleCloseMenu()
      );
    } else if (type === "followup") {
      reduxDispatch(apiPostNoLoading("/notifications/edit/all", { type: "followup" })).then(() => {
        handleCloseMenu();
        history.push("/tools/followups");
      });
    } else {
      reduxDispatch(apiPostNoLoading("/notifications/edit", { notificationId: id })).then(() => {
        handleCloseMenu();
        if (type === "opp") {
          history.push(`/pipeline/opportunities/edit/${itemId}`);
        } else {
          history.push("/tools/tasks");
        }
      });
    }
  };

  useEffect(() => {
    setFollowupCount(followupNotificationCount());
    setNotificationItems(organizeNotifications());
  }, [notifications]);

  // Creates Firestore channel to listen for realtime document updates
  useEffect(() => {
    const q = query(collection(db, `/users/${profile.uid}/notifications`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
      reduxDispatch({ type: SET_NOTIFICATIONS, payload: data });
    });

    return unsubscribe;
  }, []);

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      className={classes.navbar}
    >
      <Toolbar className={classes.navbar_container}>
        <SuiBox customClass={classes.navbar_row} color="inherit" mb={{ xs: 1, md: 0 }}>
          <Icon
            className={classes.navbar_desktop_menu}
            fontSize="medium"
            onClick={handleMiniSidenav}
          >
            {miniSidenav ? "menu_open" : "menu"}
          </Icon>
        </SuiBox>
        {isMini ? null : (
          <SuiBox customClass={classes.navbar_row}>
            <SuiBox
              color={light ? "white" : "inherit"}
              customClass={classes.navbar_section_desktop}
            >
              <IconButton
                size="small"
                color="inherit"
                className={classes.navbar_mobile_menu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? "text-white" : "text-dark"}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
            </SuiBox>
            <SuiBox
              color={light ? "white" : "inherit"}
              customClass={classes.navbar_section_desktop}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Link to="/account/settings">
                  <Avatar
                    alt="User Profile Picture"
                    src={profile.photoURL}
                    style={{ objectFit: "cover" }}
                  />
                </Link>
                <IconButton
                  color="inherit"
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <Badge
                    badgeContent={
                      notifications && notifications.length !== 0
                        ? notifications.filter((obj) => !obj.done).length
                        : 0
                    }
                    color="error"
                  >
                    <Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>
                  </Badge>
                </IconButton>
                {notificationItems && notificationItems.length !== 0 ? (
                  <Menu
                    anchorEl={openMenu}
                    anchorReference={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    open={Boolean(openMenu)}
                    onClose={handleCloseMenu}
                    sx={{ marginTop: "1rem" }}
                    PaperProps={{
                      style: {
                        maxHeight: 500,
                      },
                    }}
                  >
                    <MenuItem>
                      <SuiButton
                        variant="outlined"
                        style={{ borderColor: "#ea0606", color: "#ea0606" }}
                        size="small"
                        onClick={(e) => handleClearNotifications(e, "all")}
                      >
                        clear all notifications
                      </SuiButton>
                    </MenuItem>
                    <Divider />
                    <NotificationItemNoDate
                      image={<NotificationsActiveIcon color="white" />}
                      title={followupCount}
                      onClick={(e) => handleClearNotifications(e, "followup")}
                    />
                    <Divider />
                    {notificationItems
                      .filter((obj) => !obj.done)
                      .map((item) =>
                        item.type === "task" ? (
                          <NotificationItem
                            image={<CheckIcon color="white" />}
                            title={[`New task`, "was assigned to you"]}
                            date={item.createdAt}
                            key={item.notificationId}
                            onClick={(e) =>
                              handleClearNotifications(e, "task", item.notificationId, item.taskId)
                            }
                          />
                        ) : item.type === "opp" ? (
                          <NotificationItem
                            image={<RocketLaunchIcon color="white" />}
                            title={["New opportunity", "was assigned to you"]}
                            date={item.createdAt}
                            key={item.notificationId}
                            onClick={(e) =>
                              handleClearNotifications(e, "opp", item.notificationId, item.oppId)
                            }
                          />
                        ) : null
                      )}
                  </Menu>
                ) : null}
                <IconButton color="inherit" onClick={handleLogout}>
                  <Tooltip title="Logout">
                    <LogoutIcon />
                  </Tooltip>
                </IconButton>
              </Stack>
            </SuiBox>
          </SuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
LoanaTopNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
LoanaTopNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default LoanaTopNavbar;
