import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UI_ERROR_CLEAR, SET_FOLLOWUPS } from "redux/types";

// @mui material components
import Grid from "@mui/material/Grid";

// Firebase
import { collection, query, onSnapshot, getFirestore } from "firebase/firestore";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiSnackbar from "components/SuiSnackbar";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";
import ProgressLineChart from "examples/Charts/LineCharts/ProgressLineChart";
import ProgressDoughnutChart from "examples/Charts/DoughnutCharts/ProgressDoughnutChart";

// Components
import LoanaFollowupList from "./components/LoanaFollowupList";

function Followups() {
  const bypass = true;

  const dispatch = useDispatch();
  const db = getFirestore();

  // User, Data, UI Redux Objects
  const loading = useSelector((state) => state.ui.loading);
  const userFollowups = useSelector((state) => state.data.followups);
  const user = useSelector((state) => state.user);

  const [showFollowUpComplete, setShowFollowUpComplete] = useState(false);
  const [followUpCompleteTrigger, setFollowUpCompleteTrigger] = useState(false);

  /* --------- Analytics ---------- */
  // Analytics Data - Annual
  const [lineChartData, setLineChartData] = useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  });
  const [lineChartCount, setLineChartCount] = useState(0);
  const [lineChartProgress] = useState(Math.round((new Date().getMonth() / 11) * 100));

  // Analytics Data - Breakdown
  const [doughnutChartData, setDoughnutChartData] = useState({
    labels: ["Done", "In progress"],
    datasets: {
      label: "Breakdown",
      backgroundColors: ["info", "secondary"],
      data: [0, 0],
    },
  });
  const [doughnutChartCount, setDoughnutChartCount] = useState(0);

  // Updates analytics charts after redux data has been loaded
  useEffect(() => {
    if (userFollowups && userFollowups.length !== 0) {
      const lineData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const doughnutData = [0, 0];
      let lineCount = 0;
      let doughnutCount = 0;
      userFollowups.forEach((item) => {
        const year = new Date(item.createdAt).getFullYear();
        const month = new Date(item.createdAt).getMonth();
        const currentYear = new Date().getFullYear();
        doughnutCount += 1;
        if (item.done) {
          doughnutData[0] += 1;
        } else {
          doughnutData[1] += 1;
        }
        if (year === currentYear) {
          lineData[month] += 1;
          lineCount += 1;
        }
      });
      setLineChartData((inputs) => ({
        ...inputs,
        data: lineData,
      }));
      setDoughnutChartData((inputs) => ({
        ...inputs,
        datasets: {
          label: "Breakdown",
          backgroundColors: ["info", "secondary"],
          data: doughnutData,
        },
      }));
      setLineChartCount(lineCount);
      setDoughnutChartCount(doughnutCount);
    }
  }, [userFollowups]);
  /* --------------------------------- */

  const toggleFollowUpCompleteSnack = () => {
    setShowFollowUpComplete(true);
    setFollowUpCompleteTrigger(true);
  };

  const clearFollowUpComplete = () => {
    setShowFollowUpComplete(false);
    setFollowUpCompleteTrigger(false);
  };

  useEffect(() => {
    if (followUpCompleteTrigger) {
      setTimeout(() => {
        clearFollowUpComplete();
      }, 5000);
    }
  }, [followUpCompleteTrigger]);

  // Creates Firestore channel to listen for realtime document updates
  useEffect(() => {
    const q = query(collection(db, `/users/${user.profile.uid}/followups`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
      dispatch({ type: SET_FOLLOWUPS, payload: data });
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    dispatch({ type: UI_ERROR_CLEAR });
  }, []);

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {userFollowups && userFollowups.length !== 0 ? (
                  <LoanaFollowupList
                    followups={userFollowups.filter((item) => !item.done)}
                    triggerComplete={toggleFollowUpCompleteSnack}
                    companyId={user.company.companyId}
                  />
                ) : (
                  <LoanaFollowupList
                    followups={userFollowups}
                    triggerComplete={toggleFollowUpCompleteSnack}
                    companyId={user.company.companyId}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ProgressLineChart
                  icon="date_range"
                  title="Follow Ups (Annual)"
                  count={lineChartCount}
                  progress={lineChartProgress}
                  chart={lineChartData}
                />
              </Grid>
              <Grid item xs={12}>
                <ProgressDoughnutChart
                  icon="workspace_premium"
                  title="Follow Ups breakdown"
                  count={doughnutChartCount}
                  chart={doughnutChartData}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mt={3}>
        <SuiSnackbar
          color="info"
          icon="notifications"
          title="Loana Follow Ups"
          content="Congrats! A follow up has been completed."
          open={showFollowUpComplete}
          close={toggleFollowUpCompleteSnack}
          dateTime=""
        />
      </SuiBox>
    </DashboardLayout>
  );
}

export default Followups;
