import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiPostWithReturn } from "redux/actions/dataActions";
import { UI_ERROR_CLEAR, SET_COMPANY } from "redux/types";

// Firebase Firestore
import { doc, getFirestore, onSnapshot } from "firebase/firestore";

// @mui material components
import { Grid } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";

// Teams page components
import Header from "layouts/dashboards/company/team/components/Header";
import TeamOverviewCard from "layouts/dashboards/company/team/components/TeamOverviewCard";
import TeamLeaderboard from "./components/TeamLeaderboard";
import TeamLeaderboardRate from "./components/TeamLeaderboardRate/TeamLeaderboardRate";

function Team() {
  const bypass = true;
  const loading = false;

  const db = getFirestore();
  const dispatch = useDispatch();

  const company = useSelector((state) => state.user.company);
  const data = useSelector((state) => state.data);

  const handleImageChange = (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("file", image);
    dispatch(apiPostWithReturn("/company/profile/image", formData));
  };

  // Creates Firestore channel to listen for realtime document updates
  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "companies", `${company.companyId}`), (document) => {
      dispatch({ type: SET_COMPANY, payload: document.data() });
    });

    return unsubscribe;
  }, []);

  /* ----------- CHARTS ------------- */
  const labels = ["new", "pq", "ai", "docs", "pa", "uc", "les", "closed"];

  const [pipelineData, setPipelineData] = useState({
    labels: [
      "New",
      "Pre-Qualified",
      "Application In",
      "Waiting on Documents",
      "Pre-Approved",
      "Under Contract",
      "Loan Estimate Sent",
      "Closed",
    ],
    datasets: {
      label: "Pipeline Breakdown",
      backgroundColors: [
        "dark",
        "secondary",
        "primary",
        "info",
        "warning",
        "light",
        "error",
        "success",
      ],
      data: [0, 0, 0, 0, 0, 0, 0, 0],
    },
  });

  useEffect(() => {
    if (data.opps && Object.keys(data.opps).length !== 0) {
      const doughnutData = [0, 0, 0, 0, 0, 0, 0, 0];
      Object.keys(data.opps).forEach((status) => {
        if (data.opps[status].length !== 0) {
          data.opps[status].forEach((opp) => {
            labels.forEach((label, index) => {
              if (opp.status === label) {
                doughnutData[index] += 1;
              }
            });
          });
        }
      });
      setPipelineData((inputs) => ({
        ...inputs,
        datasets: {
          label: "Pipeline Breakdown",
          backgroundColors: [
            "dark",
            "secondary",
            "primary",
            "info",
            "warning",
            "light",
            "error",
            "success",
          ],
          data: doughnutData,
        },
      }));
    }
  }, [data]);

  useEffect(() => {
    dispatch({ type: UI_ERROR_CLEAR });
  }, []);

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <Header company={company} onImageChange={handleImageChange} />
      <SuiBox my={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DefaultDoughnutChart title="Pipeline Breakdown" chart={pipelineData} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TeamLeaderboard team={company.team} data={data.opps} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TeamLeaderboardRate team={company.team} data={data.opps} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4}>
            <TeamOverviewCard company={company} />
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
}

export default Team;
