// Redux
import { SET_LEADS, SET_OPPS, SET_TASKS } from "../../redux/types";

const dataRemove = (dispatch) => {
  dispatch({ type: SET_TASKS, payload: [] });
  dispatch({ type: SET_LEADS, payload: [] });
  dispatch({ type: SET_OPPS, payload: [] });
};

export default dataRemove;
