import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { apiPostWithReturn } from "redux/actions/dataActions";

// SUI and Custom Components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// SUI Icons
import Shop from "examples/Icons/Shop";
import SpaceShip from "examples/Icons/SpaceShip";

// Images
import LoanaWelcome from "assets/images/loana-welcome.jpg";
import LoanaLeadsVOpps from "assets/images/loana-leadsvopps.jpg";

// MUI Components
import { Divider } from "material-ui";
import {
  Check,
  ContactSupport,
  Groups,
  LockOpen,
  People,
  PrecisionManufacturing,
  Settings,
  NotificationsActive,
  Handshake,
} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { getUserProfile } from "redux/actions/userActions";

function LoanaWalkthrough() {
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(0);
  const [openWelcome, setOpenWelcome] = useState(true);
  const [openDashboardIntro, setOpenDashboardIntro] = useState(false);
  const [openLeadsVsOpps, setOpenLeadsVsOpps] = useState(false);
  const [openFinishDialog, setOpenFinishDialog] = useState(false);

  const handleNext = () => {
    const step = currentStep + 1;
    setCurrentStep(step);
  };

  useEffect(() => {
    if (currentStep === 1) {
      setOpenDashboardIntro(true);
      setOpenWelcome(false);
    } else if (currentStep === 2) {
      setOpenLeadsVsOpps(true);
      setOpenDashboardIntro(false);
    } else if (currentStep === 3) {
      setOpenFinishDialog(true);
      setOpenLeadsVsOpps(false);
    } else if (currentStep === 4) {
      dispatch(apiPostWithReturn("/user/profile", { showWalkthrough: false })).then(() => {
        dispatch(getUserProfile()).then(() => {
          setOpenFinishDialog(false);
        });
      });
    }
  }, [currentStep]);

  return (
    <div>
      {/* Welcome Dialog */}
      <Dialog
        open={openWelcome}
        keepMounted
        onClose={handleNext}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{ sx: { width: "70%", height: "auto" } }}
      >
        <DialogTitle>
          <SuiBox pt={3} textAlign="center">
            <SuiTypography variant="h2" textColor="info" fontWeight="bold">
              Welcome to Loana!
            </SuiTypography>
          </SuiBox>
        </DialogTitle>
        <DialogContent>
          <SuiBox p={3} textAlign="center">
            <Divider />
            <SuiBox mb={2}>
              <img
                src={LoanaWelcome}
                alt="loana welcome"
                style={{ width: "100%", height: "auto" }}
              />
            </SuiBox>
            <Divider />
            <SuiBox m={2} textAlign="left">
              <SuiTypography variant="h5" textColor="text">
                Our <span style={{ color: "#17c1e8" }}>goal</span> is to cut out the complexity and
                bulkiness of other CRMs. Our <span style={{ color: "#17c1e8" }}>mission</span> is to
                get back to the <span style={{ color: "#17c1e8" }}>basics</span> of what it means to
                be a mortgage professional!
              </SuiTypography>
            </SuiBox>
            <SuiBox m={2} textAlign="left">
              <SuiTypography variant="h5" textColor="text">
                In the next steps, we will walk you through some basics of Loana.
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </DialogContent>
        <DialogActions>
          <SuiBox m={2}>
            <SuiButton variant="gradient" buttonColor="error" onClick={handleNext}>
              Let&apos;s Go!
            </SuiButton>
          </SuiBox>
        </DialogActions>
      </Dialog>
      {/* Dashboard Intro Dialog */}
      <Dialog
        open={openDashboardIntro}
        keepMounted
        onClose={handleNext}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{ sx: { width: "70%", height: "auto" } }}
      >
        <DialogTitle>
          <SuiBox pt={3} textAlign="center">
            <SuiTypography variant="h4" textColor="info" fontWeight="bold">
              Dashboard Intro
            </SuiTypography>
          </SuiBox>
        </DialogTitle>
        <DialogContent>
          <SuiBox p={3} textAlign="center">
            <Divider />
            <SuiBox m={2} textAlign="left">
              <SuiTypography variant="h6" textColor="text">
                Let&apos;s briefly explore the 5 core areas of your Loana Dashboard. You will find
                them on the left menu.
              </SuiTypography>
            </SuiBox>
            <SuiBox m={2} textAlign="left">
              <SuiTypography variant="h5" textColor="info">
                Home
              </SuiTypography>
              <Divider />
              <SuiTypography variant="body2" textColor="text">
                <Shop size="14px" /> Discover company/personal revenue and pipeline analytics.
              </SuiTypography>
            </SuiBox>
            <SuiBox m={2} textAlign="left">
              <SuiTypography variant="h5" textColor="info">
                Pipeline
              </SuiTypography>
              <Divider />
              <SuiTypography variant="body2" textColor="text">
                <Handshake size="12px" /> <b>Partners</b> - Create and manage your list of partners
              </SuiTypography>
              <SuiTypography variant="body2" textColor="text">
                <People size="12px" /> <b>Leads</b> - Create and manage your company&apos;s leads
              </SuiTypography>
              <SuiTypography variant="body2" textColor="text">
                <SpaceShip size="12px" /> <b>Opportunities</b> - Create and manage opportunities
              </SuiTypography>
            </SuiBox>
            <SuiBox m={2} textAlign="left">
              <SuiTypography variant="h5" textColor="info">
                Tools
              </SuiTypography>
              <Divider />
              <SuiTypography variant="body2" textColor="text">
                <Check size="12px" /> <b>Tasks</b> - Add/Complete tasks to stay organized
              </SuiTypography>
              <SuiTypography variant="body2" textColor="text">
                <NotificationsActive size="12px" /> <b>Follow-ups</b> - Automated reminders to
                follow up on your open opportunities.
              </SuiTypography>
              <SuiTypography variant="body2" textColor="text">
                <PrecisionManufacturing size="12px" /> <b>Automations</b> - Deploy automations to
                reclaim your time.
              </SuiTypography>
            </SuiBox>
            <SuiBox m={2} textAlign="left">
              <SuiTypography variant="h5" textColor="info">
                Company
              </SuiTypography>
              <Divider />
              <SuiTypography variant="body2" textColor="text">
                <LockOpen size="12px" /> <b>Administration</b> - Invite and manage your team
              </SuiTypography>
              <SuiTypography variant="body2" textColor="text">
                <Groups size="12px" /> <b>Team</b> - View your team and its performance
              </SuiTypography>
            </SuiBox>
            <SuiBox m={2} textAlign="left">
              <SuiTypography variant="h5" textColor="info">
                Account
              </SuiTypography>
              <Divider />
              <SuiTypography variant="body2" textColor="text">
                <Settings size="12px" /> <b>Settings</b> - Your personal account settings
              </SuiTypography>
              <SuiTypography variant="body2" textColor="text">
                <ContactSupport size="12px" /> <b>Help</b> - Reach out to our customer success team
                for help
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </DialogContent>
        <DialogActions>
          <SuiBox m={2}>
            <SuiButton variant="gradient" buttonColor="error" onClick={handleNext}>
              Got it, next please!
            </SuiButton>
          </SuiBox>
        </DialogActions>
      </Dialog>
      {/* Leads vs Opps Dialog */}
      <Dialog
        open={openLeadsVsOpps}
        keepMounted
        onClose={handleNext}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{ sx: { width: "70%", height: "auto" } }}
      >
        <DialogTitle>
          <SuiBox pt={3} textAlign="center">
            <SuiTypography variant="h2" textColor="info" fontWeight="bold">
              Leads vs. Opportunities
            </SuiTypography>
          </SuiBox>
        </DialogTitle>
        <DialogContent>
          <SuiBox p={3} textAlign="center">
            <Divider />
            <SuiBox mb={2}>
              <img
                src={LoanaLeadsVOpps}
                alt="loana leads vs opps"
                style={{ width: "100%", height: "auto" }}
              />
            </SuiBox>
            <Divider />
            <SuiBox m={2} textAlign="left">
              <SuiTypography variant="h6" textColor="text">
                Leads and opportunities are the foundation of Loana. It&apos;s important that you
                know the difference between the two when using Loana.
              </SuiTypography>
            </SuiBox>
            <SuiBox m={2} textAlign="left">
              <SuiTypography variant="body2" textColor="text">
                <span style={{ color: "#17c1e8" }}>Leads</span> are your potential customers. When
                you create a lead, you are inputting and storing essential information about that
                individual.
              </SuiTypography>
            </SuiBox>
            <SuiBox m={2} textAlign="left">
              <SuiTypography variant="body2" textColor="text">
                <span style={{ color: "#17c1e8" }}>Opportunites</span> are created from leads.
                Multiple leads can be attached to an opportunity. They are used to track each
                customer&apos;s journey through your pipeline.
              </SuiTypography>
            </SuiBox>
            <SuiBox m={2} textAlign="left">
              <SuiTypography variant="body2" textColor="warning" fontWeight="bold">
                Scenario
              </SuiTypography>
              <Divider />
              <SuiTypography variant="body2" textColor="text">
                You just received a new lead from a realtor. Woohoo! Now what? You give them a call
                and while you are qualifying them, you create a new Loana lead. Okay great they are
                pre-qualified, what&apos;s next? Easy, create a new Loana opportunity from the lead
                you just created. Now you can simply track the opportunity all the way through your
                pipeline until you close the deal. Boom!
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </DialogContent>
        <DialogActions>
          <SuiBox m={2}>
            <SuiButton variant="gradient" buttonColor="error" onClick={handleNext}>
              On to the next one!
            </SuiButton>
          </SuiBox>
        </DialogActions>
      </Dialog>
      {/* Tasks */}
      <Dialog
        open={openFinishDialog}
        keepMounted
        onClose={handleNext}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{ sx: { width: "70%", height: "auto" } }}
      >
        <DialogTitle>
          <SuiBox pt={3} textAlign="center">
            <SuiTypography variant="h4" textColor="info" fontWeight="bold">
              That&apos;s it for now!
            </SuiTypography>
          </SuiBox>
        </DialogTitle>
        <DialogContent>
          <SuiBox p={3} textAlign="center">
            <Divider />
            <SuiBox m={2} textAlign="left">
              <SuiTypography variant="h6" textColor="text">
                Awesome, we&apos;ve covered the absolute basics! Head over to <b>Tasks</b> to
                complete your Getting Started Tasks.
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </DialogContent>
        <DialogActions>
          <SuiBox m={2}>
            <SuiButton variant="gradient" buttonColor="error" onClick={handleNext}>
              Finish
            </SuiButton>
          </SuiBox>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LoanaWalkthrough;
