// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material UI
import { Divider, Grid, Stack } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function RefinanceHeader(props) {
  const { onShowChange, showVariable } = props;

  const { values } = breakpoints;

  return (
    <Grid
      container
      spacing={window.innerWidth > values.md ? 3 : 4}
      justifyContent="space-between"
      alignItems="flex-end"
    >
      <Grid item xs={12} md={5} alignSelf="flex-start">
        <SuiTypography variant="h3">Refinance</SuiTypography>
      </Grid>
      <Grid item xs={12} md={2}>
        <Stack
          spacing={2}
          direction={window.innerWidth > values.md ? "column" : "row"}
          justifyContent="space-around"
          alignItems="flex-start"
        >
          <SuiTypography
            textColor={showVariable ? "info" : "dark"}
            component="label"
            variant="caption"
            fontWeight="bold"
            onClick={onShowChange}
          >
            Show All Refinance
          </SuiTypography>
          <SuiTypography
            textColor={!showVariable ? "info" : "dark"}
            component="label"
            variant="caption"
            fontWeight="bold"
            onClick={onShowChange}
          >
            Show My Refinance
          </SuiTypography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}

RefinanceHeader.propTypes = {
  onShowChange: PropTypes.func.isRequired,
  showVariable: PropTypes.bool.isRequired,
};

export default RefinanceHeader;
