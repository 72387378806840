import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { UI_ERROR } from "redux/types";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";
import MuiLoader from "components/MuiLoader";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Separator from "layouts/authentication/components/Separator";

// Images
import curvedYellow from "assets/images/curved-images/curved-yellow.jpg";
import { signUpWithEmailAndPassword, signUpWithGoogle } from "redux/actions/userActions";

function Signup() {
  const loading = useSelector((state) => state.ui.loading);
  const inputError = useSelector((state) => state.ui.error);
  const [openError, setOpenError] = useState(false);
  const [agreement, setAgreement] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const handleSetAgremment = () => {
    if (agreement) setAgreement(false);
    if (!agreement) setAgreement(true);
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((values) => ({ ...values, [event.target.name]: event.target.value }));
  };

  const validateInputs = () => {
    let validated = true;
    if (inputs.email === "" || inputs.password === "") {
      validated = false;
      dispatch({ type: UI_ERROR, payload: "Please enter a valid email and password." });
    }
    return validated;
  };

  const handleUserEmailPass = () => {
    const validated = validateInputs();
    if (validated) {
      dispatch(signUpWithEmailAndPassword(inputs, history));
    }
  };

  const handleUserGoogleProvider = () => {
    dispatch(signUpWithGoogle(history));
  };

  useEffect(() => {
    if (!agreement) setDisableSubmit(true);
    if (agreement) setDisableSubmit(false);
  }, [agreement]);

  useEffect(() => {
    if (inputError !== "") {
      setOpenError(true);
    }
  }, [inputError]);

  return (
    <>
      <MuiLoader loading={loading} />
      <CoverLayout
        title="Join us today, it's free!"
        description="Create an account with Google or an email/password"
        image={curvedYellow}
        top={12}
      >
        {openError ? (
          <SuiAlert color="warning">
            <SuiTypography variant="body2" textColor="white">
              <SuiTypography
                component="a"
                href="#"
                variant="body2"
                fontWeight="medium"
                textColor="white"
              >
                Oops!&nbsp;
              </SuiTypography>
              {inputError}
            </SuiTypography>
          </SuiAlert>
        ) : null}
        <SuiBox display="flex" justifyContent="left">
          <SuiButton variant="outlined" buttonColor="light" onClick={handleUserGoogleProvider}>
            <svg width="24px" height="32px" viewBox="0 0 64 64" version="1.1">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(3.000000, 2.000000)" fillRule="nonzero">
                  <path
                    d="M57.8123233,30.1515267 C57.8123233,27.7263183 57.6155321,25.9565533 57.1896408,24.1212666 L29.4960833,24.1212666 L29.4960833,35.0674653 L45.7515771,35.0674653 C45.4239683,37.7877475 43.6542033,41.8844383 39.7213169,44.6372555 L39.6661883,45.0037254 L48.4223791,51.7870338 L49.0290201,51.8475849 C54.6004021,46.7020943 57.8123233,39.1313952 57.8123233,30.1515267"
                    fill="#4285F4"
                  />
                  <path
                    d="M29.4960833,58.9921667 C37.4599129,58.9921667 44.1456164,56.3701671 49.0290201,51.8475849 L39.7213169,44.6372555 C37.2305867,46.3742596 33.887622,47.5868638 29.4960833,47.5868638 C21.6960582,47.5868638 15.0758763,42.4415991 12.7159637,35.3297782 L12.3700541,35.3591501 L3.26524241,42.4054492 L3.14617358,42.736447 C7.9965904,52.3717589 17.959737,58.9921667 29.4960833,58.9921667"
                    fill="#34A853"
                  />
                  <path
                    d="M12.7159637,35.3297782 C12.0932812,33.4944915 11.7329116,31.5279353 11.7329116,29.4960833 C11.7329116,27.4640054 12.0932812,25.4976752 12.6832029,23.6623884 L12.6667095,23.2715173 L3.44779955,16.1120237 L3.14617358,16.2554937 C1.14708246,20.2539019 0,24.7439491 0,29.4960833 C0,34.2482175 1.14708246,38.7380388 3.14617358,42.736447 L12.7159637,35.3297782"
                    fill="#FBBC05"
                  />
                  <path
                    d="M29.4960833,11.4050769 C35.0347044,11.4050769 38.7707997,13.7975244 40.9011602,15.7968415 L49.2255853,7.66898166 C44.1130815,2.91684746 37.4599129,0 29.4960833,0 C17.959737,0 7.9965904,6.62018183 3.14617358,16.2554937 L12.6832029,23.6623884 C15.0758763,16.5505675 21.6960582,11.4050769 29.4960833,11.4050769"
                    fill="#EB4335"
                  />
                </g>
              </g>
            </svg>
          </SuiButton>
        </SuiBox>
        <Separator />
        <SuiBox component="form" role="form">
          <SuiBox mb={2} lineHeight={1.25}>
            <SuiBox mb={1} ml={0.5}>
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                Email
              </SuiTypography>
            </SuiBox>
            <SuiInput type="email" placeholder="Email" name="email" onChange={handleInputChange} />
          </SuiBox>
          <SuiBox mb={2} lineHeight={1.25}>
            <SuiBox mb={1} ml={0.5}>
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                Password
              </SuiTypography>
            </SuiBox>
            <SuiInput
              type="password"
              placeholder="Password"
              name="password"
              onChange={handleInputChange}
            />
          </SuiBox>
          <SuiBox display="flex" alignItems="center">
            <Checkbox checked={agreement} onChange={handleSetAgremment} />
            <SuiTypography
              variant="button"
              fontWeight="regular"
              onClick={handleSetAgremment}
              customClass="cursor-pointer user-select-none"
            >
              &nbsp;&nbsp;I agree to the&nbsp;
            </SuiTypography>
            <SuiTypography
              component="a"
              href="http://localhost:3000/terms"
              variant="button"
              fontWeight="bold"
              textGradient
            >
              Terms of Service
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={4} mb={1}>
            <SuiButton
              variant="gradient"
              buttonColor="error"
              fullWidth
              onClick={handleUserEmailPass}
              disabled={disableSubmit}
            >
              sign up
            </SuiButton>
          </SuiBox>
          <SuiBox mt={3} textAlign="center">
            <SuiTypography variant="button" textColor="text" fontWeight="regular">
              Already have an account?&nbsp;
              <SuiTypography
                component={Link}
                to="/authentication/sign-in"
                variant="button"
                textColor="error"
                fontWeight="medium"
                textGradient
              >
                Sign In
              </SuiTypography>
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </CoverLayout>
    </>
  );
}

export default Signup;
