import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";
import { Divider } from "@mui/material";

function AdministrationLayout({ children, onTabChange, loading, bypass }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    onTabChange(newValue);
    setTabValue(newValue);
  };

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiTypography variant="h4">Company Administration</SuiTypography>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={8} lg={4}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab label="Users" />
                <Tab label="Templates" />
                <Tab label="Billing and Subscription" />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </SuiBox>
    </DashboardLayout>
  );
}

// Typechecking props for BaseLayout
AdministrationLayout.propTypes = {
  children: PropTypes.node.isRequired,
  onTabChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  bypass: PropTypes.bool.isRequired,
};

export default AdministrationLayout;
