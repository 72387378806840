// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Card, CardContent, CardHeader, Divider, Grid, Stack, Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import FormField from "layouts/profile/components/FormField";

function FollowupTemplateAdministrationCard(props) {
  const { currentTemplate, onChange, onSubmit } = props;

  return (
    <Card style={{ paddingTop: 5, paddingRight: 5, paddingLeft: 5, paddingBottom: 10 }}>
      <CardHeader
        title={
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <SuiTypography variant="h5" fontWeight="bold">
              Follow Up Template
            </SuiTypography>
            <Tooltip title="The following inputs determine the length of time our Follow Up automations will wait before they are triggered.">
              <HelpIcon color="#8392ab" />
            </Tooltip>
          </Stack>
        }
      />
      <CardContent>
        <Grid container spacing={2} justifyContent="center" alignItems="flex-end">
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                style={{ paddingLeft: 1, paddingTop: 3 }}
              >
                <SuiTypography variant="h6" fontWeight="medium" textColor="info">
                  Pipeline
                </SuiTypography>
                <Tooltip title="Determines the number of days before a Follow Up will be created for opportunities within your Pipeline. For example, if Application In is set to 1 day, then a Follow Up will be created 1 day after you last contacted any opportunity with an Application In status.">
                  <HelpIcon color="#8392ab" />
                </Tooltip>
                <Divider />
              </Stack>
              <Grid item xs={12}>
                <FormField
                  type="number"
                  name="new"
                  value={currentTemplate.new}
                  label="New"
                  placeholder=""
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type="number"
                  name="pq"
                  value={currentTemplate.pq}
                  label="Pre-qualified"
                  placeholder=""
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type="number"
                  name="ai"
                  value={currentTemplate.ai}
                  label="Application In"
                  placeholder=""
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type="number"
                  name="docs"
                  value={currentTemplate.docs}
                  label="Waiting on Documents"
                  placeholder=""
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type="number"
                  name="pa"
                  value={currentTemplate.pa}
                  label="Pre-approved"
                  placeholder=""
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type="number"
                  name="uc"
                  value={currentTemplate.uc}
                  label="Under Contract"
                  placeholder=""
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type="number"
                  name="les"
                  value={currentTemplate.les}
                  label="Loan Estimate Sent"
                  placeholder=""
                  onChange={onChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                style={{ paddingLeft: 1, paddingTop: 10 }}
              >
                <SuiTypography variant="h6" fontWeight="medium" textColor="info">
                  Credit Repair
                </SuiTypography>
                <Tooltip title="Determines the number of days before a Follow Up will be created for opportunities that have been tagged for Credit Repair Needed.">
                  <HelpIcon color="#8392ab" />
                </Tooltip>
                <Divider />
              </Stack>
              <Grid item xs={12}>
                <FormField
                  type="number"
                  name="creditRepair"
                  value={currentTemplate.creditRepair}
                  label="Credit Repair Needed"
                  placeholder=""
                  onChange={onChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                style={{ paddingLeft: 1, paddingTop: 10 }}
              >
                <SuiTypography variant="h6" fontWeight="medium" textColor="info">
                  No Response
                </SuiTypography>
                <Tooltip title="Determines the number of days before a Follow Up will be created for opportunities that have been tagged as No Response. When this tag is added to an opportunity, Loana will automatically create a task to remind you to reach out to the referral source.">
                  <HelpIcon color="#8392ab" />
                </Tooltip>
                <Divider />
              </Stack>
              <Grid item xs={12}>
                <FormField
                  type="number"
                  name="noResponse"
                  value={currentTemplate.noResponse}
                  label="No Response"
                  placeholder=""
                  onChange={onChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} align="end" style={{ marginTop: 10 }}>
            <SuiButton variant="gradient" buttonColor="error" size="medium" onClick={onSubmit}>
              Save Changes
            </SuiButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

FollowupTemplateAdministrationCard.propTypes = {
  onChange: PropTypes.func.isRequired,
  currentTemplate: PropTypes.shape({
    ai: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    creditRepair: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    docs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    les: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    new: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    noResponse: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pa: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pq: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    uc: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FollowupTemplateAdministrationCard;
