// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Dialog, DialogActions, DialogTitle, DialogContent } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";

function NotesForm(props) {
  const { newNote, open, onClose, onClick, onChange } = props;

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Add New Note</DialogTitle>
      <DialogContent>
        <SuiBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
          mb={2}
          mt={2}
        >
          <SuiInput multiline fullWidth value={newNote} onChange={onChange} />
        </SuiBox>
      </DialogContent>
      <DialogActions>
        <SuiButton variant="outlined" buttonColor="error" size="small" onClick={onClose}>
          Cancel
        </SuiButton>
        <SuiButton variant="gradient" buttonColor="error" size="small" onClick={onClick}>
          Add Note
        </SuiButton>
      </DialogActions>
    </Dialog>
  );
}

NotesForm.propTypes = {
  newNote: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NotesForm;
