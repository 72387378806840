import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UI_ERROR } from "redux/types";
import { apiPostWithReturn } from "redux/actions/dataActions";
import { useHistory, useLocation, useParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import { Divider } from "material-ui";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Custom Components
import BasicsPartnerForm from "../add-partner/components/BasicsPartnerForm";
import PartnerFormActions from "../add-partner/components/PartnerFormActions";

function EditPartner() {
  const { values } = breakpoints;
  const bypass = true;

  const partnersAll = useSelector((state) => state.data.partners);
  const loading = useSelector((state) => state.ui.loading);
  const inputError = useSelector((state) => state.ui.error);

  const [openError, setOpenError] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { partnerId } = useParams();

  // User and Data Redux Objects
  const user = useSelector((state) => state.user);

  // Gets current partner from redux data
  const currentPartner = partnersAll.filter((partner) => partner.partnerId === partnerId);

  // User Inputs for new partner
  const [basicInputs, setBasicInputs] = useState(currentPartner[0].basic);

  // onChange handlers for user inputs
  const handleInputsChange = (event) => {
    event.persist();
    setBasicInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  // User input validator
  const validateInputs = () => {
    let valid = true;
    if (basicInputs.name === "") {
      valid = false;
      dispatch({
        type: UI_ERROR,
        payload: "Please enter at least a name for the new partner.",
      });
    }
    return valid;
  };

  // Handles user submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    const submitData = {
      partner: {
        basic: {
          ...basicInputs,
        },
        partnerId: currentPartner[0].partnerId,
        partnerOwner: currentPartner[0].partnerOwner,
      },
      companyId: user.company.companyId,
    };
    const valid = validateInputs();
    if (valid) {
      dispatch(apiPostWithReturn("/partner/edit", submitData))
        .then((res) => {
          if (res.status === 200) {
            if (location.state.redirectToOpps) {
              history.push(location.state.returnURL);
            } else {
              history.push("/pipeline/partners");
            }
          }
        })
        .catch((error) => {
          dispatch({
            type: UI_ERROR,
            payload: `Something went wrong. Please contact support@loana.freshdesk.com for help or try again. Error: ${error}`,
          });
        });
    }
  };

  // Opens error alert
  useEffect(() => {
    if (inputError !== "") setOpenError(true);
  }, [inputError]);

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiBox lineHeight={1} pl={3}>
              <SuiTypography
                textTransform="capitalize"
                fontWeight="bold"
                variant={window.innerWidth < values.sm ? "h5" : "h4"}
              >
                Edit Partner
              </SuiTypography>
            </SuiBox>
            <Divider />
          </Grid>
          {openError ? (
            <Grid item xs={12}>
              <SuiAlert color="warning">
                <SuiTypography variant="body2" textColor="white">
                  <SuiTypography
                    component="a"
                    href="#"
                    variant="body2"
                    fontWeight="medium"
                    textColor="white"
                  >
                    Oops!&nbsp;
                  </SuiTypography>
                  {inputError}
                </SuiTypography>
              </SuiAlert>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={9}>
            <BasicsPartnerForm inputs={basicInputs} onChange={handleInputsChange} />
          </Grid>

          <Grid item xs={12} sm={3}>
            <PartnerFormActions onSubmit={handleSubmit} />
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
}

export default EditPartner;
