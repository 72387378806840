import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "redux/actions/userActions";
import { UI_ERROR_CLEAR } from "redux/types";
import { useHistory } from "react-router-dom";

// Day JS
import dayjs from "dayjs";

// @mui material components
import { Skeleton, Grid, Icon } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import MiniGradientLineChart from "examples/Charts/LineCharts/MiniGradientLineChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
import breakpoints from "assets/theme/base/breakpoints";

// Custom Components
import LoanaBreakdown from "./components/LoanaBreakdown";

function Mortgage() {
  const { values } = breakpoints;
  const { size } = typography;
  const bypass = false;

  const dispatch = useDispatch();
  const history = useHistory();

  const loading = useSelector((state) => state.ui.loading);
  const isDataReady = useSelector((state) => state.data.isDataReady);
  const oppsAll = useSelector((state) => state.data.opps);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!isDataReady) {
      dispatch(getUserProfile());
    }
  }, []);

  useEffect(() => {
    if (user && !user.isEmpty) {
      if (!user.profile.profileComplete) {
        history.push("/profile/incomplete");
      }
    }
  }, [user]);

  /* -------------------- ANALYTICS FLAGS ------------------- */
  // Sets flags to trigger the analytics components to show vs the skeletons
  const [companyAnalyticsReady, setCompanyAnalyticsReady] = useState(false);
  const [breakdownAnalyticsReady, setBreakdownAnalyticsReady] = useState(false);
  const [personalAnalyticsReady, setPersonalAnalyticsReady] = useState(false);
  const [analyticsReady, setAnalyticsReady] = useState(false);

  /* -------------------- ANALYTICS BREAKDOWN ------------------- */
  const [breakdown, setBreakdown] = useState([
    {
      color: "success",
      icon: "arrow_upward",
      name: "User",
      description: "07 June 2021, at 09:00 AM",
      value: "+ $ 000,000",
    },
  ]);

  const calculateBreakdown = () => {
    const tempBreakdown = [];
    const currentYear = new Date().getFullYear();

    if (oppsAll) {
      if (oppsAll.closed && oppsAll.closed.length !== 0) {
        oppsAll.closed.forEach((opp) => {
          const year = new Date(opp.closedAt).getFullYear();
          if (year === currentYear) {
            let ownerName = "User";
            if (user.company.team && user.company.team.length !== 0) {
              user.company.team.forEach((member) => {
                if (member.uid === opp.oppOwner) {
                  ownerName = `${member.firstName} ${member.lastName}`;
                  return ownerName;
                }
                return "User";
              });
              tempBreakdown.push({
                color: "success",
                icon: "arrow_upward",
                name: ownerName,
                description: dayjs(opp.closedAt).format("MMM DD YYYY"),
                value: `+ $${opp.closedAmount.toLocaleString()}`,
              });
            }
          }
        });
      }
      setBreakdown(tempBreakdown);
      setBreakdownAnalyticsReady(true);
    }
  };

  /* -------------------- ANALYTICS COMPANY REVENUE ANNUAL ------------------- */
  const [companyAnnualRevenue, setCompanyAnnualRevenue] = useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Revenue",
        color: "info",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    customTick: " ",
  });

  const [pipelineOverview, setPipelineOverview] = useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Current Year",
        color: "info",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        label: "Previous Year",
        color: "dark",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  });

  const [companyTotalAnnualRevenue, setCompanyTotalAnnualRevenue] = useState(0);
  const [companyTotalMonthlyRevenue, setCompanyTotalMonthlyRevenue] = useState(0);
  const [thisMonthsOpps, setThisMonthsOpps] = useState(0);
  const [todaysOpps, setTodaysOpps] = useState(0);
  const [lastMonthsOpps, setLastMonthsOpps] = useState(0);
  const [newToClosed, setNewToClosed] = useState(0);

  const getCount = (obj) => {
    let count = 0;
    Object.keys(obj).forEach((item) => {
      count += obj[item].length;
    });
    return count;
  };

  const calculateCompanyAndPipelineAnalytics = () => {
    const revenue = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const pipelineCurrentYear = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const pipelineLastYear = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    const oppCount = getCount(oppsAll);
    const closedCount = oppsAll.closed.length;

    let totalRevenue = 0;
    let countMonth = 0;
    let countToday = 0;
    let countLastMonth = 0;

    if (oppsAll) {
      Object.keys(oppsAll).forEach((status) => {
        if (oppsAll[status].length !== 0) {
          oppsAll[status].forEach((opp) => {
            // Sets opp createdAt year and month
            const year = new Date(opp.createdAt).getFullYear();
            const month = new Date(opp.createdAt).getMonth();
            const yearClosed = new Date(opp.closedAt).getFullYear();
            const monthClosed = new Date(opp.closedAt).getMonth();

            // Adds to current year's month counter if current year
            if (year === currentYear) {
              pipelineCurrentYear[month] += 1;
            }

            // Adds to previous year's month counter if last year
            if (
              dayjs(dayjs(new Date().toISOString()).subtract(1, "year")).isSame(
                opp.createdAt,
                "year"
              )
            ) {
              pipelineLastYear[month] += 1;
            }

            // Adds to this month's counter
            if (currentMonth === month) {
              countMonth += 1;
            }

            // Adds to last month's counter
            if (
              dayjs(dayjs(new Date().toISOString()).subtract(1, "month")).isSame(
                opp.createdAt,
                "month"
              )
            ) {
              countLastMonth += 1;
            }

            // Adds to today's counter
            if (dayjs(opp.createdAt).isSame(new Date().toISOString(), "day")) {
              countToday += 1;
            }

            if (yearClosed === currentYear && opp.status === "closed") {
              revenue[monthClosed] += opp.closedAmount;
              totalRevenue += opp.closedAmount;
            }
          });
        }
      });
      setCompanyAnnualRevenue({
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Revenue",
            color: "info",
            data: revenue,
          },
        ],
        customTick: " ",
      });
      setCompanyTotalAnnualRevenue(totalRevenue);
      setCompanyTotalMonthlyRevenue(revenue[currentMonth]);
      setThisMonthsOpps(countMonth);
      setTodaysOpps(countToday);
      setLastMonthsOpps(countLastMonth);
      if (oppCount > 0 && closedCount > 0) {
        setNewToClosed((closedCount / oppCount) * 100);
      }
      setPipelineOverview({
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Current Year",
            color: "info",
            data: pipelineCurrentYear,
          },
          {
            label: "Previous Year",
            color: "dark",
            data: pipelineLastYear,
          },
        ],
      });
      setCompanyAnalyticsReady(true);
    }
  };

  /* -------------------- ANALYTICS PERSONAL REVENUE ANNUAL ------------------- */
  const [personalAnnualRevenue, setPersonalAnnualRevenue] = useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Revenue",
        color: "info",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    customTick: " ",
  });
  const [personalTotalAnnualRevenue, setPersonalTotalAnnualRevenue] = useState(0);
  const [personalTotalMonthlyRevenue, setPersonalTotalMonthlyRevenue] = useState(0);

  const calculatePersonalRevenue = () => {
    const personalRevenue = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    let totalPersonalRevenue = 0;

    if (oppsAll) {
      Object.keys(oppsAll).forEach((status) => {
        if (oppsAll[status].length !== 0) {
          oppsAll[status].forEach((opp) => {
            const year = new Date(opp.closedAt).getFullYear();
            const month = new Date(opp.closedAt).getMonth();
            if (
              year === currentYear &&
              opp.status === "closed" &&
              opp.oppOwner === user.profile.uid
            ) {
              personalRevenue[month] += opp.closedAmount;
              totalPersonalRevenue += opp.closedAmount;
            }
          });
        }
      });
      setPersonalAnnualRevenue({
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Revenue",
            color: "info",
            data: personalRevenue,
          },
        ],
        customTick: " ",
      });
      setPersonalTotalAnnualRevenue(totalPersonalRevenue);
      setPersonalTotalMonthlyRevenue(personalRevenue[currentMonth]);
      setPersonalAnalyticsReady(true);
    }
  };

  useEffect(() => {
    if (isDataReady) {
      calculateBreakdown();
      calculateCompanyAndPipelineAnalytics();
      calculatePersonalRevenue();
    }
  }, [isDataReady]);

  useEffect(() => {
    if (companyAnalyticsReady && breakdownAnalyticsReady && personalAnalyticsReady) {
      setAnalyticsReady(true);
    }
  }, [companyAnalyticsReady, breakdownAnalyticsReady, personalAnalyticsReady]);

  useEffect(() => {
    dispatch({ type: UI_ERROR_CLEAR });
  }, []);

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SuiBox mb={3} p={1}>
                <SuiTypography
                  variant={window.innerWidth > values.md ? "h3" : "h5"}
                  textTransform="capitalize"
                  fontWeight="bold"
                >
                  closed opportunities
                </SuiTypography>
              </SuiBox>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  {analyticsReady ? (
                    <MiniGradientLineChart
                      title="Company (Annual)"
                      description={
                        <SuiTypography variant="h5" fontWeight="bold" textColor="success">
                          ${companyTotalAnnualRevenue.toLocaleString()}{" "}
                        </SuiTypography>
                      }
                      chart={companyAnnualRevenue}
                    />
                  ) : (
                    <Skeleton animation="wave" width="100%">
                      <MiniGradientLineChart
                        title="Company (Annual)"
                        description={
                          <SuiTypography variant="h5" fontWeight="bold" textColor="success">
                            ${companyTotalAnnualRevenue.toLocaleString()}{" "}
                          </SuiTypography>
                        }
                        chart={companyAnnualRevenue}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  {analyticsReady ? (
                    <DefaultCounterCard
                      count={companyTotalMonthlyRevenue}
                      prefix="$"
                      suffix=""
                      title="Monthly Total (Company)"
                      description=""
                    />
                  ) : (
                    <Skeleton animation="wave" width="100%">
                      <DefaultCounterCard
                        count={companyTotalMonthlyRevenue}
                        prefix="$"
                        suffix=""
                        title="Monthly Total (Company)"
                        description=""
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item xs={12} sm={8}>
                  {analyticsReady ? (
                    <MiniGradientLineChart
                      title="Personal (Annual)"
                      description={
                        <SuiTypography variant="h5" fontWeight="bold" textColor="success">
                          ${personalTotalAnnualRevenue.toLocaleString()}{" "}
                        </SuiTypography>
                      }
                      chart={personalAnnualRevenue}
                    />
                  ) : (
                    <Skeleton animation="wave" width="100%">
                      <MiniGradientLineChart
                        title="Personal (Annual)"
                        description={
                          <SuiTypography variant="h5" fontWeight="bold" textColor="success">
                            ${personalTotalAnnualRevenue.toLocaleString()}{" "}
                          </SuiTypography>
                        }
                        chart={personalAnnualRevenue}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  {analyticsReady ? (
                    <DefaultCounterCard
                      count={personalTotalMonthlyRevenue}
                      prefix="$"
                      suffix=""
                      title="Monthly Total (Personal)"
                      description=""
                    />
                  ) : (
                    <Skeleton animation="wave" width="100%">
                      <DefaultCounterCard
                        count={personalTotalMonthlyRevenue}
                        prefix="$"
                        suffix=""
                        title="Monthly Total (Personal)"
                        description=""
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item xs={12} sm={8}>
                  {analyticsReady ? (
                    <LoanaBreakdown
                      title="breakdown"
                      date={new Date().getFullYear().toString()}
                      rankings={breakdown}
                    />
                  ) : (
                    <Skeleton animation="wave" width="100%">
                      <LoanaBreakdown
                        title="breakdown"
                        date={new Date().getFullYear().toString()}
                        rankings={breakdown}
                      />
                    </Skeleton>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  {analyticsReady ? (
                    <DefaultCounterCard
                      count={newToClosed}
                      suffix="%"
                      title="close rate"
                      description=""
                    />
                  ) : (
                    <Skeleton animation="wave" width="100%">
                      <DefaultCounterCard
                        count={newToClosed}
                        suffix="%"
                        title="close rate"
                        description=""
                      />
                    </Skeleton>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SuiBox mb={3} p={1}>
                <SuiTypography
                  variant={window.innerWidth > values.md ? "h3" : "h5"}
                  textTransform="capitalize"
                  fontWeight="bold"
                >
                  pipeline
                </SuiTypography>
              </SuiBox>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <SuiBox mb={3}>
                        {analyticsReady ? (
                          <MiniStatisticsCard
                            title={{ text: "today's opportunities" }}
                            count={todaysOpps}
                            percentage={{ color: "success", text: "" }}
                            icon={{ color: "info", component: "people" }}
                          />
                        ) : (
                          <Skeleton animation="wave" width="100%">
                            <MiniStatisticsCard
                              title={{ text: "today's opportunities" }}
                              count={todaysOpps}
                              percentage={{ color: "success", text: "" }}
                              icon={{ color: "info", component: "people" }}
                            />
                          </Skeleton>
                        )}
                      </SuiBox>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <SuiBox mb={3}>
                        {analyticsReady ? (
                          <MiniStatisticsCard
                            title={{ text: "this month's opportunities" }}
                            count={thisMonthsOpps}
                            percentage={{ color: "success", text: "" }}
                            icon={{ color: "info", component: "people" }}
                          />
                        ) : (
                          <Skeleton animation="wave" width="100%">
                            <MiniStatisticsCard
                              title={{ text: "this month's opportunities" }}
                              count={thisMonthsOpps}
                              percentage={{ color: "success", text: "" }}
                              icon={{ color: "info", component: "people" }}
                            />
                          </Skeleton>
                        )}
                      </SuiBox>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <SuiBox mb={3}>
                        {analyticsReady ? (
                          <MiniStatisticsCard
                            title={{ text: "last month's opportunities" }}
                            count={lastMonthsOpps}
                            percentage={{ color: "success", text: "" }}
                            icon={{ color: "info", component: "people" }}
                          />
                        ) : (
                          <Skeleton animation="wave" width="100%">
                            <MiniStatisticsCard
                              title={{ text: "last month's opportunities" }}
                              count={lastMonthsOpps}
                              percentage={{ color: "success", text: "" }}
                              icon={{ color: "info", component: "people" }}
                            />
                          </Skeleton>
                        )}
                      </SuiBox>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      {analyticsReady ? (
                        <GradientLineChart
                          title="Pipeline Overview"
                          description={
                            <SuiBox display="flex" alignItems="center">
                              <SuiBox
                                fontSize={size.lg}
                                color="success"
                                mb={0.3}
                                mr={0.5}
                                lineHeight={0}
                              >
                                <Icon className=" font-bold">arrow_upward</Icon>
                              </SuiBox>
                              <SuiTypography variant="button" textColor="text" fontWeight="medium">
                                4% more{" "}
                                <SuiTypography
                                  variant="button"
                                  textColor="text"
                                  fontWeight="regular"
                                >
                                  in 2021
                                </SuiTypography>
                              </SuiTypography>
                            </SuiBox>
                          }
                          chart={pipelineOverview}
                        />
                      ) : (
                        <Skeleton animation="wave" width="100%">
                          <GradientLineChart
                            title="Pipeline Overview"
                            description={
                              <SuiBox display="flex" alignItems="center">
                                <SuiBox
                                  fontSize={size.lg}
                                  color="success"
                                  mb={0.3}
                                  mr={0.5}
                                  lineHeight={0}
                                >
                                  <Icon className=" font-bold">arrow_upward</Icon>
                                </SuiBox>
                                <SuiTypography
                                  variant="button"
                                  textColor="text"
                                  fontWeight="medium"
                                >
                                  4% more{" "}
                                  <SuiTypography
                                    variant="button"
                                    textColor="text"
                                    fontWeight="regular"
                                  >
                                    in 2021
                                  </SuiTypography>
                                </SuiTypography>
                              </SuiBox>
                            }
                            chart={pipelineOverview}
                          />
                        </Skeleton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
}

export default Mortgage;
