import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiPostWithReturn, apiGetWithReturn } from "redux/actions/dataActions";
import { SET_PROFILE, UI_ERROR, UI_ERROR_CLEAR } from "redux/types";

// @mui material components
import { Grid, Card, CardContent, Tooltip, IconButton, Divider } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

// Soft UI Dashboard PRO React theme styles
import breakpoints from "assets/theme/base/breakpoints";
import EmailTemplateEditor from "../components/EmailTemplateEditor";

function RefinanceAutomation() {
  const bypass = true;
  const { values } = breakpoints;

  const dispatch = useDispatch();

  // Opens UI Error
  const [openError, setOpenError] = useState(false);

  // User, Data, UI Redux Objects
  const loading = useSelector((state) => state.ui.loading);
  const user = useSelector((state) => state.user);
  const inputError = useSelector((state) => state.ui.error);

  // Template states
  const [templates, setTemplates] = useState(user.profile.refinanceTemplates);
  const [stage, setStage] = useState("opener");
  const [currentTemplate, setCurrentTemplate] = useState("");
  const [currentTemplateSubject, setCurrentTemplateSubject] = useState("");
  const [showEditTemplate, setShowEditTemplate] = useState(false);

  // Opens email editor
  const handleEditOpen = (event, template) => {
    event.preventDefault();
    dispatch({ type: UI_ERROR_CLEAR });
    setCurrentTemplate(templates[template].message);
    setCurrentTemplateSubject(templates[template].subject);
    setStage(template);
    setShowEditTemplate(true);
  };

  // Handles user input of email template
  const handleTemplateChange = (content) => setCurrentTemplate(content);

  // Handles user input of email subject
  const handleTemplateSubjectChange = (event) => {
    event.persist();
    setCurrentTemplateSubject(event.target.value);
  };

  // Handles submit action
  const handleSubmit = (event) => {
    event.preventDefault();
    const submitData = {
      ...templates,
      [stage]: {
        subject: currentTemplateSubject,
        message: currentTemplate,
      },
    };
    if (currentTemplate !== "" || currentTemplateSubject !== "") {
      dispatch(
        apiPostWithReturn("/automation/template/edit", {
          templateType: "refinance",
          templates: submitData,
        })
      ).then((res) => {
        if (res.status === 200) {
          dispatch(apiGetWithReturn("/user/profile")).then((result) => {
            dispatch({ type: SET_PROFILE, payload: result.data.message });
            setShowEditTemplate(false);
            setCurrentTemplate("");
            setCurrentTemplateSubject("");
            setStage("opener");
            dispatch({ type: UI_ERROR_CLEAR });
          });
        }
      });
    } else {
      dispatch({ type: UI_ERROR, payload: "Please enter a email subject and template below" });
    }
  };

  // Updates the templates when user redux object changes
  useEffect(() => {
    if (user && user.profile.refinanceTemplates) {
      setTemplates({ ...user.profile.refinanceTemplates });
    }
  }, [user]);

  useEffect(() => {
    if (inputError !== "") {
      setOpenError(true);
    }
  }, [inputError]);

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <Grid container spacing={3} mt={3}>
        <Grid item xs={12}>
          <SuiTypography variant={window.innerWidth > values.md ? "h3" : "h4"}>
            Refinance Journey
          </SuiTypography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card style={{ padding: 10 }}>
                <SuiBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
                  <SuiBox lineHeight={1}>
                    <SuiTypography
                      textTransform="capitalize"
                      fontWeight="medium"
                      variant={window.innerWidth > values.md ? "h4" : "h6"}
                    >
                      Journey Templates
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
                <CardContent>
                  <Grid container justifyContent="space-between" spacing={1}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={window.innerWidth > values.md ? 10 : 8}>
                          <SuiTypography component="label" variant="caption" fontWeight="bold">
                            Template Name
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={window.innerWidth > values.md ? 2 : 4}>
                          <SuiTypography component="label" variant="caption" fontWeight="bold">
                            Actions
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={window.innerWidth > values.md ? 10 : 8}>
                          <SuiTypography
                            variant={window.innerWidth > values.md ? "body2" : "caption"}
                            fontWeight="bold"
                            textColor="info"
                          >
                            Opener
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={window.innerWidth > values.md ? 2 : 4}>
                          <Tooltip title="Edit Template">
                            <IconButton
                              aria-label="edit"
                              color="error"
                              onClick={(e) => handleEditOpen(e, "opener")}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={window.innerWidth > values.md ? 10 : 8}>
                          <SuiTypography
                            variant={window.innerWidth > values.md ? "body2" : "caption"}
                            fontWeight="bold"
                            textColor="info"
                          >
                            Nudge
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={window.innerWidth > values.md ? 2 : 4}>
                          <Tooltip title="Edit Template">
                            <IconButton
                              aria-label="edit"
                              color="error"
                              onClick={(e) => handleEditOpen(e, "nudge")}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={window.innerWidth > values.md ? 10 : 8}>
                          <SuiTypography
                            variant={window.innerWidth > values.md ? "body2" : "caption"}
                            fontWeight="bold"
                            textColor="info"
                          >
                            Reminder One
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={window.innerWidth > values.md ? 2 : 4}>
                          <Tooltip title="Edit Template">
                            <IconButton
                              aria-label="edit"
                              color="error"
                              onClick={(e) => handleEditOpen(e, "reminderOne")}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={window.innerWidth > values.md ? 10 : 8}>
                          <SuiTypography
                            variant={window.innerWidth > values.md ? "body2" : "caption"}
                            fontWeight="bold"
                            textColor="info"
                          >
                            Reminder Two
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={window.innerWidth > values.md ? 2 : 4}>
                          <Tooltip title="Edit Template">
                            <IconButton
                              aria-label="edit"
                              color="error"
                              onClick={(e) => handleEditOpen(e, "reminderTwo")}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={window.innerWidth > values.md ? 10 : 8}>
                          <SuiTypography
                            variant={window.innerWidth > values.md ? "body2" : "caption"}
                            fontWeight="bold"
                            textColor="info"
                          >
                            Reminder Three
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={window.innerWidth > values.md ? 2 : 4}>
                          <Tooltip title="Edit Template">
                            <IconButton
                              aria-label="edit"
                              color="error"
                              onClick={(e) => handleEditOpen(e, "reminderThree")}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={window.innerWidth > values.md ? 10 : 8}>
                          <SuiTypography
                            variant={window.innerWidth > values.md ? "body2" : "caption"}
                            fontWeight="bold"
                            textColor="info"
                          >
                            Final Chance
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={window.innerWidth > values.md ? 2 : 4}>
                          <Tooltip title="Edit Template">
                            <IconButton
                              aria-label="edit"
                              color="error"
                              onClick={(e) => handleEditOpen(e, "finalChance")}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {showEditTemplate ? (
              <Grid item xs={12}>
                {openError ? (
                  <Grid item xs={12}>
                    <SuiAlert color="warning">
                      <SuiTypography variant="body2" textColor="white">
                        <SuiTypography
                          component="a"
                          href="#"
                          variant="body2"
                          fontWeight="medium"
                          textColor="white"
                        >
                          Oops!&nbsp;
                        </SuiTypography>
                        {inputError}
                      </SuiTypography>
                    </SuiAlert>
                  </Grid>
                ) : null}
                <EmailTemplateEditor
                  title="Edit Stage Template"
                  hint="Edit your custom email for the selected Refinance Journey stage"
                  message={currentTemplate}
                  onChange={handleTemplateChange}
                  onSubmit={handleSubmit}
                  showName
                  onNameChange={handleTemplateSubjectChange}
                  templateName={currentTemplateSubject}
                  label="Email Subject"
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ padding: 10 }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SuiTypography variant="h6" fontWeight="medium" textColor="info">
                    What is Loana&apos;s Refinance Journey?
                  </SuiTypography>
                </Grid>
                <Grid item xs={12}>
                  <SuiTypography variant="body2">
                    Imagine you just closed a loan for a new customer. Awesome! But now what? You
                    want that customer to come back to refinance with your company, but how in the
                    world are you supposed to keep track of when that customer is ready to
                    refinance, maintain a consistent message, and remember to contact them at
                    certain dates after their closing?
                  </SuiTypography>
                </Grid>
                <Grid item xs={12}>
                  <SuiTypography variant="body2">
                    No sweat, Loana has your back! With Loana&apos;s Refinance Journey, your closed
                    opportunities will automatically be placed into the Refinance pipeline.
                  </SuiTypography>
                </Grid>
                <Grid item xs={12}>
                  <SuiTypography variant="body2">
                    Next, Loana sends predetermined messages, that you create, to each of the leads
                    when they reach a certain point in the journey. This page is where you will
                    create those messages.
                  </SuiTypography>
                </Grid>
                <Grid item xs={12}>
                  <SuiTypography variant="body2">
                    When your customer says, &quot;Yes I&apos;m ready to refinance with you!&quot;,
                    you will visit the <b>Refinance</b> page on the left-hand menu, and simply
                    create a new opportunity by clicking the <b>Rocket Icon </b>
                    next to that specific customer. Just like magic, a new opportunity is added into
                    your pipeline on the Opportunites page!
                  </SuiTypography>
                </Grid>
                <Grid item xs={12}>
                  <SuiTypography variant="body2">
                    Here are the stages of the Loana Refinance Journey:
                  </SuiTypography>
                </Grid>
                <Grid item xs={12} style={{ marginLeft: 20 }}>
                  <SuiTypography component="span" variant="body2" fontWeight="medium">
                    <ul>
                      <li>Opener - 5 months after closing</li>
                      <li>Nudge - 6 months after closing</li>
                      <li>Reminder One - 9 months after closing</li>
                      <li>Reminder Two - 1 year after closing</li>
                      <li>Reminder Three - 1 year and 6 months after closing</li>
                      <li>Final Chance - 2 years after closing</li>
                    </ul>
                  </SuiTypography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default RefinanceAutomation;
