// Proptypes
import PropTypes from "prop-types";

// HTML React Parser
import parse from "html-react-parser";

// @mui material components
import { Card, CardContent, CardHeader, Grid, Stack, Tooltip, Divider } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function EmailTemplatePreview(props) {
  const { title, message, hint } = props;
  const { values } = breakpoints;

  return (
    <Card style={{ padding: window.innerWidth > values.md ? 10 : 5 }}>
      <CardHeader
        title={
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            style={{ paddingLeft: 1, paddingTop: 3 }}
          >
            <SuiTypography
              variant={window.innerWidth > values.md ? "h4" : "h6"}
              fontWeight="medium"
              textColor="dark"
            >
              {title}
            </SuiTypography>
            <Tooltip title={hint}>
              <HelpIcon color="#8392ab" />
            </Tooltip>
            <Divider />
          </Stack>
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          {message && message !== "" ? (
            <Grid item xs={12}>
              <SuiTypography
                variant={window.innerWidth > values.md ? "body2" : "caption"}
                textColor="text"
              >
                Customer Name,
              </SuiTypography>
            </Grid>
          ) : (
            <Grid item xs={12} align="center">
              <SuiTypography
                variant={window.innerWidth > values.md ? "body2" : "caption"}
                textColor="text"
              >
                Nothing to preview
              </SuiTypography>
            </Grid>
          )}
          <Grid item xs={12}>
            <SuiTypography
              variant={window.innerWidth > values.md ? "body2" : "caption"}
              textColor="text"
            >
              {parse(message)}
            </SuiTypography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

EmailTemplatePreview.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
};

export default EmailTemplatePreview;
