// @mui material components
import { Stack, Grid } from "@mui/material";

// Proptypes
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import FormField from "layouts/applications/wizard/components/FormField";

function HelpTicket(props) {
  const { onChange, onSelectChange, inputs } = props;

  const options = {
    type: [
      { value: "bug", label: "Problem / Bug" },
      { value: "question", label: "Question / Feedback" },
      { value: "feature", label: "Feature Request" },
    ],
    priority: [
      { value: "low", label: "No rush on my end" },
      { value: "high", label: "Please fix asap" },
      { value: "blocker", label: "I can't use Loana until this is fixed" },
    ],
    priorityFeature: [
      { value: "low", label: "No rush on my end" },
      { value: "high", label: "As soon as possible" },
      { value: "blocker", label: "Yesterday" },
    ],
    page: [
      { value: "home", label: "Home" },
      { value: "leads", label: "Leads" },
      { value: "opps", label: "Opportunities" },
      { value: "tasks", label: "Tasks" },
      { value: "followUps", label: "Follow-ups" },
      { value: "automations", label: "Automations" },
      { value: "administration", label: "Company Administration" },
      { value: "team", label: "Company Team" },
      { value: "settings", label: "Settings" },
      { value: "other", label: "Other" },
    ],
  };

  return (
    <SuiBox>
      <SuiBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Ticket Type
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                value={
                  inputs.type === ""
                    ? ""
                    : options.type.filter((option) => option.value === inputs.type)
                }
                placeholder=""
                options={options.type}
                onChange={(e) => onSelectChange(e, "type")}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12}>
            <SuiBox>
              <FormField
                type="text"
                name="subject"
                value={inputs.subject}
                label="Subject"
                placeholder=""
                onChange={onChange}
              />
            </SuiBox>
          </Grid>
          {inputs.type === "bug" ? (
            <Grid item xs={12}>
              <Stack spacing={3}>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Priority
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    value={
                      inputs.priority === ""
                        ? ""
                        : options.priority.filter((option) => option.value === inputs.priority)
                    }
                    placeholder=""
                    options={options.priority}
                    onChange={(e) => onSelectChange(e, "priority")}
                  />
                </SuiBox>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Which page does this occur on?
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    value={
                      inputs.page === ""
                        ? ""
                        : options.page.filter((option) => option.value === inputs.page)
                    }
                    placeholder=""
                    options={options.page}
                    onChange={(e) => onSelectChange(e, "page")}
                  />
                </SuiBox>
                <SuiBox>
                  <FormField
                    type="text"
                    multiline
                    rows={5}
                    name="description"
                    value={inputs.description}
                    label="Problem Description"
                    placeholder=""
                    onChange={onChange}
                  />
                </SuiBox>
              </Stack>
            </Grid>
          ) : null}
          {inputs.type === "question" ? (
            <Grid item xs={12}>
              <Stack spacing={3}>
                <SuiBox>
                  <FormField
                    type="text"
                    multiline
                    rows={5}
                    name="description"
                    value={inputs.description}
                    label="What is your question/feedback for the Loana team? Thanks!"
                    placeholder=""
                    onChange={onChange}
                  />
                </SuiBox>
              </Stack>
            </Grid>
          ) : null}
          {inputs.type === "feature" ? (
            <Grid item xs={12}>
              <Stack spacing={3}>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Priority
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    value={
                      inputs.priority === ""
                        ? ""
                        : options.priorityFeature.filter(
                            (option) => option.value === inputs.priority
                          )
                    }
                    placeholder=""
                    options={options.priorityFeature}
                    onChange={(e) => onSelectChange(e, "priority")}
                  />
                </SuiBox>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Which page would this feature be for?
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    value={
                      inputs.page === ""
                        ? ""
                        : options.page.filter((option) => option.value === inputs.page)
                    }
                    placeholder=""
                    options={options.page}
                    onChange={(e) => onSelectChange(e, "page")}
                  />
                </SuiBox>
                <SuiBox>
                  <FormField
                    type="text"
                    multiline
                    rows={5}
                    name="description"
                    value={inputs.description}
                    label="Feature Description"
                    placeholder=""
                    onChange={onChange}
                  />
                </SuiBox>
              </Stack>
            </Grid>
          ) : null}
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

HelpTicket.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  inputs: PropTypes.shape({
    type: PropTypes.string,
    page: PropTypes.string,
    priority: PropTypes.string,
    description: PropTypes.string,
    subject: PropTypes.string,
  }).isRequired,
};

export default HelpTicket;
