import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// DayJS
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

// @mui material components
import { Grid, IconButton, Stack, Card, Tooltip, CardContent } from "@mui/material";
import { Avatar } from "material-ui";
import EmailIcon from "@mui/icons-material/Email";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import CategoryIcon from "@mui/icons-material/Category";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// import SuiBadge from "components/SuiBadge";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function OpportunityCard({ opp, owner, openSend }) {
  dayjs.extend(relativeTime);

  const { values } = breakpoints;

  const history = useHistory();

  const user = useSelector((state) => state.user.profile);

  const handleEditRedirect = (event, id) => {
    event.preventDefault();
    history.push({
      pathname: `/pipeline/opportunities/edit/${id}`,
      state: { redirectToFollowups: false },
    });
  };

  return window.innerWidth < values.md ? (
    <Grid item xs={12} key={uuidv4()}>
      <Card
        key={uuidv4()}
        onClick={(e) => handleEditRedirect(e, opp.oppId)}
        style={{
          borderRadius: 10,
          marginBottom: 10,
          boxShadow: "0px 0px 20px #ECEDEE",
        }}
      >
        <CardContent>
          <Grid container spacing={1} justifyContent="center" alignItems="center">
            <Grid item xs={2} align="center">
              <Avatar
                alt="User Profile Picture"
                src={owner.photoURL}
                style={{ objectFit: "cover", width: "32px", height: "32px" }}
              />
            </Grid>
            <Grid item xs={10}>
              <Stack spacing={1} my={2} justifyContent="center">
                {opp.leads
                  ? opp.leads.map((lead) => (
                      <SuiTypography
                        variant="caption"
                        textColor="text"
                        fontWeight="bold"
                        key={`opp-card-${lead.leadId}`}
                      >
                        {lead.name}
                      </SuiTypography>
                    ))
                  : null}
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  ) : (
    <div key={uuidv4()}>
      <Grid container>
        <Grid item xs={11} style={{ cursor: "pointer" }}>
          <Card
            onClick={(e) => handleEditRedirect(e, opp.oppId)}
            style={{
              borderRadius: "10px 0 10px 10px",
              boxShadow: "0px 0px 20px #ECEDEE",
            }}
          >
            <CardContent style={{ paddingTop: 20 }}>
              <Grid container spacing={1}>
                <Grid item sm={12}>
                  <Grid container spacing={1} justifyContent="space-between">
                    <Grid item>
                      <Avatar
                        alt="User Profile Picture"
                        src={owner.photoURL}
                        style={{ objectFit: "cover" }}
                      />
                    </Grid>
                    <Grid item>
                      <Stack direction="row" spacing={2}>
                        {opp.status === "closed" ? (
                          <>
                            <Tooltip title={`Closed: ${dayjs(opp.closedAt).format("MM/DD/YYYY")}`}>
                              <AccessTimeIcon color="dark" />
                            </Tooltip>
                            <Tooltip title={`Closed Amount: $${opp.closedAmount.toLocaleString()}`}>
                              <AttachMoneyIcon color="dark" />
                            </Tooltip>
                            <Tooltip title={`Interest Rate: ${opp.closedRate}%`}>
                              <PercentIcon color="dark" />
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <Tooltip
                              title={`Created: ${dayjs(opp.createdAt).format("MM/DD/YYYY")}`}
                            >
                              <AccessTimeIcon color="dark" />
                            </Tooltip>
                            <Tooltip
                              title={`Last Contacted: ${dayjs(opp.lastContacted).fromNow()}`}
                            >
                              <AccessTimeFilledIcon color="dark" />
                            </Tooltip>
                            <Tooltip title={`Type: ${opp.type}`}>
                              <CategoryIcon color="dark" />
                            </Tooltip>
                          </>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12}>
                  {opp.leads
                    ? opp.leads.map((lead) => (
                        <SuiBox>
                          <SuiTypography
                            variant="caption"
                            textColor="text"
                            fontWeight="bold"
                            key={`opp-card-${lead.leadId}`}
                          >
                            {lead.name}
                          </SuiTypography>
                        </SuiBox>
                      ))
                    : null}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={1}>
          <Card
            style={{
              backgroundImage: "linear-gradient(to left, #ea0606, #ff667c)",
              borderTopRightRadius: 10,
              borderTopLeftRadius: 0,
              borderBottomRightRadius: 10,
              borderBottomLeftRadius: 0,
              boxShadow: "0px 0px 20px #ECEDEE",
            }}
          >
            <Tooltip
              title={
                user.proActive ? "Send Email" : "Upgrade to Loana Pro to send emails through Loana"
              }
            >
              <IconButton
                aria-label="actions"
                color="light"
                onClick={(e) => openSend(e, opp)}
                disabled={!user.proActive || false}
              >
                <EmailIcon
                  style={{
                    paddingLeft: window.innerWidth < values.xxl ? 2 : 0,
                    paddingRight: window.innerWidth < values.xxl ? 2 : 0,
                  }}
                  fontSize={window.innerWidth >= values.xxl ? "medium" : "small"}
                />
              </IconButton>
            </Tooltip>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

// Typechecking props for the Card
OpportunityCard.propTypes = {
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  opp: PropTypes.shape({
    closedAmount: PropTypes.number,
    closedAt: PropTypes.string,
    closedRate: PropTypes.string,
    createdAt: PropTypes.string,
    holdActive: PropTypes.bool,
    holdUntil: PropTypes.string,
    lastContacted: PropTypes.string,
    leads: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        leadId: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
      })
    ),
    notes: PropTypes.arrayOf(
      PropTypes.shape({
        note: PropTypes.string,
        createdAt: PropTypes.string,
      })
    ),
    oppId: PropTypes.string,
    refinanceFocus: PropTypes.string,
    refinanceJourneyActive: PropTypes.bool,
    refinanceJourneyStart: PropTypes.string,
    refinanceJourneyEnd: PropTypes.string,
    refinanceType: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  owner: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    uid: PropTypes.string,
    photoURL: PropTypes.string,
  }).isRequired,
  openSend: PropTypes.func.isRequired,
};

export default OpportunityCard;
