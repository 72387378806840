import { useDispatch, useSelector } from "react-redux";
import { apiPostWithReturn } from "redux/actions/dataActions";

// @mui material components
import { Card, CardContent, CardHeader, Divider, Grid, Stack, Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

function BillingPortalCard() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const handleOpenPortal = (event) => {
    event.preventDefault();
    dispatch(
      apiPostWithReturn("/stripe/portal", {
        companyId: user.company.companyId,
        customer: user.company.stripeCustomer,
      })
    ).then((res) => {
      if (res.status === 200) {
        window.location.replace(res.data.message);
      }
    });
  };

  return (
    <Card style={{ paddingTop: 5, paddingRight: 5, paddingLeft: 5, paddingBottom: 10 }}>
      <CardHeader
        title={
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <SuiTypography variant="h5" fontWeight="bold">
              Manage Billing and Invoices
            </SuiTypography>
            <Tooltip title="View previous invoices, update billing information and methods, and cancel subscription.">
              <HelpIcon color="#8392ab" />
            </Tooltip>
          </Stack>
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <SuiButton
              variant="gradient"
              buttonColor="error"
              size="medium"
              fullWidth
              onClick={handleOpenPortal}
            >
              Go To Billing Portal
            </SuiButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default BillingPortalCard;
