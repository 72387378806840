import React from "react";
import { Route, Redirect } from "react-router-dom";

// Proptypes
import PropTypes from "prop-types";

const AdminRoute = (props) => {
  const { isAuthenticated, isAdmin } = props;

  return isAuthenticated && isAdmin ? <Route {...props} /> : <Redirect to="/mortgage" />;
};

AdminRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default AdminRoute;
