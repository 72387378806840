import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { apiPostWithReturn } from "redux/actions/dataActions";

// @mui material components
import { Stack, Divider, Card } from "@mui/material";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Soft UI Dasboard PRO Material components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import FormField from "layouts/profile/components/FormField";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// General page components
import LoanaTask from "../LoanaTask";

function LoanaTaskList(props) {
  const { tasks, triggerComplete, triggerAdded, team, user } = props;
  const { values } = breakpoints;

  const dispatch = useDispatch();

  const [taskInput, setTaskInput] = useState({ task: "", taskOwner: "" });
  const [ownerSelect, setOwnerSelect] = useState([{ value: "new", label: "new" }]);
  const [taskId, setTaskId] = useState("");
  const [taskAddOpen, setTaskAddOpen] = useState(false);
  const [taskEditOpen, setTaskEditOpen] = useState(false);

  const options = {
    taskOwner: ownerSelect,
  };

  const handleTaskComplete = (id) => {
    dispatch(apiPostWithReturn("/task/edit", { taskId: id, done: true })).then((response) => {
      if (response.status === 200) {
        triggerComplete();
      }
    });
  };

  const handleTaskInputChange = (event) => {
    event.persist();
    setTaskInput((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));
  };

  const handleTaskSelectChange = (event, name) => {
    setTaskInput((inputs) => ({
      ...inputs,
      [name]: event.value,
    }));
  };

  const handleTaskAddOpen = () => setTaskAddOpen(true);
  const handleTaskEditOpen = (event, currentTask, id) => {
    event.preventDefault();
    setTaskInput((inputs) => ({ ...inputs, task: currentTask }));
    setTaskId(id);
    setTaskEditOpen(true);
  };
  const handleTaskClose = () => {
    setTaskAddOpen(false);
    setTaskEditOpen(false);
    setTaskInput((inputs) => ({ ...inputs, task: "" }));
    setTaskId("");
  };
  const handleTaskAddSubmit = () => {
    dispatch(
      apiPostWithReturn("/task/add", {
        task: taskInput.task,
        taskOwner: taskInput.taskOwner,
        createNotification: taskInput.taskOwner !== user.uid || false,
        done: false,
        createdAt: new Date().toISOString(),
        orderId: tasks.length,
        taskId: crypto.randomUUID(),
      })
    ).then((response) => {
      handleTaskClose();
      if (response.status === 200) {
        triggerAdded();
      }
    });
  };

  const handleTaskEditSubmit = () => {
    dispatch(
      apiPostWithReturn("/task/edit", {
        task: taskInput.task,
        taskId,
      })
    ).then((response) => {
      handleTaskClose();
      if (response.status === 200) {
        triggerAdded();
      }
    });
  };

  useEffect(() => {
    const tempArr = [];
    team.forEach((member) => {
      tempArr.push({ value: member.uid, label: `${member.firstName} ${member.lastName}` });
    });
    setOwnerSelect(tempArr);
    setTaskInput((inputs) => ({
      ...inputs,
      taskOwner: user.uid,
    }));
  }, [user, team]);

  return (
    <Card>
      <SuiBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
        <SuiBox lineHeight={1}>
          <SuiTypography
            textTransform="capitalize"
            fontWeight="bold"
            variant={window.innerWidth < values.sm ? "h5" : "h4"}
          >
            Tasks
          </SuiTypography>
        </SuiBox>
        <SuiButton variant="gradient" buttonColor="error" size="small" onClick={handleTaskAddOpen}>
          + new task
        </SuiButton>
      </SuiBox>
      <Divider />
      <SuiBox pb={2} px={2}>
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {taskAddOpen ? (
            <SuiBox>
              <Stack spacing={2}>
                <FormField
                  type="text"
                  name="task"
                  value={taskInput.task || ""}
                  label="Task"
                  placeholder="Eg. Check on Stephen's application status tomorrow"
                  onChange={handleTaskInputChange}
                />
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={2} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Task Owner
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    value={
                      taskInput.taskOwner === ""
                        ? ""
                        : options.taskOwner.filter((option) => option.value === taskInput.taskOwner)
                    }
                    placeholder={`${user.firstName} ${user.lastName}`}
                    options={options.taskOwner}
                    onChange={(e) => handleTaskSelectChange(e, "taskOwner")}
                  />
                </SuiBox>
              </Stack>
              <SuiBox display="flex" mb={3} mt={2}>
                <SuiBox ml={1}>
                  <SuiButton
                    variant="gradient"
                    buttonColor="error"
                    size="small"
                    onClick={handleTaskAddSubmit}
                  >
                    add
                  </SuiButton>
                </SuiBox>
                <SuiBox ml={1}>
                  <SuiButton
                    variant="outlined"
                    buttonColor="error"
                    size="small"
                    onClick={handleTaskClose}
                  >
                    cancel
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </SuiBox>
          ) : null}
          {taskEditOpen ? (
            <SuiBox>
              <FormField
                type="text"
                name="task"
                value={taskInput.task || ""}
                label=""
                placeholder="Eg. Check on Stephen's application status tomorrow"
                onChange={handleTaskInputChange}
              />
              <SuiBox display="flex" mb={3} mt={2}>
                <SuiBox ml={1}>
                  <SuiButton
                    variant="gradient"
                    buttonColor="error"
                    size="small"
                    onClick={handleTaskEditSubmit}
                  >
                    edit
                  </SuiButton>
                </SuiBox>
                <SuiBox ml={1}>
                  <SuiButton
                    variant="outlined"
                    buttonColor="error"
                    size="small"
                    onClick={handleTaskClose}
                  >
                    cancel
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </SuiBox>
          ) : null}
          {tasks && tasks.length !== 0 ? (
            tasks
              .sort((a, b) => a.orderId - b.orderId)
              .map((item) =>
                !item.done ? (
                  <LoanaTask
                    key={item.taskId}
                    task={item.task}
                    created={item.createdAt}
                    onClick={handleTaskComplete}
                    taskId={item.taskId}
                    onEdit={handleTaskEditOpen}
                  />
                ) : null
              )
          ) : (
            <SuiTypography
              variant="button"
              fontWeight="regular"
              textColor="text"
              py={2}
              align="center"
            >
              No current tasks todo!
            </SuiTypography>
          )}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

LoanaTaskList.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      task: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      done: PropTypes.bool.isRequired,
    })
  ).isRequired,
  triggerComplete: PropTypes.func.isRequired,
  triggerAdded: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    uid: PropTypes.string,
  }).isRequired,
  team: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      uid: PropTypes.string,
    })
  ).isRequired,
};

export default LoanaTaskList;
