// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import FormField from "layouts/profile/components/FormField";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function AddressesLeadForm(props) {
  const { values } = breakpoints;
  const { inputs, onChange } = props;

  return (
    <Accordion style={{ borderRadius: 10, boxShadow: "0px 0px 20px #ECEDEE" }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="basics-accordion"
        id="basics-accordion-header"
        style={{ marginLeft: 15 }}
      >
        <SuiTypography
          textTransform="capitalize"
          fontWeight="bold"
          variant={window.innerWidth < values.sm ? "h6" : "h5"}
        >
          Addresses
        </SuiTypography>
      </AccordionSummary>
      <SuiBox p={3}>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormField
                type="text"
                name="currentAddress"
                value={inputs.currentAddress}
                label="Current Address"
                placeholder=""
                onChange={(e) => onChange(e, "addresses")}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                name="currentCity"
                value={inputs.currentCity}
                label="Current City"
                placeholder=""
                onChange={(e) => onChange(e, "addresses")}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormField
                type="text"
                name="currentState"
                value={inputs.currentState}
                label="Current State"
                placeholder=""
                onChange={(e) => onChange(e, "addresses")}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type="text"
                name="currentZip"
                value={inputs.currentZip}
                label="Current Zipcode"
                placeholder=""
                onChange={(e) => onChange(e, "addresses")}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type="text"
                name="currentAddressLength"
                value={inputs.currentAddressLength}
                label="Duration at Current Address"
                placeholder=""
                onChange={(e) => onChange(e, "addresses")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormField
                type="text"
                name="previousAddress"
                value={inputs.previousAddress}
                label="Previous Address"
                placeholder=""
                onChange={(e) => onChange(e, "addresses")}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type="text"
                name="previousCity"
                value={inputs.previousCity}
                label="Previous City"
                placeholder=""
                onChange={(e) => onChange(e, "addresses")}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormField
                type="text"
                name="previousState"
                value={inputs.previousState}
                label="Previous State"
                placeholder=""
                onChange={(e) => onChange(e, "addresses")}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type="text"
                name="previousZip"
                value={inputs.previousZip}
                label="Previous Zipcode"
                placeholder=""
                onChange={(e) => onChange(e, "addresses")}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type="text"
                name="previousAddressLength"
                value={inputs.previousAddressLength}
                label="Duration at Previous Address"
                placeholder=""
                onChange={(e) => onChange(e, "addresses")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                name="estimatedHomeValue"
                value={inputs.estimatedHomeValue}
                label="Estimated Home Value"
                placeholder=""
                onChange={(e) => onChange(e, "addresses")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                name="currentPayment"
                value={inputs.currentPayment}
                label="Current Monthly Payment"
                placeholder=""
                onChange={(e) => onChange(e, "addresses")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                name="mortgageBalance"
                value={inputs.mortgageBalance}
                label="Mortgage Balance"
                placeholder=""
                onChange={(e) => onChange(e, "addresses")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                name="interestRate"
                value={inputs.interestRate}
                label="Mortgage Interest Rate"
                placeholder=""
                onChange={(e) => onChange(e, "addresses")}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </SuiBox>
    </Accordion>
  );
}

AddressesLeadForm.propTypes = {
  inputs: PropTypes.shape({
    currentAddress: PropTypes.string.isRequired,
    currentAddressLength: PropTypes.string.isRequired,
    currentCity: PropTypes.string.isRequired,
    currentPayment: PropTypes.string.isRequired,
    currentState: PropTypes.string.isRequired,
    currentZip: PropTypes.string.isRequired,
    estimatedHomeValue: PropTypes.string.isRequired,
    interestRate: PropTypes.string.isRequired,
    mortgageBalance: PropTypes.string.isRequired,
    previousAddress: PropTypes.string.isRequired,
    previousAddressLength: PropTypes.string.isRequired,
    previousCity: PropTypes.string.isRequired,
    previousState: PropTypes.string.isRequired,
    previousZip: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AddressesLeadForm;
