// Proptypes
import PropTypes from "prop-types";

// @mui material components
import { Grid } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiEditor from "components/SuiEditor";
import SuiButton from "components/SuiButton";

function EmailEditor(props) {
  const { message, onChange, onSubmit } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SuiEditor value={message} onChange={(content) => onChange(content)} />
      </Grid>
      <Grid item xs={12} align="end" style={{ marginTop: 10 }}>
        <SuiButton variant="gradient" buttonColor="error" size="medium" onClick={onSubmit}>
          Send Email
        </SuiButton>
      </Grid>
    </Grid>
  );
}

EmailEditor.propTypes = {
  message: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EmailEditor;
