import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material UI
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  AppBar,
  Tabs,
  Tab,
} from "@mui/material";

// SUI Components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import LoanaDashboardPricingCard from "components/LoanaDashboardPricingCard";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import LoanaSubscriptionImg from "assets/images/loana-subscription.png";

function LoanaSubscription(props) {
  const { open, onClose, title } = props;
  const { values } = breakpoints;
  const isUpgrade = false;

  const user = useSelector((state) => state.user.profile);
  const company = useSelector((state) => state.user.company);

  const [tabValue, setTabValue] = useState(0);
  const [price, setPrice] = useState("250");
  const [seats, setSeats] = useState(1);

  const handleSeatAdd = () => setSeats(seats + 1);
  const handleSeatMinus = () => {
    if (seats > company.team.length) {
      setSeats(seats - 1);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);

    if (event.currentTarget.id === "team") {
      if (seats > 5) {
        const seatPrice = (seats - 5) * 40;
        const totalPrice = seatPrice + 250;
        setPrice(totalPrice.toString());
      } else {
        setPrice("250");
      }
    } else {
      setPrice("100");
    }
  };

  useEffect(() => {
    if (company && company.team) {
      setSeats(company.team.length);
    }
  }, [company]);

  useEffect(() => {
    if (seats > 5) {
      const seatPrice = (seats - 5) * 40;
      const totalPrice = seatPrice + 250;
      setPrice(totalPrice.toString());
    } else {
      setPrice("250");
    }
  }, [seats]);

  return (
    <Dialog open={open} fullScreen style={{ padding: 10, backgroundColor: "#F8F9FA" }}>
      <DialogTitle>
        <SuiBox pt={3} mb={3} textAlign="center">
          {user.admin ? (
            <SuiTypography
              variant={window.innerWidth > values.md ? "h2" : "h4"}
              textColor="error"
              fontWeight="bold"
            >
              {title}
            </SuiTypography>
          ) : (
            <SuiTypography
              variant={window.innerWidth > values.md ? "h2" : "h4"}
              textColor="error"
              fontWeight="bold"
            >
              Please contact your company administrator to upgrade to Loana Pro
            </SuiTypography>
          )}
        </SuiBox>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Grid container spacing={3} justifyContent="center">
            {window.innerWidth < values.md ? (
              <Grid item xs={12} alignContent="center">
                <img
                  src={LoanaSubscriptionImg}
                  alt="upgrade to loana pro"
                  width="100%"
                  height="auto"
                  style={{ borderRadius: 10 }}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} md={8}>
              <Stack spacing={2} justifyContent="center">
                <SuiTypography
                  variant={window.innerWidth > values.md ? "h4" : "h6"}
                  textColor="info"
                  fontWeight="bold"
                >
                  What benefits will I receive from upgrading?
                </SuiTypography>
                <SuiTypography variant="body2">
                  <span color="#21d4fd">
                    <b>Refinance Journey:</b>
                  </span>{" "}
                  Do you want returning customers to refinance with you? Perfect, Loana&apos;s
                  Refinance Journey will automatically move your closed opportunities to a custom
                  email campaign designed to bring them back.
                </SuiTypography>
                <SuiTypography variant="body2">
                  <span color="#21d4fd">
                    <b>Tasks:</b>
                  </span>{" "}
                  Need to organize and assign daily tasks to you and your team? Great, Loana&apos;s
                  Tasks does exactly this and keeps track of your yearly stats to track your
                  productivity.
                </SuiTypography>
                <SuiTypography variant="body2">
                  <span color="#21d4fd">
                    <b>Follow-ups:</b>
                  </span>{" "}
                  Are you having a difficult time keeping track of which leads/opportunities you
                  need to follow up with? No sweat, Loana&apos;s Follow-ups automatically notify you
                  when it is time to reach out to each opportunity based on your company&apos;s
                  preferences.
                </SuiTypography>
                <SuiTypography variant="body2">
                  <span color="#21d4fd">
                    <b>Email Automations:</b>
                  </span>{" "}
                  Create custom email templates and send an email to an opportunity with the click
                  of a button.
                </SuiTypography>
                <SuiTypography variant="body2">
                  <span color="#21d4fd">
                    <b>Birthday Automations:</b>
                  </span>{" "}
                  Wishing a lead Happy Birthday goes a long ways in building your relationship. With
                  Loana Pro, you create your custom birthday email and we handle the rest.
                </SuiTypography>
                <SuiTypography variant="body2">
                  <span color="#21d4fd">
                    <b>Hello Loana:</b>
                  </span>{" "}
                  On the go and need to see the tasks you set the night before? Hello Loana will
                  send you a daily interactive SMS to quickly know what you need to accomplish for
                  the day.
                </SuiTypography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={8}>
              <Stack spacing={2} justifyContent="center">
                <SuiTypography
                  variant={window.innerWidth > values.md ? "h4" : "h6"}
                  textColor="info"
                  fontWeight="bold"
                >
                  Why is there an onboarding fee and what do I get from it?
                </SuiTypography>
                <SuiTypography variant="body2">
                  Migrating to a new CRM can be a painful process, but at Loana we strive to make
                  the transition as smooth as possible. The onboarding fee unlocks the following
                  benefits:
                </SuiTypography>
                <SuiTypography variant="body2">
                  <span color="#21d4fd">
                    <b>Dedicated Onboarding Specialist:</b>
                  </span>{" "}
                  You will be assigned an onboarding specialist for your first month of using Loana
                  Pro. They are essentially a guide for any questions or concerns you might have.
                </SuiTypography>
                <SuiTypography variant="body2">
                  <span color="#21d4fd">
                    <b>Onboarding Demo and Presentation:</b>
                  </span>{" "}
                  Your dedicated onboarding specialist will demonstrate the key features of Loana
                  Pro during a 1-hour presentation with your team.
                </SuiTypography>
                <SuiTypography variant="body2">
                  <span color="#21d4fd">
                    <b>Import Leads and Opportunities:</b>
                  </span>{" "}
                  All of your previous leads and opportunities will be imported into your Loana
                  account.
                </SuiTypography>
                <SuiTypography variant="body2">
                  If you have additional questions, please reach out to our sales team at{" "}
                  <b>sales@helloloana.com</b>
                </SuiTypography>
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={window.innerWidth > values.md ? 3 : 0}
            justifyContent="center"
            alignItems="center"
          >
            {window.innerWidth > values.md ? (
              <Grid item md={4} alignContent="center">
                <img
                  src={LoanaSubscriptionImg}
                  alt="upgrade to loana pro"
                  width="100%"
                  height="auto"
                  style={{ borderRadius: 10 }}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} md={4}>
              <Stack spacing={2}>
                {company.team && company.team.length === 1 ? (
                  <SuiBox width="100%">
                    <AppBar position="static">
                      <Tabs value={tabValue} onChange={handleTabChange}>
                        <Tab
                          id="team"
                          label={
                            <SuiBox py={0.5} px={2}>
                              Team
                            </SuiBox>
                          }
                        />
                        <Tab
                          id="single"
                          label={
                            <SuiBox py={0.5} px={2}>
                              Single
                            </SuiBox>
                          }
                        />
                      </Tabs>
                    </AppBar>
                  </SuiBox>
                ) : null}
                <LoanaDashboardPricingCard
                  badge={{
                    color: "secondary",
                    label: price >= "250" ? "Team" : "Single",
                  }}
                  price={{ currency: "$", value: price }}
                  seats={seats}
                  onAdd={handleSeatAdd}
                  onMinus={handleSeatMinus}
                  upgrade={isUpgrade}
                  fee={price >= "250" ? "$299" : "$399"}
                  specifications={[
                    { label: "Unlimited leads and opportunities", includes: true },
                    { label: "Company and Personal Analytics", includes: true },
                    { label: "Team Performance Leaderboards", includes: true },
                    { label: "Refinance Journey", includes: true },
                    { label: "Tasks and Productivity Stats", includes: true },
                    { label: "Automated Follow-ups and reminders", includes: true },
                    { label: "Email Automations", includes: true },
                    { label: "Birthday Automations", includes: true },
                    { label: "Hello Loana SMS", includes: true },
                  ]}
                  action={{
                    color: "error",
                    label: "Upgrade Today",
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <SuiButton variant="outlined" buttonColor="error" onClick={onClose}>
          No thanks
        </SuiButton>
      </DialogActions>
    </Dialog>
  );
}

LoanaSubscription.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default LoanaSubscription;
