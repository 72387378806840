import "./wdyr";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Soft UI Context Provider
import { SoftUIControllerProvider } from "context";

// Utils
import removeLocalToken from "utils/helpers/removeLocalToken";
import setLocalToken from "utils/helpers/setLocalToken";
// import showAdminUi from "utils/helpers/showAdminUi";

// Firebase
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Redux
import { Provider } from "react-redux";
import store from "redux/store";
import { getUserAuth } from "redux/actions/userActions";
import { SET_UI_ADMIN } from "redux/types";

// Axios
import axios from "axios";

axios.defaults.baseURL = "https://us-central1-loana-f75fa.cloudfunctions.net/api";

const firebaseConfig = {
  apiKey: "AIzaSyAWMJgCJacOexWY2aYjpJjy5JBmW-GL52o",
  authDomain: "loana-f75fa.firebaseapp.com",
  projectId: "loana-f75fa",
  storageBucket: "loana-f75fa.appspot.com",
  messagingSenderId: "342388019128",
  appId: "1:342388019128:web:2a59921353009df7c1894d",
  measurementId: "G-82KDS1MXB3",
};

initializeApp(firebaseConfig);

const auth = getAuth();

// onAuthStateChanged(auth, (user) => {
//   if (user) {
//     store.dispatch(getUserAuth(user));
//   }
// });

auth.onIdTokenChanged((user) => {
  if (user) {
    user
      .getIdTokenResult()
      .then((data) => {
        removeLocalToken();
        setLocalToken(data.token);
        if (data.claims.admin) {
          store.dispatch({ type: SET_UI_ADMIN, payload: true });
        }
      })
      .then(() => store.dispatch(getUserAuth(user)));
  }
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <SoftUIControllerProvider>
        <App />
      </SoftUIControllerProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
