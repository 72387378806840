import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { apiPostWithReturn, apiGetWithReturn } from "redux/actions/dataActions";
import { SET_PROFILE } from "redux/types";

// @mui material components
import { Grid } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Custom components
import EmailTemplateEditor from "../components/EmailTemplateEditor";
import EmailTemplatePreview from "../components/EmailTemplatePreview";

function BirthdayAutomation() {
  const bypass = true;
  const { values } = breakpoints;

  const dispatch = useDispatch();
  const history = useHistory();

  // Opens UI Error
  const [openError, setOpenError] = useState(false);

  // User, Data, UI Redux Objects
  const loading = useSelector((state) => state.ui.loading);
  const user = useSelector((state) => state.user);
  const inputError = useSelector((state) => state.ui.error);

  const [message, setMessage] = useState("");

  const handleMessageChange = (content) => {
    setMessage(content);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      apiPostWithReturn("/automation/template/edit", {
        templateType: "birthday",
        templates: [message],
      })
    ).then((res) => {
      if (res.status === 200) {
        dispatch(apiGetWithReturn("/user/profile")).then((result) => {
          dispatch({ type: SET_PROFILE, payload: result.data.message });
          history.push("/dashboard/tools/automations");
        });
      }
    });
  };

  useEffect(() => {
    if (inputError !== "") {
      setOpenError(true);
    }
  }, [inputError]);

  useEffect(() => {
    if (user.profile.birthdaysTemplates && user.profile.birthdaysTemplates.length !== 0) {
      setMessage(user.profile.birthdaysTemplates[0]);
    }
  }, [user]);

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiTypography variant={window.innerWidth > values.md ? "h3" : "h4"} fontWeight="bold">
              Birthday Automations
            </SuiTypography>
          </Grid>
          {openError ? (
            <Grid item xs={12}>
              <SuiAlert color="warning">
                <SuiTypography variant="body2" textColor="white">
                  <SuiTypography
                    component="a"
                    href="#"
                    variant="body2"
                    fontWeight="medium"
                    textColor="white"
                  >
                    Oops!&nbsp;
                  </SuiTypography>
                  {inputError}
                </SuiTypography>
              </SuiAlert>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={6}>
            <EmailTemplateEditor
              title="Birthday Email Editor"
              hint="Edit your custom birthday email below. This email will automatically be sent to individuals with a corresponding birthday every day at 12:00 AM. The Customer Name will automatically be inserted. The email will be sent from your email address that is connected to Loana."
              message={message}
              onChange={handleMessageChange}
              onSubmit={handleSubmit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EmailTemplatePreview
              title="Birthday Email Preview"
              message={message}
              hint="A preview of your custom birthday email"
            />
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
}

export default BirthdayAutomation;
