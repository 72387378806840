module.exports = [
  {
    title: "Leads and Opportunities 101",
    description: `Leads and opportunities are the foundation of Loana. It's important that you know the difference between the two when using Loana. Leads are your potential customers. When you create a lead, you are inputting and storing essential information about that individual. Opportunites are created from leads. Multiple leads can be attached to an opportunity. They are used to track each customer's journey through your pipeline.`,
    type: "Leads and Opportunities",
  },
  {
    title: "Lead Templates 101",
    description: `Your company's lead template is used as the foundation for each newly created lead and the information every team member will gather for each lead.`,
    type: "Leads and Opportunities",
  },
  {
    title: "How do I delete a lead?",
    description: `Navigate to Leads on the left-hand menu. Find the lead you wish to delete in the Leads table. On the far right under Actions you will find an icon that looks like a trash can. Click that icon.`,
    type: "Leads and Opportunities",
  },
  {
    title: "How do I edit a lead?",
    description: `Navigate to Leads on the left-hand menu. Find the lead you wish to edit in the Leads table. On the far right under Actions you will find an icon that looks like a pencil. Click that icon. Now make your required changes and click save.`,
    type: "Leads and Opportunities",
  },
  {
    title: "How do I filter and search my company's leads?",
    description: `Navigate to Leads on the left-hand menu. At the top of the Leads table, there are three available filters - search, filter by date (start) and filter by date (end). Search is used to find a lead by entering their name, email, or phone. The filter by date inputs can be used to filter all leads by a specific time frame of when those leads were created.`,
    type: "Leads and Opportunities",
  },
  {
    title: "How do I create a new opportunity?",
    description: `There are two methods to create a new opportunity. 1. Navigate to Opportunities on the left-hand menu. At the top of the page, click the +New Opportunity button. 2. Create a new lead and check the box that says "Check the box to create an opportunity from this lead".`,
    type: "Leads and Opportunities",
  },
  {
    title: "How do I edit an opportunity?",
    description: `Navigate to Opportunites on the left-hand menu. Find the opportunity you wish to edit. Next click the red tab to the right of the opportunity called the Actions tab which is shown as a lightning bolt icon. Then click the edit icon, which looks like a pencil. Make your changes and then click save.`,
    type: "Leads and Opportunities",
  },
  {
    title: "How do I add leads to an opportunity?",
    description: `While editing an opportunity, find the Leads section. Type the name, email, or phone of the lead you wish to add. When you find the lead, click their name in the search results dropdown. Finally click save.`,
    type: "Leads and Opportunities",
  },
  {
    title: "How do I change the status of an opportunity?",
    description: `There are two methods to change the status of an opportunity. 1. Navigate to Opportunites on the left-hand menu. Click and hold the opportunity "card" you wish to change, and then drag the opportunity to the respective status column. 2. While editing an opportunity, click the "Status" input, select the desired status, and click save.`,
    type: "Leads and Opportunities",
  },
  {
    title: "How do I change my password?",
    description: `Navigate to Settings on the left-hand menu. Click the "Change Password" tab. Click the "Change Password" button. A password reset email will be sent to your Account's email address.`,
    type: "Account",
  },
  {
    title: "How do I delete my account?",
    description: `Navigate to Help on the left-hand menu. Then send our support team a ticket in order for them to complete the request.`,
    type: "Account",
  },
  {
    title: "How do I change my email?",
    description: `At the moment, this is not currently possible from within Loana. Please contact support by submitting a ticket from Help on the left-hand menu. `,
    type: "Account",
  },
  {
    title: "How do I edit my account profile and profile picture?",
    description: `Navigate to Settings on the left-hand menu. Click the "Profile" tab. Make your desired changes and click save.`,
    type: "Account",
  },
  {
    title: "How do I change a team member's account privilege level?",
    description: `Navigate to Administration on the left-hand menu. Click the "Users" tab. Under the "Company Users and Roles" area, find the team member's name, under "User Role" select the desired "User Role" and click save changes. The user will need to logout of their Account and sign in again for the changes to propagate.`,
    type: "Company",
  },
  {
    title: "How do I invite a team member?",
    description: `Navigate to Administration on the left-hand menu. Click the "Users" tab. Under the "Invite a Team Member" area, enter the individual's email and their desired role within the company, then click send.`,
    type: "Company",
  },
  {
    title: "How do I change the settings for Loana Follow Ups and the company's Lead Template?",
    description: `Navigate to Administration on the left-hand menu. Click the "Templates" tab. Here you will be able to change each template. Make sure to click save if you've made changes.`,
    type: "Company",
  },
  {
    title: "How do I change my billing method?",
    description: `Navigate to Administration on the left-hand menu. Click the "Billing and Subscription" tab. Under the "Manage Billing and Invoices" area, click the "Go to Billing Portal" button. You will be redirected to Stripe to complete the process.`,
    type: "Billing and Subscription",
  },
  {
    title: "How do I purchase Loana Pro?",
    description: `Navigate to Administration on the left-hand menu. Click the "Billing and Subscription" tab. Under the "Your Subscription" area, click the "Upgrade Plan Today" button. Select the number of team members and plan you wish to purchase.`,
    type: "Billing and Subscription",
  },
  {
    title: "Loana Cancellation Policy",
    description: `If you choose to cancel Loana Pro, your subscription will remain open for the remainder of your billing period. You may renew your subscription during that remaining period. If a failed payment is received during renewal or for an upgrade, Stripe will attempt the payment 4 times within a 7 day period. If all retry attempts fail, your subscription will be canceled entirely. We will notify you of expiring credit cards, failed payments, etc. However, it is solely your responsibility to ensure your information is up-to-date in order to not have your services suspended. For the full cancellation policy, please visit https://www.helloloana.com/terms`,
    type: "Billing and Subscription",
  },
  {
    title: "How do I cancel my Loana Pro subscription?",
    description: `Navigate to Administration on the left-hand menu. Click the "Billing and Subscription" tab. Under the "Manage Billing and Invoices" area, click the "Go to Billing Portal" button. You will be redirected to Stripe to complete the process.`,
    type: "Billing and Subscription",
  },
  {
    title: "Will I lose my leads and opportunities if I cancel my Loana Pro subscription?",
    description: `No! Loana will always store your leads and opportunites as long as you have an active Account, but you will lose key features of Loana Pro such as email and text, all automations, Hello Loana daily tasks and follow ups, etc.`,
    type: "Billing and Subscription",
  },
];
