import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Day JS
import dayjs from "dayjs";

// @mui material components
import { Card, CardContent, CardHeader, Divider, Grid, Icon, Stack, Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

function SubscriptionCard(props) {
  const { onUpgrade } = props;

  const user = useSelector((state) => state.user);

  const [price, setPrice] = useState(0);
  const [plan, setPlan] = useState("Free");
  const [seats, setSeats] = useState(0);
  const [currentSeats, setCurrentSeats] = useState(0);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);

  useEffect(() => {
    if (user.company && user.company.proPlan) {
      if (user.company.proPlan === "team") {
        setSeats(user.company.proSeats);
        setCurrentSeats(user.company.team.length);
        setStart(user.company.proStart);
        setEnd(user.company.proEnd);
        setPlan("Team");
        setPrice(250 + 40 * user.company.proSeats - 200);
      } else if (user.company.proPlan === "single") {
        setSeats(user.company.proSeats);
        setCurrentSeats(user.company.team.length);
        setStart(user.company.proStart);
        setEnd(user.company.proEnd);
        setPlan("Single");
        setPrice(99);
      } else if (user.company.proPlan === "free") {
        setSeats("Unlimited");
        setStart("N/A");
        setEnd("N/A");
      }
    }
  }, [user]);

  return (
    <Card style={{ paddingTop: 5, paddingRight: 5, paddingLeft: 5, paddingBottom: 10 }}>
      <CardHeader
        title={
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <SuiTypography variant="h5" fontWeight="bold">
              Your Subscription
            </SuiTypography>
            <Tooltip title="View current subscription information, and the ability to upgrade your Loana Pro plan.">
              <HelpIcon color="#8392ab" />
            </Tooltip>
          </Stack>
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={3}>
              <SuiTypography variant="caption" fontWeight="bold" textColor="dark">
                Current Plan
              </SuiTypography>
              <SuiTypography variant="caption" fontWeight="bold" textColor="dark">
                Team Members Used
              </SuiTypography>
              <SuiTypography variant="caption" fontWeight="bold" textColor="dark">
                Billing Cycle Start
              </SuiTypography>
              <SuiTypography variant="caption" fontWeight="bold" textColor="dark">
                Billing Cycle End
              </SuiTypography>
              <SuiTypography variant="caption" fontWeight="bold" textColor="dark">
                Subscription Monthly Amount
              </SuiTypography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={3}>
              <SuiTypography variant="caption" textColor="dark">
                {plan}
              </SuiTypography>
              <SuiTypography variant="caption" textColor="dark">
                {`${currentSeats} out of ${seats}`}
              </SuiTypography>
              <SuiTypography variant="caption" textColor="dark">
                {dayjs.unix(start).format("MMMM DD YYYY")}
              </SuiTypography>
              <SuiTypography variant="caption" textColor="dark">
                {dayjs.unix(end).format("MMMM DD YYYY")}
              </SuiTypography>
              <SuiTypography variant="caption" textColor="dark">
                {`$${price}`}
              </SuiTypography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <SuiButton
              variant="gradient"
              buttonColor="error"
              size="medium"
              fullWidth
              onClick={onUpgrade}
            >
              Upgrade Plan Today&nbsp;
              <Icon className=" font-bold">arrow_forward</Icon>
            </SuiButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

SubscriptionCard.propTypes = {
  onUpgrade: PropTypes.func.isRequired,
};

export default SubscriptionCard;
