import { useSelector } from "react-redux";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// Day JS
import dayjs from "dayjs";

// @mui material components
import { CardTitle } from "material-ui";
import { Card, CardContent, Divider, Grid } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function setOwner(team, id) {
  const oppOwner = team.find((o) => o.uid === id);
  return `${oppOwner.firstName} ${oppOwner.lastName}`;
}

function NotesArea(props) {
  const { values } = breakpoints;
  const { notes, openDialog } = props;

  const team = useSelector((state) => state.user.company.team);

  return (
    <Card>
      <CardTitle>
        <Grid container spacing={3} alignItems="center" justifyContent="space-between">
          <Grid item>
            <SuiTypography
              textTransform="capitalize"
              fontWeight="bold"
              variant={window.innerWidth < values.sm ? "h6" : "h5"}
            >
              Notes
            </SuiTypography>
          </Grid>
          <Grid item>
            <SuiButton variant="gradient" buttonColor="error" size="medium" onClick={openDialog}>
              Add Note
            </SuiButton>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </CardTitle>
      <SuiBox p={3}>
        <CardContent style={{ maxHeight: 300, overflow: "auto" }}>
          <Grid container spacing={3}>
            {notes && notes.length !== 0 ? (
              notes
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map((note) => (
                  <Grid item xs={12} key={uuidv4()}>
                    <Card
                      key={note.noteId || uuidv4()}
                      style={{
                        borderRadius: 10,
                        backgroundImage: "linear-gradient(to bottom right, #2152ff, #21d4fd)",
                      }}
                    >
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <SuiTypography variant="body2" textColor="light" fontWeight="bold">
                              {note.message}
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6}>
                            <SuiTypography variant="body2" textColor="light" fontWeight="medium">
                              {setOwner(team, note.noteOwner)}
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6}>
                            <SuiTypography variant="body2" textColor="light" fontWeight="medium">
                              {dayjs(note.createdAt).format("MM/DD/YYYY")}
                            </SuiTypography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
            ) : (
              <Grid item xs={12} align="center">
                <SuiTypography variant="body2">No notes have been added</SuiTypography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </SuiBox>
    </Card>
  );
}

NotesArea.propTypes = {
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      message: PropTypes.string,
      noteOwner: PropTypes.string,
      noteId: PropTypes.string,
    })
  ).isRequired,
  openDialog: PropTypes.func.isRequired,
};

export default NotesArea;
