import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Custom styles for the LayoutContainer
import styles from "examples/LayoutContainers/DashboardLayout/styles";

// Soft UI Dashboard PRO React context
import { useSoftUIController } from "context";

// Custom Components
import MuiLoader from "components/MuiLoader";
import LoanaSubscription from "components/LoanaSubscription";

// import user from "utils/schemas/user";
import LoanaWalkthrough from "components/LoanaWalkthrough";
// import { MuiThemeProvider } from "material-ui/styles";

function LayoutContainer({ children, loading, bypass }) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction } = controller;
  const { pathname } = useLocation();
  const classes = styles({ miniSidenav, direction });
  const history = useHistory();

  const profileloading = useSelector((state) => state.user.isLoading);
  const companyLoading = useSelector((state) => state.user.isCompanyLoading);
  const user = useSelector((state) => state.user);

  const [openLoader, setOpenLoader] = useState(loading);
  const [openSubscription, setOpenSubscription] = useState(false);

  const handleCloseSubscription = (event) => {
    event.preventDefault();
    history.push("/mortgage");
  };

  useEffect(() => {
    dispatch({ type: "LAYOUT", value: "dashboard" });
    if (
      pathname === "/pipeline/refinance" ||
      pathname === "/tools/tasks" ||
      pathname === "/tools/followups" ||
      pathname === "/tools/automations" ||
      pathname === "/tools/automations/birthdays" ||
      pathname === "/tools/automations/helloloana" ||
      pathname === "/tools/automations/refinance" ||
      pathname === "/tools/automations/email"
    ) {
      if (!user.profile.proActive) {
        setOpenSubscription(true);
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (!bypass) {
      if (!profileloading && !companyLoading) {
        setOpenLoader(false);
      } else {
        setOpenLoader(true);
      }
    }
  }, [profileloading, companyLoading]);

  useEffect(() => {
    if (bypass) {
      setOpenLoader(loading);
    }
  }, [loading]);

  return (
    <>
      <MuiLoader loading={openLoader} />
      <LoanaSubscription
        open={openSubscription}
        title="Upgrade today to unlock the benefits of Loana Pro!"
        onClose={handleCloseSubscription}
      />
      {user && !profileloading && user.profile.showWalkthrough ? <LoanaWalkthrough /> : null}

      <SuiBox customClass={classes.layoutContainer}>{children}</SuiBox>
    </>
  );
}

// Typechecking props for the LayoutContainer
LayoutContainer.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  bypass: PropTypes.bool.isRequired,
};

export default LayoutContainer;
