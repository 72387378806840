import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiPostWithReturn, apiGetWithReturn } from "redux/actions/dataActions";
import { SET_PROFILE } from "redux/types";

// @mui material components
import {
  Card,
  CardContent,
  Grid,
  Stack,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LoanaTopNavbar from "examples/Navbars/LoanaTopNavbar";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Custom components
import EmailTemplateEditor from "../components/EmailTemplateEditor";
import EmailTemplatePreview from "../components/EmailTemplatePreview";

function EmailAutomation() {
  const { values } = breakpoints;
  const bypass = true;
  const dispatch = useDispatch();

  // Opens UI Error
  const [openError, setOpenError] = useState(false);

  // User, Data, UI Redux Objects
  const loading = useSelector((state) => state.ui.loading);
  const user = useSelector((state) => state.user);
  const inputError = useSelector((state) => state.ui.error);

  /* ----------------------- CORE --------------------------- */

  const [templates, setTemplates] = useState([]);
  const [newTemplate, setNewTemplate] = useState("");
  const [currentTemplate, setCurrentTemplate] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [showNewTemplate, setShowNewTemplate] = useState(false);
  const [showEditTemplate, setShowEditTemplate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleTemplateChange = (content) => setNewTemplate(content);
  const handleNameChange = (event) => {
    event.persist();
    setTemplateName(event.target.value);
  };

  const handleNewOpen = () => {
    setOpenDelete(false);
    setShowEditTemplate(false);
    setShowNewTemplate(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const templatesArr = templates;
    templatesArr.push({
      template: newTemplate,
      name: templateName,
      templateId: crypto.randomUUID(),
    });
    dispatch(
      apiPostWithReturn("/automation/template/edit", {
        templateType: "email",
        templates: templatesArr,
      })
    ).then((res) => {
      if (res.status === 200) {
        dispatch(apiGetWithReturn("/user/profile")).then((result) => {
          dispatch({ type: SET_PROFILE, payload: result.data.message });
          setNewTemplate("");
          setCurrentTemplate("");
          setTemplateName("");
          setTemplateId("");
          setShowNewTemplate(false);
        });
      }
    });
  };

  useEffect(() => {
    if (inputError !== "") {
      setOpenError(true);
    }
  }, [inputError]);

  useEffect(() => {
    if (user.profile.emailTemplates && user.profile.emailTemplates.length !== 0) {
      setTemplates(user.profile.emailTemplates);
    }
  }, [user]);
  /* ----------------------- EDIT TEMPLATE --------------------------- */

  const handleEditOpen = (event, template) => {
    event.preventDefault();
    setCurrentTemplate(template.template);
    setTemplateName(template.name);
    setTemplateId(template.templateId);
    setOpenDelete(false);
    setShowNewTemplate(false);
    setShowEditTemplate(true);
  };

  const handleEditTemplateChange = (content) => setCurrentTemplate(content);

  const handleEditSubmit = (event) => {
    event.preventDefault();
    const templatesEditArr = templates;
    let index = 0;
    index = templatesEditArr.findIndex((obj) => obj.templateId === templateId);
    templatesEditArr[index] = {
      template: currentTemplate,
      name: templateName,
      templateId,
    };
    dispatch(
      apiPostWithReturn("/automation/template/edit", {
        templateType: "email",
        templates: templatesEditArr,
      })
    ).then((res) => {
      if (res.status === 200) {
        dispatch(apiGetWithReturn("/user/profile")).then((result) => {
          dispatch({ type: SET_PROFILE, payload: result.data.message });
          setNewTemplate("");
          setCurrentTemplate("");
          setTemplateName("");
          setTemplateId("");
          setShowEditTemplate(false);
        });
      }
    });
  };

  /* ----------------------- DELETE TEMPLATE --------------------------- */

  const handleDeleteOpen = (event, template) => {
    event.preventDefault();
    setTemplateId(template.templateId);
    setShowNewTemplate(false);
    setShowEditTemplate(false);
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setNewTemplate("");
    setCurrentTemplate("");
    setTemplateName("");
    setTemplateId("");
  };

  const handleDeleteTemplate = (event) => {
    event.preventDefault();
    const templatesEditArr = templates;
    let index = 0;
    index = templatesEditArr.findIndex((obj) => obj.templateId === templateId);
    if (index > -1) {
      templatesEditArr.splice(index, 1);
    }
    dispatch(
      apiPostWithReturn("/automation/template/edit", {
        templateType: "email",
        templates: templatesEditArr,
      })
    ).then((res) => {
      if (res.status === 200) {
        dispatch(apiGetWithReturn("/user/profile")).then((result) => {
          dispatch({ type: SET_PROFILE, payload: result.data.message });
          setNewTemplate("");
          setCurrentTemplate("");
          setTemplateName("");
          setTemplateId("");
          setOpenDelete(false);
          setShowNewTemplate(false);
          setShowEditTemplate(false);
        });
      }
    });
  };

  return (
    <DashboardLayout loading={loading} bypass={bypass}>
      <LoanaTopNavbar />
      <SuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiTypography variant={window.innerWidth > values.md ? "h3" : "h4"} fontWeight="bold">
              Email Automations
            </SuiTypography>
          </Grid>
          {openError ? (
            <Grid item xs={12}>
              <SuiAlert color="warning">
                <SuiTypography variant="body2" textColor="white">
                  <SuiTypography
                    component="a"
                    href="#"
                    variant="body2"
                    fontWeight="medium"
                    textColor="white"
                  >
                    Oops!&nbsp;
                  </SuiTypography>
                  {inputError}
                </SuiTypography>
              </SuiAlert>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card style={{ padding: 10 }}>
                  <SuiBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    p={3}
                  >
                    <SuiBox lineHeight={1}>
                      <SuiTypography
                        textTransform="capitalize"
                        fontWeight="bold"
                        variant={window.innerWidth > values.md ? "h4" : "h6"}
                      >
                        Templates
                      </SuiTypography>
                    </SuiBox>
                    <Stack spacing={1} direction="row">
                      <SuiButton
                        variant="gradient"
                        buttonColor="error"
                        size="small"
                        onClick={handleNewOpen}
                      >
                        + new template
                      </SuiButton>
                    </Stack>
                  </SuiBox>
                  <CardContent>
                    <Grid container justifyContent="space-between">
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={window.innerWidth > values.md ? 10 : 8}>
                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                              Template Name
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={window.innerWidth > values.md ? 2 : 4}>
                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                              Actions
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </Grid>
                      </Grid>
                      {templates && templates.length !== 0 ? (
                        templates.map((template) => (
                          <Grid item xs={12} key={template.templateId}>
                            <Grid container>
                              <Grid item xs={window.innerWidth > values.md ? 10 : 8}>
                                <SuiTypography
                                  variant={window.innerWidth > values.md ? "body2" : "caption"}
                                  fontWeight="bold"
                                  textColor="info"
                                >
                                  {template.name}
                                </SuiTypography>
                              </Grid>
                              <Grid item xs={window.innerWidth > values.md ? 2 : 4}>
                                <Grid container>
                                  <Grid item>
                                    <Tooltip title="Edit Template">
                                      <IconButton
                                        aria-label="edit"
                                        color="error"
                                        onClick={(e) => handleEditOpen(e, template)}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                  <Grid item>
                                    <Tooltip title="Delete Template">
                                      <IconButton
                                        aria-label="delete"
                                        color="error"
                                        onClick={(e) => handleDeleteOpen(e, template)}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <Grid item xs={12}>
                          <SuiTypography
                            variant={window.innerWidth > values.md ? "body2" : "caption"}
                            align="center"
                          >
                            No templates have been created
                          </SuiTypography>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                {showEditTemplate ? (
                  <EmailTemplateEditor
                    title="Edit email template"
                    hint="Edit a custom email template. These templates can be used from the opportunities page. The Customer Name will automatically be inserted. The email will be sent from your email address that is connected to Loana."
                    message={currentTemplate}
                    templateName={templateName}
                    showName
                    onChange={handleEditTemplateChange}
                    onNameChange={handleNameChange}
                    onSubmit={handleEditSubmit}
                  />
                ) : null}
              </Grid>
              <Grid item xs={12}>
                {showNewTemplate ? (
                  <EmailTemplateEditor
                    title="Add email template"
                    hint="Add a new custom email template. These templates can be used from the opportunities page. The Customer Name will automatically be inserted. The email will be sent from your email address that is connected to Loana."
                    message={newTemplate}
                    templateName={templateName}
                    showName
                    onChange={handleTemplateChange}
                    onNameChange={handleNameChange}
                    onSubmit={handleSubmit}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <EmailTemplatePreview
              title="Email Template Preview"
              message={showEditTemplate ? currentTemplate : newTemplate}
              hint="A preview of your custom email."
            />
          </Grid>
          <Dialog
            open={openDelete}
            onClose={handleDeleteClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure you wish to delete this template?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This action will permanently delete this template from your collection.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <SuiButton
                variant="outlined"
                buttonColor="error"
                size="small"
                onClick={handleDeleteClose}
              >
                cancel
              </SuiButton>
              <SuiButton
                variant="gradient"
                buttonColor="error"
                size="small"
                onClick={handleDeleteTemplate}
              >
                delete
              </SuiButton>
            </DialogActions>
          </Dialog>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
}

export default EmailAutomation;
