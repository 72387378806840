// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { CardTitle } from "material-ui";
import { Card, Grid, Stack } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function OpportunityFormActions(props) {
  const { values } = breakpoints;
  const { onSubmit, onSubmitClosed, onDelete, deleteAvailable, status } = props;

  return (
    <Card>
      <CardTitle>
        <Grid container spacing={3} alignItems="center" justifyContent="space-between">
          <Grid item>
            <SuiTypography
              textTransform="capitalize"
              fontWeight="bold"
              variant={window.innerWidth < values.sm ? "h6" : "h5"}
            >
              Actions
            </SuiTypography>
          </Grid>
          <Grid item>
            <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" spacing={3}>
              <SuiButton
                variant="gradient"
                buttonColor="error"
                size="medium"
                onClick={status === "closed" ? onSubmitClosed : onSubmit}
              >
                Save Opportunity
              </SuiButton>
              {deleteAvailable ? (
                <SuiButton variant="outlined" buttonColor="error" size="medium" onClick={onDelete}>
                  Delete Opportunity
                </SuiButton>
              ) : null}
            </Stack>
          </Grid>
        </Grid>
      </CardTitle>
    </Card>
  );
}

OpportunityFormActions.defaultProps = {
  onDelete: () => {},
  onSubmitClosed: () => {},
};

OpportunityFormActions.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSubmitClosed: PropTypes.func,
  onDelete: PropTypes.func,
  deleteAvailable: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default OpportunityFormActions;
