module.exports = {
  admin: true,
  birthdaysActive: false,
  birthdaysTemplates: [
    "<p>Happy birthday! Here’s to wishing you another prosperous year and mutual growth. Thank you for choosing us as your mortgage team.</p><p><br></p><p><strong>Best Regards,</strong></p><p>Insert Your Signature Here</p>",
  ],
  companyId: "",
  companyTitle: "",
  createdAt: new Date().toISOString(),
  editCustomClaims: true,
  email: "",
  emailTemplates: [],
  firstName: "",
  followupCounter: 0,
  helloLoanaActive: false,
  helloLoanaPhone: "9706987646",
  lastName: "",
  master: false,
  nmls: "",
  phone: "",
  photoURL:
    "https://firebasestorage.googleapis.com/v0/b/loana-f75fa.appspot.com/o/no-image.png?alt=media",
  proActive: true,
  profileComplete: false,
  pushToCompany: false,
  refinanceActive: false,
  refinanceTemplates: {
    opener: "",
    nudge: "",
    reminderOne: "",
    reminderTwo: "",
    reminderThree: "",
    finalChance: "",
  },
  role: "mortgage",
  showWalkthrough: true,
  smsTemplates: [],
  taskCounter: 0,
  trialActive: true,
  uid: "",
};
